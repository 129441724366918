/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { PageContainer, BoxVerticalCenter } from 'components'
import { TrackEventEnum } from 'interfaces/trackEvent.interface'
import { sendToNewRelic } from 'services'
import { IDadosPessoaisCtx } from 'interfaces/dadosPessoais.interface'
import { DadosPessoaisCtx, FichaCadastralCtx, PixInformationCtx } from 'App'
import { IFichaCadastralCtx } from 'interfaces/fichaCadastral.interface'
import { useTrackEvent } from 'hooks'
import qs from 'qs'
import { getDadosPix } from 'services/axios'
import { IPixInformationCtx, TPixInformation } from 'interfaces/pixData.interface'
import { LoadingIndicator } from 'components/elements/LoadingIndicator'
import { RoutesEnum } from 'enums/routes.enum'

import * as S from './style'

const FinalizarPF = () => {
  const history = useHistory()
  const { dadosPessoais }: IDadosPessoaisCtx = useContext<IDadosPessoaisCtx>(DadosPessoaisCtx)
  const { hsfc, setHsfc, setBasicAccountCreated }: IFichaCadastralCtx =
    useContext<IFichaCadastralCtx>(FichaCadastralCtx)
  const { pixInfo, setPixInfo }: IPixInformationCtx =
    useContext<IPixInformationCtx>(PixInformationCtx)

  const hsfcValue = (): string => hsfc || (sessionStorage.getItem('hsfc') as string)

  const sendTrackEventData = useTrackEvent()

  useEffect(() => {
    if (!hsfc) {
      const url = window.location.hash
      const urlParams = url.split('?')[1]
      const params = qs.parse(urlParams)
      setHsfc((params.hsfc as string) || '')
      const valueBasicAccountCreated = (params.basicAccountCreated as string) === 'true'
      setBasicAccountCreated(valueBasicAccountCreated)

      sessionStorage.setItem('hsfc', String(params.hsfc))
      sessionStorage.setItem('basicAccountCreated', String(params.basicAccountCreated))

      if (params.hsfc) consultaDadoPix(params.hsfc as string)
      else history.push(RoutesEnum.INDICAR_AMIGOS)

      sendToNewRelic('FINALIZAR_PF', '[Screen] - FinalizarPF', {
        EMAIL: dadosPessoais?.email,
        FICHA_CADASTRAL: params.hsfc,
      })
    } else {
      consultaDadoPix()
    }
  }, [])

  const consultaDadoPix = (valorHsfc?: string) => {
    getDadosPix({
      header: { hsfc: valorHsfc || hsfcValue() },
      success: (response: TPixInformation) => {
        setPixInfo(response)
      },
      error: () => {
        setPixInfo({ showView: false } as TPixInformation)
      },
    })
  }

  useEffect(() => {
    if (Object.keys(pixInfo).length !== 0) {
      if (pixInfo.showView) history.push(RoutesEnum.CHAVE_PIX_INFO)
      else redirectIndicacao()
    }
  }, [pixInfo])

  const redirectIndicacao = () => {
    sendToNewRelic('FINALIZAR_PF', 'onFinishOnboarding', {
      EMAIL: dadosPessoais?.email,
      FICHA_CADASTRAL: hsfc,
    })
    sendTrackEventData(TrackEventEnum.TELA_CONCLUSAO_PF_A_CAMINHO_INDICAR, {
      content_id: 'Indicar depois',
    })
    history.push(RoutesEnum.CODIGO_INDICACAO)
  }

  return (
    <PageContainer>
      <BoxVerticalCenter>
        <S.LoadingWrapper>
          <LoadingIndicator />
        </S.LoadingWrapper>
      </BoxVerticalCenter>
    </PageContainer>
  )
}

export default FinalizarPF
