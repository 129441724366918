import { Gray } from 'assets/styled-component/variables'
import styled from 'styled-components'

export const Container = styled.div<{ hasOnClick: boolean }>`
  display: flex;

  ${({ hasOnClick }) => hasOnClick && 'cursor: pointer;'}
`

export const TooltipIcon = styled.div`
  width: 30px;
  text-align: center;
  cursor: pointer;
`

export const TooltipBox = styled.div`
  position: absolute;
  top: calc(70% + 20px);
  right: -20px;
  visibility: hidden;
  border-radius: 8px;
  z-index: 1;

  &:before {
    content: '';
    left: 213px;
    top: -4px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
  }
`

export const TooltipCard = styled.div`
  position: relative;
  & ${TooltipIcon}:hover + ${TooltipBox} {
    visibility: visible;
    color: ${Gray[100]};
    background-color: ${Gray[500]};
    font-size: 12px;
    width: 258px;
    padding: 16px;
    &:before {
      border-color: transparent transparent ${Gray[500]} ${Gray[500]};
    }
    p {
      color: ${Gray[100]};
      font-size: 12px;
      font-family: Inter;
      margin-top: 4px;
    }
  }
`
