import { BaseServices, EHttpMethod } from 'services/base'
import { urls } from 'services/base/url'

import { TRequestLoyaltyPayload } from './types'

const request = (payload: TRequestLoyaltyPayload) =>
  BaseServices.request({
    pathname: urls.PUT_LOYALTY,
    method: EHttpMethod.PUT,
    host: process.env.REACT_APP_API_HOST_CONTA_DIGITAL,
    data: { ...payload.data },
  })

export const putLoyalty = async (payload: TRequestLoyaltyPayload) => {
  try {
    const response = await request(payload)
    payload.success && payload.success(response.status)
  } catch (error) {
    payload.error && payload.error(error)
  } finally {
    payload.finally && payload.finally()
  }
}
