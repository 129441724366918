import { BaseServices, EHttpMethod } from 'services/base'
import { urls } from 'services/base/url'

import { getOperatingSystem } from '@interco/lib-util'

import { TRequestPixSendDataPayload } from './types'

const request = (payload: TRequestPixSendDataPayload) =>
  BaseServices.request({
    pathname: urls.PIX_KEYS,
    method: EHttpMethod.POST,
    host: process.env.REACT_APP_API_HOST_CONTA_DIGITAL,
    data: { ...payload.data },
    headers: {
      'x-inter-hash-id': payload.header.hsfc,
      'x-inter-so': getOperatingSystem().toLocaleUpperCase(),
    },
  })

export const postDadosPix = async (payload: TRequestPixSendDataPayload) => {
  try {
    const response = await request(payload)
    payload.success && payload.success(response.status)
  } catch (error) {
    payload.error && payload.error(error)
  } finally {
    payload.finally && payload.finally()
  }
}
