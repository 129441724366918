import styled from 'styled-components'
import { Gray } from 'assets/styled-component/variables'

export const TextRulesStyled = styled.span`
  color: ${(props) =>
    props.defaultChecked ? `${Gray[300]} !important` : `${Gray[400]} !important`};
  font-size: 12px;
  margin-bottom: 4px;
  display: inline-flex;
`

export const StatusContainer = styled.div`
  display: inline-flex;
  justify-content: right;
  align-items: center;
`

export const StatusBar = styled.div`
  border-radius: ${(props) => props.itemProp};
  background: ${(props) => props.color};
  width: 31px;
  height: 5px;
  margin-right: 2px;
`

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 80px 0 40px;
`
