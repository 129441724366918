import styled, { keyframes } from 'styled-components'
import { Loading } from 'components/elements/Icons'

const loadingAnimation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

export const LoadingIndicatorStyle = styled(Loading)`
  -webkit-animation: ${loadingAnimation} 1s linear infinite;
  animation: ${loadingAnimation} 1s linear infinite;

  width: 48px;
  height: 48px;
`
