import React, { useContext, useState } from 'react'

import {
  PageContainer,
  ButtonWrapper,
  Button,
  Checkbox,
  BottomSheet,
  FullPage,
  Spacing,
} from 'components'
import imagemLoop from 'assets/images/interLoop.png'
import { sendToNewRelic } from 'services'
import { TrackEventEnum } from 'interfaces/trackEvent.interface'
import { FichaCadastralCtx, InterLoopCtx, PosAberturaCtx } from 'App'
import { useHistory } from 'react-router-dom'
import { RoutesEnum } from 'enums/routes.enum'
import { IPosAberturaCtx } from 'interfaces/posAbertura.interface'
import { putLoyalty } from 'services/axios/putLoyalty'
import { IInterLoopCtx } from 'interfaces/loop.interface'
import url from 'shared/functions/url'
import { IFichaCadastralCtx } from 'interfaces/fichaCadastral.interface'

import { Text } from '@inter/inter-ui'

import { Termos } from './termos'

const InterLoop = () => {
  const { posAberturaOptions, setPosAberturaOptions }: IPosAberturaCtx =
    useContext<IPosAberturaCtx>(PosAberturaCtx)
  const { termId, termType }: IInterLoopCtx = useContext<IInterLoopCtx>(InterLoopCtx)

  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [openBottomsheet, setOpenBottomsheet] = useState<boolean>(false)
  const [openFailBottomsheet, setOpenFailBottomsheet] = useState<boolean>(false)
  const [openTermsModal, setOpenTermsModal] = useState<boolean>(false)

  const history = useHistory()

  const { hsfc }: IFichaCadastralCtx = useContext<IFichaCadastralCtx>(FichaCadastralCtx)
  const hsfcStorage = sessionStorage.getItem('hsfc')
  const hsfcUrl = url.queryParam().hsfc

  const handleConfirm = () => {
    setPosAberturaOptions({
      ...posAberturaOptions,
      loop: { ...posAberturaOptions.loop, done: true },
    })
    history.push(RoutesEnum.HOME_POS_ABERTURA)
  }

  const handleTermos = () => {
    sendToNewRelic('POS_ABERTURA', TrackEventEnum.GERAL_BOTAO, {
      FICHA_CADASTRAL: hsfc ?? hsfcUrl ?? hsfcStorage,
      content_id: 'Inter Loop - Termos',
    })

    setOpenTermsModal(true)
  }

  // TODO: Remove hashIdFichaNull-temp on Monday
  const acceptInterLoop = () => {
    putLoyalty({
      data: {
        hashIdFichaCadastral: hsfc ?? hsfcUrl ?? hsfcStorage,
        termId,
        termType,
      },
      success: () => {
        setOpenBottomsheet(true)

        sendToNewRelic('POS_ABERTURA', 'InterLoop - Sucesso', {
          FICHA_CADASTRAL: hsfc ?? hsfcUrl ?? hsfcStorage,
        })
      },
      error: () => {
        setOpenFailBottomsheet(true)

        sendToNewRelic('POS_ABERTURA', 'InterLoop - Error', {
          FICHA_CADASTRAL: hsfc ?? hsfcUrl ?? hsfcStorage,
        })
      },
    })
  }

  const handleOutInterLoop = () => {
    history.push(RoutesEnum.HOME_POS_ABERTURA)

    sendToNewRelic('POS_ABERTURA', 'InterLoop - Agora não', {
      FICHA_CADASTRAL: hsfc ?? hsfcUrl ?? hsfcStorage,
    })
  }

  return (
    <PageContainer>
      <img
        src={imagemLoop}
        alt="Conta Básica Aberta!"
        style={{ margin: '24px -24px', width: 'calc(100% + 48px)' }}
      />

      <Text variant="headline-h1" as="h1">
        Transforme pontos em cashback e muito mais
      </Text>

      <Text variant="body-3" as="p">
        Agora, você tem um programa gratuito de benefícios, com cashback e outras vantagens em um só
        lugar.
      </Text>

      <ButtonWrapper>
        <Checkbox
          id="checkbox-terms"
          checked={termsAccepted}
          onChange={() => setTermsAccepted(!termsAccepted)}
          label={
            <div style={{ display: 'block' }}>
              <Text variant="caption-1" as="span">
                Li e concordo com as informações descritas no{' '}
              </Text>
              <Text variant="caption-1" color="primary" as="span" bold onClick={handleTermos}>
                termo de adesão e política de privacidade.
              </Text>
            </div>
          }
          reversed
        />

        <Button id="submit" disabled={!termsAccepted} onClick={() => acceptInterLoop()}>
          Entrar no Inter Loop
        </Button>
        <Button id="indicate" variant="secondary" onClick={() => handleOutInterLoop()}>
          Agora não
        </Button>
      </ButtonWrapper>

      {openBottomsheet && (
        <BottomSheet onClose={() => setOpenBottomsheet(false)}>
          <div>
            <Spacing mb="xxxs" mt="xxxs">
              <Text variant="headline-h3">Agora você faz parte do Loop!</Text>
            </Spacing>

            <Spacing mb="xs">
              <Text variant="body-3">
                Você já pode começar a acumular e aproveitar os benefícios do nosso programa de
                pontos.
              </Text>
            </Spacing>

            <Button onClick={() => handleConfirm()} style={{ width: '100%' }}>
              Continuar
            </Button>
          </div>
        </BottomSheet>
      )}

      {openFailBottomsheet && (
        <BottomSheet onClose={() => setOpenFailBottomsheet(false)}>
          <div>
            <Spacing mb="xxxs" mt="xxxs">
              <Text variant="headline-h3">Ops! Algo deu errado!</Text>
            </Spacing>

            <Spacing mb="xs">
              <Text variant="body-3">
                {' '}
                Já estamos cientes e trabalhando nisto. Tente novamente em instantes.
              </Text>
            </Spacing>

            <Button onClick={() => setOpenFailBottomsheet(false)} style={{ width: '100%' }}>
              Entendi
            </Button>
          </div>
        </BottomSheet>
      )}

      <FullPage activeCloseFullPage={openTermsModal}>
        <Termos
          onConfirmClick={() => setOpenTermsModal(false)}
          onBackClick={() => setOpenTermsModal(false)}
          loading={false}
          hsfcValue={hsfc ?? hsfcUrl ?? hsfcStorage}
        />
      </FullPage>
    </PageContainer>
  )
}

export default InterLoop
