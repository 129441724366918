import { ButtonLink } from 'components'
import styled from 'styled-components'

export const Link = styled(ButtonLink)`
  width: 42px;
  height: 42px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;

  &:hover,
  &:enabled {
    background: transparent !important;
  }
`
export const Wrapper = styled.div`
  display: block;
  margin-bottom: 32px;

  & > div {
    width: 100%;
  }
`
export const CircleWrapper = styled.div`
  background: var(--success500);
  width: 64px;
  max-width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
