import React from 'react'

import imgPendenciamentoAprovado from 'assets/images/pendenciamentoAprovado.png'
import { PageContainer, ButtonWrapper, Button } from 'components'
import { TSendDataNative, sendDataNative } from 'components/hooks/useFetchNative'
import { DESCRIPTION_SHARE_FRIENDS } from 'shared/functions/constants'
import { IShare } from 'interfaces/share.interface'
import { ITrackEvent, TrackEventEnum } from 'interfaces/trackEvent.interface'

import { Text } from '@inter/inter-ui'

const PendenciamentoAprovado = () => {
  const indicarAmigos = () => {
    const callApi: TSendDataNative<IShare> = {
      callback: 'callbackIndicarAmigos',
      body: { description: DESCRIPTION_SHARE_FRIENDS },
      endpoint: '',
      method: 'SHARE',
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_PENDENCIAMENTO_APROVADO_INDICAR,
        parameters: {},
      },
    })
  }

  const completeOnboarding = () => {
    const callApi: TSendDataNative = {
      callback: 'callbackCompleteOnboarding',
      endpoint: '',
      method: 'COMPLETEONBOARDING',
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_PENDENCIAMENTO_APROVADO_CONTINUAR,
        parameters: {},
      },
    })
  }

  return (
    <PageContainer>
      <img
        src={imgPendenciamentoAprovado}
        alt="Pendenciamento aprovado!"
        style={{ margin: '24px -24px', width: 'calc(100% + 48px)' }}
      />
      <Text variant="headline-h1" as="h1">
        Parabéns!
      </Text>
      <Text variant="body-3" as="p">
        Sua Conta Digital foi aprovada. Agora você faz parte de um mundo sem tarifas bancárias,
        aproveite!
      </Text>

      <ButtonWrapper>
        <Button id="submit" onClick={completeOnboarding}>
          Acessar conta
        </Button>
        <Button id="indicate" variant="secondary" onClick={indicarAmigos}>
          Indicar amigos
        </Button>
      </ButtonWrapper>
    </PageContainer>
  )
}

export default PendenciamentoAprovado
