import { useEffect } from 'react'

import { GenericRes } from 'interfaces/response.interface'

import resolveResponse from './resolveResponse'

let timeout: null | number

export const useCallbackNative = (
  callbackEvent: string,
  callbackFunction: string,
  handlers: Partial<(data: GenericRes) => void>,
) => {
  timeout = null

  const handleCallResponse = (eventNames: string, event: GenericRes) => {
    if (eventNames && handlers[eventNames] && typeof handlers[eventNames] === 'function') {
      handlers[eventNames](event)
    }
  }

  useEffect(() => {
    const handlerCallbackApi = (event: Event) => {
      handleCallResponse(callbackFunction, resolveResponse(event))
    }

    window.addEventListener(
      callbackEvent,
      (event: Event) => {
        if (!timeout) {
          timeout = setTimeout(() => {
            handlerCallbackApi(event)
            return null
          })
        }
      },
      { once: true },
    )

    return () => {
      window.removeEventListener(callbackEvent, handlerCallbackApi)
    }
  })
}
