import { justDigits } from 'shared/functions/utils'

export const API_OAUTH = 'app-pf/nova-abertura-conta-pf/v2'

export const SMS_VALIDACAO = `${API_OAUTH}/codigos/sms/validacao`
export const SMS_REENVIO = `${API_OAUTH}/codigos/sms/reenvio`
export const EMAIL_VALIDACAO = `${API_OAUTH}/codigos/email/validacao`
export const EMAIL_REVALIDACAO = `${API_OAUTH}/codigos/email/revalidacao/validacao`
export const EMAIL_REVALIDACAO_REENVIO = `${API_OAUTH}/codigos/email/revalidacao/reenvio`
export const EMAIL_REENVIO = `${API_OAUTH}/codigos/email/reenvio`

export const EMAIL_REINICIAR_FICHA = `${API_OAUTH}/fichas/reiniciar`
export const DADOS_BASICOS = `${API_OAUTH}/fichas/dados-basicos`
export const DADOS_COMPLEMENTARES = `${API_OAUTH}/fichas/dados-complementares`
export const STEP_NATURALIDADE = `${API_OAUTH}/fichas/naturalidade`
export const TIPO_CARTAO = `${API_OAUTH}/fichas/tipo-cartao`
export const CRIAR_SENHA = `${API_OAUTH}/fichas/senha`
export const STEP_DADOS_PROFISSIONAIS = `${API_OAUTH}/fichas/dados-profissionais-renda`
export const DADOS_RESPONSAVEL = `${API_OAUTH}/fichas/dados-responsavel`
export const ENDERECOS = `${API_OAUTH}/fichas/enderecos`
export const getPathCidades = (estado: string) => `${API_OAUTH}/fichas/${estado}/cidades`
export const getPathAbrirContaPJ = (cnpj: string) =>
  `${API_OAUTH}/fichas/conclusao-pf-pj/${justDigits(cnpj)}`

export const getPathCEP = (cep: string) => `${API_OAUTH}/enderecos/${justDigits(cep)}`

export const TERMO_ACEITE = `${API_OAUTH}/termo-aceite/pdf`
export const TERMO_ACEITE_MEI = `${API_OAUTH}/termo-aceite/pdf/mei`

export const getPathTermoMei = (cnpj: string) => `${API_OAUTH}/termo-aceite/pdf/mei/${cnpj}`
