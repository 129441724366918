/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  PropsWithChildren,
  useEffect,
  useMemo,
  FormEvent,
  useRef,
  BaseSyntheticEvent,
  memo,
} from 'react'

import { Input, Button } from 'components'
import { Gray, Orange } from 'assets/styled-component/variables'

import { useModal, Icon, InputProps } from '@inter/inter-ui'

import { SelectList, Header } from './style'
import { ItemList } from './ItemList'

import './modal.scss'

type TOption = {
  key: string | number
  value: string
}

type IInputProps = InputProps & {
  options: TOption[]
  setSelectValue: (value: string) => void
  name?: string
  isUsingFilter?: boolean
  placeholderFilter?: string
  loading?: boolean
}

const Component: React.FC<PropsWithChildren<IInputProps>> = ({
  id,
  label,
  placeholder,
  options,
  setSelectValue,
  error = false,
  infoText = '',
  isUsingFilter = false,
  placeholderFilter = '',
  loading = false,
  ...props
}: PropsWithChildren<IInputProps>) => {
  const [filterValue, setFilterValue] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)
  const modal = useModal()
  const refFilterSearch = useRef<HTMLInputElement>(null)

  const handleClose = () => {
    modal.closeModal()
  }

  const handleClickOption = (option: TOption) => {
    setSelectValue(option.value)
    handleClose()
  }

  const formatWordToFilter = (value: string) =>
    value
      .normalize(`NFD`)
      .replace(/[\u0300-\u036f]/g, '')
      .toLocaleLowerCase()

  const filterOptions = (value: string) => {
    if (value) {
      const filtered = options.filter((option: TOption) =>
        formatWordToFilter(option.value).includes(formatWordToFilter(value)),
      )
      setFilteredOptions(filtered)
    } else {
      setFilteredOptions(options)
    }
  }

  const setValueFilter = (value: string) => {
    setFilterValue(value)
    filterOptions(value)
  }

  const handleFilterChange = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setValueFilter(value)
  }

  const handleCleanFilter = () => {
    setValueFilter('')

    if (refFilterSearch && refFilterSearch.current) {
      refFilterSearch.current.value = ''
    }
  }

  const displayHeader = (
    <Header>
      <div className="line-header" />
      <div className="flex-row">
        <span>{label}</span>
        {isUsingFilter && (
          <Button variant="link" onClick={handleClose}>
            Fechar
          </Button>
        )}
      </div>
      {isUsingFilter && (
        <Input
          id="search"
          name="search"
          ref={refFilterSearch}
          placeholder={placeholderFilter}
          className="filterOptions"
          value={filterValue}
          onChange={handleFilterChange}
          onClickIconRight={handleCleanFilter}
          iconLeft={!filterValue && <Icon name="search" size="20px" />}
          iconRight={filterValue && <Icon name="clear" size="20px" />}
          style={{ paddingBottom: 0 }}
        />
      )}
    </Header>
  )

  const handleFocus = (element: BaseSyntheticEvent) => {
    element.target.blur()
  }

  const displayListOptions = useMemo(
    () => (
      <SelectList>
        {filteredOptions.map((option: TOption) => (
          <ItemList
            key={option.key}
            text={option.value}
            value={option.key}
            Icon={<Icon name="arrow-right" size={24} color={Orange[500]} />}
            onClick={() => handleClickOption(option)}
          />
        ))}
      </SelectList>
    ),
    [filteredOptions],
  )

  const heightFooter = <div style={{ height: '300px' }} />

  const handleDisplayModal = () => {
    modal.openModal({
      header: displayHeader,
      content: displayListOptions,
      footer: isUsingFilter ? heightFooter : undefined,
    })
  }

  useEffect(() => {
    if (modal?.modalSettings?.opened) {
      handleDisplayModal()
    }
  }, [filteredOptions])

  return (
    <Input
      id={id}
      label={label}
      placeholder={placeholder}
      readOnly
      onFocus={handleFocus}
      className={props.readOnly ? 'readonlyDisabled' : 'customSelect'}
      onClick={props.readOnly ? undefined : handleDisplayModal}
      iconRight={
        <Icon
          name={loading ? 'loading' : 'arrow-down'}
          size={24}
          color={props.readOnly ? Gray[200] : Orange[500]}
        />
      }
      onClickIconRight={props.readOnly ? undefined : handleDisplayModal}
      error={error}
      infoText={infoText}
      {...props}
    />
  )
}

export const Select = memo(Component)
