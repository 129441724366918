import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  margin-top: auto;
  display: grid;

  button:not(:first-child) {
    margin-top: 16px;
  }
`
