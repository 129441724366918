export enum EPasswordComplexity {
  BAD = 'bad',
  GOOD = 'good',
  GREAT = 'great',
  PERFECT = 'perfect',
  FANTASTIC = 'fantastic',
}

export type TVariations = {
  digits: boolean
  lower: boolean
  upper: boolean
  nonWords: boolean
  sequence: boolean
  identicalSequence: boolean
}

export type TScorePassword = {
  status: {
    complexity: EPasswordComplexity | undefined
    score: number
  }
  variations: TVariations
}

export const checkPassStrength = (score: number): EPasswordComplexity => {
  if (score <= 25) {
    return EPasswordComplexity.BAD
  }
  if (score <= 45) {
    return EPasswordComplexity.GOOD
  }
  if (score <= 65) {
    return EPasswordComplexity.GREAT
  }
  if (score <= 85) {
    return EPasswordComplexity.PERFECT
  }
  return EPasswordComplexity.FANTASTIC
}

export const defaultScorePassword: TScorePassword = {
  status: {
    complexity: undefined,
    score: 0,
  },
  variations: {
    digits: false,
    lower: false,
    nonWords: false,
    upper: false,
    sequence: false,
    identicalSequence: false,
  },
}

export const scorePassword = (pass: string | undefined): TScorePassword => {
  let score = 0
  if (!pass) {
    return defaultScorePassword
  }

  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i += 1) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  // bonus points
  const variations: TVariations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
    sequence: checkSequence(pass),
    identicalSequence: checkdenticalSequence(pass),
  }

  let variationCount = 0
  Object.keys(variations).forEach((k) => {
    if (variations[k] === true) {
      variationCount += 1
    }
  })
  score += (variationCount - 1) * 10

  return {
    status: {
      score: parseInt(score.toString(), 10),
      complexity: checkPassStrength(parseInt(score.toString(), 10)),
    },
    variations,
  }
}

const isDigitOrLetter = (teste: string) => /\d/.test(teste) || /[a-z]/.test(teste)

const checkSequence = (password: string | undefined) => {
  let sequenceDetected = false
  if (typeof password === 'string') {
    const lowerPassword = password.toLocaleLowerCase()

    for (let index = 1; index < lowerPassword.length - 1; index += 1) {
      if (isDigitOrLetter(lowerPassword[index])) {
        const firstLetterCode = lowerPassword.charCodeAt(index - 1)
        const intermediateLetterCode = lowerPassword.charCodeAt(index)
        const lastLetterCode = lowerPassword.charCodeAt(index + 1)

        sequenceDetected =
          (firstLetterCode === intermediateLetterCode - 1 &&
            lastLetterCode === intermediateLetterCode + 1) ||
          (firstLetterCode === intermediateLetterCode + 1 &&
            lastLetterCode === intermediateLetterCode - 1)

        if (sequenceDetected) break
      }
    }
  }

  return sequenceDetected
}

const checkdenticalSequence = (password: string | undefined) => {
  let sequenceDetected = false
  if (typeof password === 'string') {
    const lowerPassword = password.toLocaleLowerCase()

    for (let index = 1; index < lowerPassword.length - 1; index += 1) {
      sequenceDetected =
        lowerPassword[index - 1] === lowerPassword[index] &&
        lowerPassword[index] === lowerPassword[index + 1]
      if (sequenceDetected) break
    }
  }

  return sequenceDetected
}
