import React, { ReactNode } from 'react'

import { ListInfoWrapper, StepNumber } from './style'

type TListInfo = {
  pageNumber: string
  children: ReactNode
}

export const ListInfo: React.FC<TListInfo> = ({ pageNumber, children }: TListInfo) => (
  <ListInfoWrapper>
    <StepNumber>
      <strong>{pageNumber}</strong>
    </StepNumber>
    {children}
  </ListInfoWrapper>
)
