/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'

import { PageContainer, ButtonWrapper, Button, PageText, Spacing } from 'components'
import { IPosAberturaCtx } from 'interfaces/posAbertura.interface'
import { FichaCadastralCtx, PosAberturaCtx } from 'App'
import { useHistory } from 'react-router-dom'
import { sendDataNative, TSendDataNative } from 'components/hooks/useFetchNative'
import { DESCRIPTION_SHARE_FRIENDS } from 'shared/functions/constants'
import { IShare } from 'interfaces/share.interface'
import { sendToNewRelic } from 'services'
import { IFichaCadastralCtx } from 'interfaces/fichaCadastral.interface'
import { RoutesEnum } from 'enums/routes.enum'
import url from 'shared/functions/url'

import { Text } from '@inter/inter-ui'

import { ListItens } from './listInfo'

const LIST_OPTIONS = {
  '/conta-mei': 'CONTA_MEI',
  '/conta-global': 'CONTA_GLOBAL',
  '/indicar-amigos': 'INDICAR_AMIGOS',
  '/inter-loop': 'INTER_LOOP',
}

const HomePosAbertura = () => {
  const { posAberturaOptions }: IPosAberturaCtx = useContext<IPosAberturaCtx>(PosAberturaCtx)

  const history = useHistory()
  const { isMinor } = url.queryParam()

  const { hsfc }: IFichaCadastralCtx = useContext<IFichaCadastralCtx>(FichaCadastralCtx)
  const hsfcValue = (): string => hsfc || (sessionStorage.getItem('hsfc') as string)

  const indicarAmigos = () => {
    const callApi: TSendDataNative<IShare> = {
      callback: 'callbackIndicarAmigos',
      body: { description: DESCRIPTION_SHARE_FRIENDS },
      endpoint: '',
      method: 'SHARE',
    }
    sendDataNative(callApi)
  }

  const handleFinalizarAbertura = () => {
    history.push(RoutesEnum.FIM_ONBOARDING)
    sendToNewRelic('POS_ABERTURA', `[CLIK] - Continuar`, {
      FICHA_CADASTRAL: hsfcValue(),
      PRODUCT: 'CONTA_DIGITAL',
      MINOR: isMinor ? 'KIDS' : 'PF',
    })
  }

  const handleItemClick = (link: string) => {
    sendToNewRelic('POS_ABERTURA', `[CLIK] - ${LIST_OPTIONS[link]}`, {
      FICHA_CADASTRAL: hsfcValue(),
      PRODUCT: 'CONTA_DIGITAL',
      MINOR: isMinor ? 'KIDS' : 'PF',
    })

    if (link === 'INDICAR_AMIGOS') {
      indicarAmigos()
    } else {
      history.push(link)
    }
  }

  useEffect(() => {
    sendToNewRelic('POS_ABERTURA', '[Screen] - TUDO CERTO', {
      FICHA_CADASTRAL: hsfcValue(),
      PRODUCT: 'CONTA_DIGITAL',
      MINOR: isMinor ? 'KIDS' : 'PF',
    })
  }, [])

  return (
    <PageContainer>
      <PageText text="Que tal aproveitar todo o potencial do Inter?" margin="80px 0px 16px 0px" />

      <Spacing mb="lg">
        <Text variant="body-3">
          Tenha diversas modalidades de conta e aproveite um universo de oportunidades!
        </Text>
      </Spacing>

      <ListItens items={posAberturaOptions} onClick={handleItemClick} />

      <ButtonWrapper>
        <Button onClick={handleFinalizarAbertura}>Continuar</Button>
      </ButtonWrapper>
    </PageContainer>
  )
}

export default HomePosAbertura
