import styled from 'styled-components'
import { White } from 'assets/styled-component/variables'

export const FullPageWrapper = styled.div`
  position: relative;
  z-index: 1000;

  .backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    visibility: hidden;
  }

  .modal {
    transition: all 0.5s ease-in;
    visibility: hidden;
    top: 0px;
    left: 0px;
    position: fixed;

    .modal-content {
      background: ${White[500]};
      width: 100%;
      position: fixed;
      bottom: -100%;
      left: 0;
      min-height: 200px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      transition: all 0.5s ease-in;

      &.modal-fullscreen {
        height: 100%;
        border-radius: 0;
      }
    }

    h3 {
      margin: 1.25rem 0 0.75rem;
    }
  }

  &.active {
    position: relative;

    .backdrop {
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.5s ease;
      visibility: visible;
    }

    .modal {
      visibility: visible;
      transition: all 0.5s ease-in;

      .modal-content {
        bottom: 0px;
      }
    }
  }
`
