import styled from 'styled-components'
import { White } from 'assets/styled-component/variables'

import { Text } from '@inter/inter-ui'

export const CardContainer = styled.div`
  justify-content: rigth;
  margin-bottom: 16px;
  border: 1px solid;
  border-radius: 8px;
  display: grid;
`

export const CardContentVirtual = styled.div`
  position: relative;
  padding: 8px 16px 16px 16px;
  display: flex;
  align-items: center;
`

export const CardContentFisico = styled.div`
  position: relative;
  padding: 32px 16px;
  display: flex;
`

export const EscolhaCartaoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  .card-container {
    position: relative;
    display: inline-flex;

    &:after {
      content: attr(data-card-name);
      position: absolute;
      font-size: 12px;
      color: ${White[500]};
      right: 18px;
      left: 16px;
      top: 125px;
      text-transform: uppercase;
      font-weight: 700;
    }

    &.kids {
      &:after {
        left: 21px;
        top: 152px;
      }
    }
  }
`

export const TextSaibaMais = styled(Text)`
  width: 80px;
  padding: 0 16px;
  color: var(--light-brand-primary-500, #ff7a00);
`
