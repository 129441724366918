import { BaseServices, EHttpMethod } from 'services/base'
import { urls } from 'services/base/url'

import { TRequestSendCodePayload } from './types'

const request = (payload: TRequestSendCodePayload) =>
  BaseServices.request({
    pathname: urls.PUT_CODIGO_INDICAR,
    method: EHttpMethod.PUT,
    host: process.env.REACT_APP_API_HOST,
    data: { ...payload.data },
    headers: { 'x-id-ficha-cadastral': payload.header.hsfc },
  })

export const putCodigoIndicar = async (payload: TRequestSendCodePayload) => {
  try {
    const response = await request(payload)

    payload.success && payload.success(response.status)
  } catch (error) {
    payload.error && payload.error(error)
  } finally {
    payload.finally && payload.finally()
  }
}
