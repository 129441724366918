import React from 'react'

import {
  PageContainer,
  ButtonWrapper,
  Button,
  BoxVerticalCenter,
  BoxCenter,
  Text,
  Spacing,
} from 'components'
import { sendDataNative } from 'components/hooks/useFetchNative'
import { TrackEventEnum, ITrackEvent } from 'interfaces/trackEvent.interface'
import sinalError from 'assets/images/sinalError.svg'
import url from 'shared/functions/url'

const PendenciamentoReprovado = () => {
  const onNext = () => {
    sendDataNative({
      callback: 'callbackFinishOnBoarding',
      method: 'FINISHONBOARDING',
    })
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_PENDENCIAMENTO_REPROVADO_CONTINUAR,
        parameters: {},
      },
    })
  }

  const { periodoMinimo, dataLiberacao } = url.queryParam()

  return (
    <PageContainer>
      <BoxVerticalCenter>
        <BoxCenter>
          <img src={sinalError} alt="Pendenciamento reprovado" width={64} />
        </BoxCenter>

        <Spacing mt="sm">
          <Text variant="headline-h1" as="h1" style={{ textAlign: 'center' }}>
            Sua conta foi negada há menos de{' '}
            {periodoMinimo !== 'null' ? url.queryParam().periodoMinimo : '180'} dias
          </Text>
        </Spacing>

        <Text variant="body-3" as="span" colorWeight={500} style={{ textAlign: 'center' }}>
          Você poderá solicitar uma nova abertura de conta a partir de{' '}
          <b>{dataLiberacao !== 'null' ? dataLiberacao : '180 dias'}</b>.
        </Text>
      </BoxVerticalCenter>

      <ButtonWrapper>
        <Button id="submit" onClick={onNext}>
          Entendi
        </Button>
      </ButtonWrapper>
    </PageContainer>
  )
}

export default PendenciamentoReprovado
