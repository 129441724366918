import { getCookie } from 'inter-frontend-lib-util'
import { IS_DEVELOPMENT } from 'common/constants'

import { FetchOptions } from './types'

const token = getCookie('TokenBearer') as string

export const api = async (url: string, options: FetchOptions['options']) => {
  let response = await fetch(`${process.env.BASE_URL}/${url}`, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      authorization: `bearer ${token}`,
      'x-inter-origem': 'IBPJ',
      ...options.headers,
    },
  })
  if (!response.ok) {
    if (IS_DEVELOPMENT) {
      response = await fetch(`${window.location.origin}/${url}`, options)
      if (response.status >= 500) {
        throw response
      }
    } else {
      throw response
    }
  }
  return response
}
