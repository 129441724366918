import React from 'react'

import ArrowLeft from '@interco/icons/core/action-navigation/ic_arrow_left'
import ArrowRight from '@interco/icons/core/action-navigation/ic_arrow_right'
import Card from '@interco/icons/core/finance/ic_credit_card'
import Check from '@interco/icons/core/action-navigation/ic_check'
import CheckCircle from '@interco/icons/core/status/ic_check_circle'
import CheckFill from '@interco/icons/core/status/ic_check_circle_fill'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import CardProtection from '@interco/icons/core/finance/ic_credit_card_shield'
import Download from '@interco/icons/core/action-navigation/ic_arrow_download'
import Gift from '@interco/icons/core/shopping/ic_gift'
import Globe from '@interco/icons/core/home-objects/ic_globe'
import Help from '@interco/icons/core/status/ic_help_circle'
import Info from '@interco/icons/core/status/ic_info_circle'
import Loading from '@interco/icons/core/misc/ic_loading'
import Loop from '@interco/icons/core/brands/ic_inter_loop'
import Message from '@interco/icons/core/media-communication/ic_message'
import Nfc from '@interco/icons/core/finance/ic_credit_card_nfc'
import PixOutline from '@interco/icons/core/brands/ic_pix'
import Receipt from '@interco/icons/core/files/ic_document'
import Share from '@interco/icons/core/action-navigation/ic_share'
import TransferMoney from '@interco/icons/core/finance/ic_rotate_money'
import UserMoney from '@interco/icons/core/human/ic_user_money'
import Whatsapp from '@interco/icons/core/brands/ic_whatsapp'
import PdfFile from '@interco/icons/core/files/ic_file_pdf'

import { TIconComponentProps } from './types'

export const Icons = ({
  variant,
  color = 'var(--primary500)',
  width,
  height,
  ...props
}: TIconComponentProps) => {
  const Icon = variant
  const defaultSize = 24

  return (
    <Icon {...props} color={color} width={width || defaultSize} height={height || defaultSize} />
  )
}

export {
  ArrowLeft,
  ArrowRight,
  Card,
  Check,
  CheckFill,
  ChevronRight,
  Download,
  Info,
  Gift,
  Globe,
  Help,
  Loading,
  Loop,
  UserMoney,
  Message,
  Nfc,
  PixOutline,
  Receipt,
  Share,
  TransferMoney,
  Whatsapp,
  CheckCircle,
  CardProtection,
  PdfFile,
}
