import * as yup from 'yup'
import { dontUseSpecialCharacters, REQUIRED_MESSAGE } from 'validations/messages'

export const yupValidacaoNaturalidade = yup.object().shape({
  nacionalidade: yup.string().required(REQUIRED_MESSAGE('País')),
  naturalidade: yup.string().when('nacionalidade', {
    is: (value: string) => value !== 'Brasil',
    then: yup
      .string()
      .trim()
      .required(REQUIRED_MESSAGE('Cidade'))
      .matches(dontUseSpecialCharacters.rule, dontUseSpecialCharacters.message),
    otherwise: yup.string().nullable(),
  }),
  ufNaturalidade: yup.string().when('nacionalidade', {
    is: (value: string) => value === 'Brasil',
    then: yup.string().required(REQUIRED_MESSAGE('Estado')),
    otherwise: yup.string().nullable(),
  }),
  cidade: yup.string().when('nacionalidade', {
    is: (value: string) => value === 'Brasil',
    then: yup.string().required(REQUIRED_MESSAGE('Cidade')),
    otherwise: yup.string(),
  }),
})
