import styled from 'styled-components'
import { Chips } from 'components'

export const EChips = styled(Chips)`
  & > div {
    display: flex;
    height: 30px;
    align-items: center;

    div {
      display: flex;
    }
  }
`
