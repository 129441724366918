import React, { useContext } from 'react'

import sinalPending from 'assets/images/sinalPending.svg'
import { PageContainer, ButtonWrapper, Button, BoxVerticalCenter, BoxCenter } from 'components'
import { TSendDataNative, sendDataNative } from 'components/hooks/useFetchNative'
import { IApiResponseCtx, ApiResponse } from 'interfaces/response.interface'
import { ApiResponseCtx } from 'App'

import { Text } from '@inter/inter-ui'

const PendenciasRefazer = () => {
  const { apiResponse }: IApiResponseCtx = useContext<IApiResponseCtx>(ApiResponseCtx)

  const onClickRefazer = () => {
    const callApi: TSendDataNative<ApiResponse> = {
      body: apiResponse,
      callback: 'callbackMediaUpdate',
      endpoint: '',
      method: 'CALLMEDIAUPDATE',
    }
    sendDataNative(callApi)
  }

  return (
    <PageContainer>
      <BoxVerticalCenter>
        <BoxCenter>
          <img src={sinalPending} alt="Pendencias refazer!" width={64} />
        </BoxCenter>
        <Text variant="headline-h1" as="h1" style={{ textAlign: 'center', marginTop: '24px' }}>
          Quase lá!
        </Text>
        <Text variant="body-3" as="p" style={{ textAlign: 'center' }}>
          Precisamos que você reenvie um ou mais documentos, combinado? No próximo passo, te
          mostraremos quais são.
        </Text>
      </BoxVerticalCenter>

      <ButtonWrapper>
        <Button id="submit" onClick={onClickRefazer}>
          Reenviar
        </Button>
      </ButtonWrapper>
    </PageContainer>
  )
}

export default PendenciasRefazer
