/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'

import { useHistory } from 'react-router-dom'
import {
  PageContainer,
  ButtonWrapper,
  InputAuthentication,
  BoxCenter,
  Text,
  PageText,
  Spacing,
} from 'components'
import { TSendDataNative, sendDataNative } from 'components/hooks/useFetchNative'
import { EMAIL_REENVIO, EMAIL_VALIDACAO, EMAIL_REINICIAR_FICHA } from 'components/urls'
import { useCallbackNative } from 'components/hooks/useCallbackNative'
import { getRouter } from 'enums/routes.enum'
import { ResponseEmpty } from 'interfaces/responseEmpty.interface'
import url from 'shared/functions/url'
import { ITrackEvent, TrackEventEnum } from 'interfaces/trackEvent.interface'
import { GenericRes, ApiResponse, ApiError } from 'interfaces/response.interface'
import { IDadosPessoais, IDadosPessoaisCtx } from 'interfaces/dadosPessoais.interface'
import { DadosPessoaisCtx } from 'App'
import { TWO_SECONDS } from 'shared/functions/constants'
import { sendToNewRelic } from 'services'
import { ellipsis } from 'shared/functions/utils'
import { CartoesEnum } from 'enums/cartoes.enum'
import { TypeTokensEnum } from 'enums/typeTokens.enum'
import { TErrorObject } from 'components/elements/InputAuthentication'
import { getUrlParams } from 'shared/helpers/getUrlPrams'

import { BottomSheet } from '@inter/inter-ui'
import { Button } from '@interco/inter-ui/components/Button'

type TDados = {
  tipoCartao?: string
  escolherCartaoVirtual?: boolean
  isUnderFourteen?: string
  destinoToken: TypeTokensEnum
}

const PinEmail = () => {
  const { dadosPessoais }: IDadosPessoaisCtx = useContext<IDadosPessoaisCtx>(DadosPessoaisCtx)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingRestart, setLoadingRestart] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [PIN, setPIN] = useState<string>('')
  const history = useHistory()
  const [error, setError] = useState<TErrorObject | null>(null)
  const [counter, setCounter] = useState(30)
  const [dadosPessoaisStorage, setDadosPessoaisStorage] = useState<IDadosPessoais>(dadosPessoais)

  const clearError = () => {
    setError(null)
  }

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
  }, [counter])

  useEffect(() => {
    if (PIN) {
      setLoading(true)
      clearError()
      const callApi: TSendDataNative = {
        body: { token: PIN },
        callback: 'callbackApiEmail',
        endpoint: EMAIL_VALIDACAO,
        method: 'POST',
      }
      sendDataNative(callApi)
      sendDataNative<ITrackEvent>({
        method: 'TRACKEVENT',
        body: {
          name: TrackEventEnum.TELA_EMAIL_ENVIAR,
          parameters: {
            email: dadosPessoais.email,
          },
        },
      })
      setTimeout(() => {
        setLoading(false)
      }, TWO_SECONDS)
    }
  }, [PIN])

  useCallbackNative('callbackApiEmail', 'onCallbackApiEmail', {
    onCallbackApiEmail: (data: GenericRes) => {
      if (data instanceof ApiResponse) {
        const step = getRouter(data.telas.stepList[0].step)

        sendToNewRelic('PIN_EMAIL', '[ApiResponse] - callbackApiEmail', {
          PROXIMA_ETAPA: step,
          URL: data.telas.stepList[0].url,
          email: dadosPessoais.email,
        })

        if (step) {
          const dados = data.dados as TDados

          history.push(
            `${step}${url.buildQuery({
              nome: data.nome,
              tipoCartao: dados?.tipoCartao || CartoesEnum.GOLDPADRAO,
              escolherCartaoVirtual: dados?.escolherCartaoVirtual || false,
              ...getUrlParams(data.telas.stepList[0].url),
            })}`,
          )
        } else {
          const callApi: TSendDataNative = {
            body: data,
            callback: 'callbackMediaUpdate',
            endpoint: '',
            method: 'CALLMEDIAUPDATE',
          }
          sendDataNative(callApi)
        }
      } else if (data instanceof ApiError) {
        setError(data)
        sendToNewRelic('PIN_EMAIL', '[ApiError] - callbackApiEmail', {
          email: dadosPessoais.email,
          ...data,
        })
      }
      setLoading(false)
    },
  })

  useCallbackNative('callbackRestartAberturaEmail', 'onCallbackRestartAberturaEmail', {
    onCallbackRestartAberturaEmail: (data: GenericRes) => {
      setLoading(false)
      if (data instanceof ApiResponse) {
        const step = getRouter(data.telas.stepList[0].step)

        sendToNewRelic(
          'PIN_EMAIL_REINICIAR_ABERTURA',
          '[ApiResponse] - callbackRestartAberturaEmail',
          {
            PROXIMA_ETAPA: step,
            URL: data.telas.stepList[0].url,
          },
        )
        if (step) {
          history.push(`${step}${url.buildQuery(getUrlParams(data.telas.stepList[0].url))}`)
        } else {
          sendDataNative<TSendDataNative>({
            callback: 'restartOnboarding',
            endpoint: '',
            method: 'RESTARTONBOARDING',
          })
        }
      } else if (data instanceof ResponseEmpty) {
        sendDataNative<TSendDataNative>({
          callback: 'restartOnboarding',
          endpoint: '',
          method: 'RESTARTONBOARDING',
        })
      }
      if (!(data as ApiError)?.httpCodigo || !(data as ApiError)?.campo) {
        sendDataNative<TSendDataNative>({
          callback: 'restartOnboarding',
          endpoint: '',
          method: 'RESTARTONBOARDING',
        })
      } else {
        sendToNewRelic(
          'PIN_EMAIL_REINICIAR_ABERTURA',
          '[ApiError] - callbackRestartAberturaEmail',
          {
            ...data,
          },
        )
      }
      setLoadingRestart(false)
    },
  })

  const reSend = () => {
    setLoading(true)
    clearError()
    const callApi: TSendDataNative = {
      callback: 'callbackApiEmpty',
      endpoint: EMAIL_REENVIO,
      method: 'POSTWRESPONSE',
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_EMAIL_REENVIAR,
        parameters: {
          email: dadosPessoais.email,
        },
      },
    })
    setTimeout(() => {
      setLoading(false)
    }, TWO_SECONDS)
    sendToNewRelic('PIN_EMAIL', 'Envio de Token')
  }

  const sendBackToPrevStep = () => {
    setLoadingRestart(true)
    const callApi: TSendDataNative = {
      callback: 'callbackRestartAberturaEmail',
      endpoint: EMAIL_REINICIAR_FICHA,
      method: 'POSTWRESPONSE',
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_REVALIDAR_EMAIL_POPUP_CONTINUAR,
        parameters: {},
      },
    })
    sendToNewRelic('PIN_EMAIL', 'Reiniciar com outro email')
  }

  const closePopUp = () => {
    setOpenModal(false)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_REVALIDAR_EMAIL_POPUP_VOLTAR,
        parameters: {},
      },
    })
  }

  const onNoAccessEmail = () => {
    setOpenModal(true)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_REVALIDAR_EMAIL_SEM_ACESSO,
        parameters: {},
      },
    })
  }

  useEffect(() => {
    const getDadosPessoaisStorage = localStorage.getItem('dadosPessoais')

    if (getDadosPessoaisStorage) {
      setDadosPessoaisStorage(JSON.parse(getDadosPessoaisStorage))
    }

    reSend()
  }, [])

  return (
    <PageContainer>
      <PageText text="Agora enviamos um código para o seu e-mail" margin="80px 0px 24px 0px" />

      <Text variant="body-3">
        Digite o código enviado para {` `}
        {dadosPessoaisStorage?.email && <strong>{ellipsis(dadosPessoaisStorage.email, 30)}</strong>}
        .
      </Text>

      <Spacing mt="lg" mb="lg">
        <BoxCenter>
          <InputAuthentication callbackValue={setPIN} error={error} />
        </BoxCenter>
      </Spacing>

      <BoxCenter>
        <Button
          variant="secondary"
          isLoading={loading}
          disabled={counter > 0}
          onClickCapture={() => {
            reSend()
            setCounter(30)
          }}
        >
          Reenviar código {counter > 0 ? `${counter}s` : null}
        </Button>
      </BoxCenter>

      <ButtonWrapper>
        <Button id="submit" variant="link" onClick={onNoAccessEmail}>
          Não tenho acesso a esse e-mail
        </Button>
      </ButtonWrapper>

      {openModal && (
        <BottomSheet onClose={closePopUp}>
          <h3>Não tenho acesso ao e-mail</h3>
          <p className="mb-4">
            Você pode reiniciar o processo de abertura da conta com outro e-mail. Quer mesmo
            reiniciar?
          </p>

          <ButtonWrapper>
            <Button isLoading={loadingRestart} onClick={sendBackToPrevStep}>
              Reiniciar
            </Button>
            <Button variant="secondary" onClick={closePopUp}>
              Voltar
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}
    </PageContainer>
  )
}

export default PinEmail
