import React from 'react'

import { useHistory } from 'react-router-dom'
import { PageContainer, ButtonWrapper, Button, BoxCenter, BoxVerticalCenter } from 'components'
import sinalPending from 'assets/images/sinalPending.svg'

import { Text } from '@inter/inter-ui'

const Pendenciamento = () => {
  const history = useHistory()

  const continuePending = () => {
    history.push('/conheca-o-banco')
  }

  return (
    <PageContainer>
      <BoxVerticalCenter>
        <BoxCenter>
          <img src={sinalPending} alt="Falta pouco" width={64} />
        </BoxCenter>
        <Text variant="headline-h1" as="h1" style={{ textAlign: 'center', marginTop: '24px' }}>
          Solicitação em análise!
        </Text>

        <Text variant="body-3" as="p" style={{ textAlign: 'center' }}>
          O prazo de resposta é de três dias úteis, mas o retorno pode chegar antes disso. Acompanhe
          seu e-mail e as notificações no Super App!
        </Text>
      </BoxVerticalCenter>

      <ButtonWrapper>
        <Button id="submit" onClick={continuePending}>
          Entendi
        </Button>
      </ButtonWrapper>
    </PageContainer>
  )
}

export default Pendenciamento
