import React, { FormHTMLAttributes, PropsWithChildren } from 'react'

import { DivFlex, CustomFlexForm } from './styled'

export const FlexForm: React.FC<PropsWithChildren<FormHTMLAttributes<HTMLFormElement>>> = ({
  children,
  ...props
}: PropsWithChildren<FormHTMLAttributes<HTMLFormElement>>) => (
  <DivFlex>
    <CustomFlexForm {...props}>{children}</CustomFlexForm>
  </DivFlex>
)
