import { TRoute } from 'enums/routes.enum'

import { ResponseEmpty } from './responseEmpty.interface'
import { ApiError } from './response.interface.error'

export { ApiError, convertErrorsFrom } from './response.interface.error'

interface IStep {
  step: TRoute
  url: string
  show: boolean
  tag: string
}

interface ITelas {
  flow: string
  stepList: IStep[]
}

export class ApiResponse<T = unknown> {
  public telas!: ITelas

  public dados!: T

  public flavor?: string

  public nome?: string
}

export type GenericRes<T = unknown> = ApiResponse<T> | ResponseEmpty | ApiError

export interface IApiResponseCtx<T = unknown> {
  apiResponse: ApiResponse<T>
  setApiResponse: React.Dispatch<React.SetStateAction<ApiResponse<unknown>>>
}
