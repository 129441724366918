import { ServiceResponseError } from 'exceptions'
import { BaseServices, EHttpMethod, TPayloadCallbacks } from 'services/base'
import { urls } from 'services/base/url'

import { TLoyaltyTermResponse } from './types'

const request = () =>
  BaseServices.request<TLoyaltyTermResponse>({
    pathname: urls.GET_LOYALTY_TERMS,
    method: EHttpMethod.GET,
    host: process.env.REACT_APP_API_HOST_CONTA_DIGITAL,
  })

export const getLoyaltyTerms = async (
  payload: TPayloadCallbacks<TLoyaltyTermResponse, unknown>,
) => {
  try {
    const response = await request()
    payload.success && payload.success(response.data)
  } catch (error: unknown) {
    if (error instanceof ServiceResponseError) {
      payload.error && payload.error(error)
    }
  } finally {
    payload.finally && payload.finally()
  }
}
