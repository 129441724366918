import React, { useState } from 'react'

import { ArrowLeft, ArrowRight } from 'components/elements/Icons'
import { Icons, Button, ButtonWrapper } from 'components'
import { TrackEventEnum } from 'interfaces/trackEvent.interface'
import { useTrackEvent } from 'hooks'
import { sendToNewRelic } from 'services'

import * as S from './style'

type TFooterProps = {
  currentPage: number
  totalPage: number
  loading: boolean
  onConfirmButton: () => void
  onClickPreview: () => void
  onClickNext: () => void
  hsfcValue: string
  track_name: string
  hasCheck?: boolean
  setShowTermsList?: (value: boolean) => void
}

const Footer = ({
  currentPage,
  totalPage,
  loading,
  onConfirmButton,
  onClickPreview,
  onClickNext,
  hsfcValue,
  track_name,
  hasCheck,
  setShowTermsList,
}: TFooterProps) => {
  const [termsAccepted, setTermsAccepted] = useState(false)
  const sendTrackEventData = useTrackEvent()

  const handleClickContinuar = () => {
    setTermsAccepted(true)

    sendToNewRelic('POS_ABERTURA', TrackEventEnum.GERAL_CHECKBOX, {
      content_id: track_name,
      content_name: 'Checkbox aceite dos termos',
      FICHA_CADASTRAL: hsfcValue,
    })

    sendTrackEventData(TrackEventEnum.GERAL_BOTAO, {
      content_id: track_name,
      content_name: 'Continuar',
    })
    sendToNewRelic('POS_ABERTURA', TrackEventEnum.GERAL_BOTAO, {
      content_id: track_name,
      content_name: 'Continuar',
      FICHA_CADASTRAL: hsfcValue,
    })
    onConfirmButton()
  }

  return (
    <S.FooterContainer>
      <S.Controller>
        <Icons
          variant={ArrowLeft}
          onClick={onClickPreview}
          color={currentPage === 1 ? 'var(--gray200)' : undefined}
        />
        <span>
          <p>
            Página {currentPage}/{totalPage}
          </p>
        </span>
        <Icons
          variant={ArrowRight}
          onClick={onClickNext}
          color={currentPage === totalPage ? 'var(--gray200)' : undefined}
        />
      </S.Controller>

      <S.Divider />

      <p>
        Ao clicar em continuar, você concorda com o{' '}
        <S.SButton onClick={() => setShowTermsList && setShowTermsList(true)} variant="link">
          E-sign Consent Agreement, Short Form e Long Form Disclosures, User Agreement, Contrato de
          Conta Inter&Co, Política de Privacidade da Inter&Co, Disclosures do InterPay e o Contrato
          de Conta Cayman e Cartão Internacional.
        </S.SButton>
      </p>

      <ButtonWrapper>
        <Button
          disabled={hasCheck && !termsAccepted}
          onClick={handleClickContinuar}
          isLoading={loading}
        >
          Continuar
        </Button>
      </ButtonWrapper>
    </S.FooterContainer>
  )
}

export default Footer
