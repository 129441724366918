type TData = {
  [key: string]: string
}

export default {
  queryParam: () => {
    const result: TData = {}
    let tmp: string[] = []
    if (window.location.hash.indexOf('?') > 0) {
      const items = window.location.hash.split('?')[1].split('&')
      for (let index = 0; index < items.length; index += 1) {
        tmp = items[index].split('=')
        result[tmp[0]] = decodeURIComponent(tmp[1])
      }
    }
    return result
  },
  buildQuery: (queries: { [key: string]: unknown }, alreadyHasBuilder = false) => {
    if (!queries) return ''
    let builder = alreadyHasBuilder ? '&' : '?'

    Object.entries(queries).forEach(([key, value]: [string, unknown]) => {
      builder += `${key}=${value}&`
    })
    return builder.slice(0, -1)
  },
}
