import React from 'react'

import { IconProps } from '@inter/inter-ui'

import { Item } from './style'

type TItemProps = {
  value: string | number
  text: string
  onClick: (value: string | number) => void
  Icon?: IconProps
}

export const ItemList: React.FC<TItemProps> = ({ value, text, Icon, onClick }: TItemProps) => (
  <Item onClick={() => onClick(value)}>
    {text}
    <div>{Icon}</div>
  </Item>
)

ItemList.defaultProps = {
  Icon: false,
}
