import { EHttpStatus } from 'services/base'

import { RESPONSE_VALIDA_CODIGO } from './constants'
import { TBuildCodeValidateResponse, TResponseInfo } from './types'

export const buildCodeValidateResponse = (data: TBuildCodeValidateResponse): TResponseInfo => {
  switch (data.status) {
    case EHttpStatus.SUCCESS:
      return RESPONSE_VALIDA_CODIGO.SUCCESS

    case EHttpStatus.UNPROCESSABLE_ENTITY:
      return RESPONSE_VALIDA_CODIGO.ERROR

    default:
      return RESPONSE_VALIDA_CODIGO.FAIL
  }
}
