import styled from 'styled-components'
import { Gray, Yellow, Red, Green } from 'assets/styled-component/variables'

export const ScorePasswordWrapper = styled.div`
  position: relative;
  margin-left: auto;

  .stronger-password {
    position: relative;
    top: 10px;
    display: flex;

    div {
      width: 14px;
      height: 3px;
      margin-right: 3px;
      background: ${Gray[100]};
      border-radius: 4px;
    }

    &.no {
      .score-0 {
        background: ${Red[500]};
      }
    }

    &.bad {
      .score-0 {
        background: ${Red[500]};
      }
    }

    &.good {
      .score-0 {
        background: ${Yellow[500]};
      }
    }

    &.great {
      .score-0,
      .score-1 {
        background: ${Yellow[500]};
      }
    }

    &.perfect {
      .score-0,
      .score-1 {
        background: ${Green[500]};
      }
    }

    &.fantastic {
      .score-0,
      .score-1,
      .score-2 {
        background: ${Green[500]};
      }
    }
  }
`
