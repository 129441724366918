import React, { useState, ChangeEvent, useEffect } from 'react'

import { Input, Button } from 'components'
import { ButtonWrapper } from 'components/layout/ButtonWrapper'

import { BottomSheet } from '@inter/inter-ui'

import { InputAuthenticationWrapper } from './style'

export type TErrorObject = {
  campo: string
  codigo: string
  httpCodigo: number
  mensagem: string
  token?: string
}

type TInputsPin = {
  callbackValue: (value: string) => void
  error: TErrorObject | null
}

const totalNumbers = 4

const MESSAGE_TOKEN_ERROR = 'Código de verificação inválido.'

export const InputAuthentication: React.FC<TInputsPin> = ({ callbackValue, error }) => {
  const [tokenValue, setTokenValue] = useState('')
  const [openModal, setOpenModal] = useState(false)

  const clearPin = () => {
    setOpenModal(false)
    ;(document.getElementById('token') as HTMLInputElement).value = ''
  }

  const validatePin = (event: ChangeEvent<HTMLInputElement>) => {
    const valuePin = event.currentTarget.value
    setTokenValue(valuePin)
    if (valuePin.length === totalNumbers) {
      ;(document.getElementById('token') as HTMLInputElement).blur()
      callbackValue(valuePin)
    }
  }

  const renderInputs = () =>
    new Array(totalNumbers).fill(0).map((_: string, index: number) => {
      const KeyValue = index
      return (
        <div
          key={KeyValue}
          className={`customInternetBanking pin pin${index + 1} ${
            Boolean(error) && 'erroValidation'
          }`}
        />
      )
    })

  const ua = navigator.userAgent.toLowerCase()
  const isAndroid = ua.includes('android')

  useEffect(() => {
    if (error && error.httpCodigo) {
      setOpenModal(true)
    }
  }, [error])

  return (
    <InputAuthenticationWrapper
      className={`${Boolean(error) && 'erroValidation'}`}
      style={{ width: '211px' }}
    >
      {renderInputs()}

      <Input
        id="token"
        type="tel"
        maxLength={totalNumbers}
        value={tokenValue}
        inputMode="numeric"
        placeholder="0000"
        mask="9999"
        className={`${Boolean(error) && 'erroValidation'}`}
        style={{ width: '247px' }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => validatePin(e)}
        autoComplete="one-time-code"
      />

      {error && error.httpCodigo && (
        <p className="error">{error.token ? error.mensagem : MESSAGE_TOKEN_ERROR}</p>
      )}

      {openModal && (
        <BottomSheet onClose={clearPin}>
          {isAndroid ? (
            <>
              <h3>
                {error && error.campo === 'token'
                  ? 'Ops, algo de errado aconteceu!'
                  : 'Verifique o código digitado'}
              </h3>
              {error && <p className="mb-4">{error.mensagem}</p>}
            </>
          ) : (
            <>
              <h3>{error ? error.mensagem : 'Verifique o código digitado'}</h3>
              {error && <p className="mb-4">{error.campo}</p>}
            </>
          )}
          <ButtonWrapper>
            <Button onClick={clearPin}>Entendi</Button>
          </ButtonWrapper>
        </BottomSheet>
      )}
    </InputAuthenticationWrapper>
  )
}
