import React from 'react'

import sinalSuccess from 'assets/images/sinalSuccess.svg'
import { TSendDataNative, sendDataNative } from 'components/hooks/useFetchNative'
import { PageContainer, ButtonWrapper, Button, BoxCenter, BoxVerticalCenter } from 'components'
import { DESCRIPTION_SHARE_FRIENDS } from 'shared/functions/constants'
import { IShare } from 'interfaces/share.interface'

import { Text } from '@inter/inter-ui'

const IndicarAmigos = () => {
  const handleIndicarAmigos = () => {
    const callApi: TSendDataNative<IShare> = {
      callback: 'callbackIndicarAmigos',
      body: { description: DESCRIPTION_SHARE_FRIENDS },
      endpoint: '',
      method: 'SHARE',
    }
    sendDataNative(callApi)
  }

  const handleFinalizarAbertura = () => {
    const callApi: TSendDataNative = {
      method: 'FINISHONBOARDING',
    }
    sendDataNative(callApi)
  }

  return (
    <PageContainer>
      <BoxVerticalCenter>
        <BoxCenter>
          <img src={sinalSuccess} alt="Tudo certo!" width={64} />
        </BoxCenter>

        <Text variant="headline-h1" as="h1" style={{ textAlign: 'center', marginTop: '24px' }}>
          Tudo certo!
        </Text>

        <Text variant="body-3" as="p" style={{ textAlign: 'center' }}>
          Já recebemos seus documentos e estamos analisando sua solicitação. Você receberá o nosso
          retorno em até 3 dias úteis.
        </Text>
        <Text variant="body-3" as="p" style={{ textAlign: 'center' }}>
          Enquanto isso, você pode indicar o Inter aos seus amigos. Aqui, nenhum deles paga tarifas,
          nem têm anuidade!
        </Text>
      </BoxVerticalCenter>

      <ButtonWrapper>
        <Button id="submit" type="submit" onClick={handleIndicarAmigos}>
          Indicar para os meus amigos
        </Button>

        <Button id="indicate" variant="secondary" onClick={handleFinalizarAbertura}>
          Indicar depois
        </Button>
      </ButtonWrapper>
    </PageContainer>
  )
}

export default IndicarAmigos
