/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'

import { obscurePhoneNumber } from 'shared/functions/utils'
import { useHistory } from 'react-router-dom'
import {
  PageContainer,
  ButtonWrapper,
  InputAuthentication,
  BoxCenter,
  Text,
  PageText,
  Icons,
  Spacing,
  BottomSheet,
  Loading,
} from 'components'
import { TSendDataNative, sendDataNative } from 'components/hooks/useFetchNative'
import { SMS_VALIDACAO, SMS_REENVIO, EMAIL_REINICIAR_FICHA } from 'components/urls'
import { useCallbackNative } from 'components/hooks/useCallbackNative'
import { getRouter } from 'enums/routes.enum'
import { ResponseEmpty } from 'interfaces/responseEmpty.interface'
import url from 'shared/functions/url'
import { IDadosPessoais, IDadosPessoaisCtx } from 'interfaces/dadosPessoais.interface'
import { ITrackEvent, TrackEventEnum } from 'interfaces/trackEvent.interface'
import { GenericRes, ApiResponse, ApiError } from 'interfaces/response.interface'
import { DadosPessoaisCtx } from 'App'
import { TWO_SECONDS } from 'shared/functions/constants'
import { sendToNewRelic } from 'services'
import { CartoesEnum } from 'enums/cartoes.enum'
import { Whatsapp, Message } from 'components/elements/Icons'
import { TypeTokensEnum } from 'enums/typeTokens.enum'
import { TErrorObject } from 'components/elements/InputAuthentication'
import { getUrlParams } from 'shared/helpers/getUrlPrams'

import { Button } from '@interco/inter-ui/components/Button'

type TDados = {
  tipoCartao?: string
  escolherCartaoVirtual?: boolean
  isUnderFourteen?: boolean
}

const PinSMS = () => {
  const { dadosPessoais }: IDadosPessoaisCtx = useContext<IDadosPessoaisCtx>(DadosPessoaisCtx)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingRestart, setLoadingRestart] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [PIN, setPIN] = useState<string>('')
  const [error, setError] = useState<TErrorObject | null>(null)
  const [openModalReenviar, setOpenModalReenviar] = useState(false)
  const [loadingResendSms, setLoadingResendSms] = useState<boolean>(false)
  const [loadingResendWpp, setLoadingResendWpp] = useState<boolean>(false)
  const [counter, setCounter] = useState(30)
  const [tokenType, setTokenType] = useState<TypeTokensEnum>(
    url.queryParam().destinoToken as TypeTokensEnum,
  )
  const [dadosPessoaisStorage, setDadosPessoaisStorage] = useState<IDadosPessoais>(dadosPessoais)

  const history = useHistory()

  const clearError = () => {
    setError(null)
  }

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
  }, [counter])

  useEffect(() => {
    if (PIN) {
      setLoading(true)
      clearError()
      const callApi: TSendDataNative = {
        body: { token: PIN },
        callback: 'callbackApiSMS',
        endpoint: SMS_VALIDACAO,
        method: 'POST',
      }
      sendDataNative(callApi)
      sendDataNative<ITrackEvent>({
        method: 'TRACKEVENT',
        body: {
          name: TrackEventEnum.TELA_SMS_ENVIAR,
          parameters: {
            celular: dadosPessoais.celular,
          },
        },
      })
      setTimeout(() => {
        setLoading(false)
      }, TWO_SECONDS)
    }
  }, [PIN])

  useCallbackNative('callbackApiSMS', 'onCallbackApiSMS', {
    onCallbackApiSMS: (data: GenericRes) => {
      if (data instanceof ApiResponse) {
        const step = getRouter(data.telas.stepList[0].step)

        sendToNewRelic('PIN_SMS', '[ApiResponse] - callbackApiSMS', {
          PROXIMA_ETAPA: step,
          URL: data.telas.stepList[0].url,
          celular: dadosPessoais.celular,
        })
        if (step) {
          history.push(`${step}${url.buildQuery(getUrlParams(data.telas.stepList[0].url))}`)
        } else {
          const callApi: TSendDataNative = {
            body: data,
            callback: 'callbackMediaUpdate',
            endpoint: '',
            method: 'CALLMEDIAUPDATE',
          }
          sendDataNative(callApi)
        }
      } else if (data instanceof ApiError) {
        setError(data)
        sendToNewRelic('PIN_SMS', '[ApiError] - callbackApiSMS', {
          celular: dadosPessoais.celular,
          ...data,
        })
      }
      setLoading(false)
    },
  })

  useCallbackNative('callbackRestartAberturaSms', 'onCallbackRestartAberturaSms', {
    onCallbackRestartAberturaSms: (data: GenericRes) => {
      setLoading(false)
      if (data instanceof ApiResponse) {
        const step = getRouter(data.telas.stepList[0].step)
        sendToNewRelic('PIN_SMS_REINICIAR_ABERTURA', '[ApiResponse] - callbackRestartAberturaSms', {
          PROXIMA_ETAPA: step,
          URL: data.telas.stepList[0].url,
        })
        if (step) {
          const dados = data.dados as TDados
          history.push(
            `${step}${url.buildQuery({
              nome: data.nome,
              tipoCartao: dados?.tipoCartao || CartoesEnum.GOLDPADRAO,
              escolherCartaoVirtual: dados?.escolherCartaoVirtual || false,
              ...getUrlParams(data.telas.stepList[0].url),
            })}`,
          )
        } else {
          sendDataNative<TSendDataNative>({
            callback: 'restartOnboarding',
            endpoint: '',
            method: 'RESTARTONBOARDING',
          })
        }
      } else if (data instanceof ResponseEmpty) {
        sendDataNative<TSendDataNative>({
          callback: 'restartOnboarding',
          endpoint: '',
          method: 'RESTARTONBOARDING',
        })
      }
      if (!(data as ApiError)?.httpCodigo || !(data as ApiError)?.campo) {
        sendDataNative<TSendDataNative>({
          callback: 'restartOnboarding',
          endpoint: '',
          method: 'RESTARTONBOARDING',
        })
      } else {
        sendToNewRelic('PIN_SMS_REINICIAR_ABERTURA', '[ApiError] - callbackRestartAberturaSms', {
          ...data,
        })
      }
      setLoadingRestart(false)
    },
  })

  const reSend = (destinoReenvio: string) => {
    setLoading(true)
    destinoReenvio === 'WHATSAPP' ? setLoadingResendWpp(true) : setLoadingResendSms(true)
    clearError()
    const callApi: TSendDataNative = {
      callback: 'callbackApiEmpty',
      endpoint: SMS_REENVIO,
      method: 'POSTWRESPONSE',
      body: {
        destino: destinoReenvio,
      },
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_SMS_REENVIAR,
        parameters: {
          celular: dadosPessoais.celular,
        },
      },
    })
    setTimeout(() => {
      setLoading(false)
      destinoReenvio === 'WHATSAPP' ? setLoadingResendWpp(false) : setLoadingResendSms(false)
      setOpenModalReenviar(false)
    }, TWO_SECONDS)
    sendToNewRelic('PIN_SMS', 'Envio de Token')
  }

  const sendBackToPrevStep = () => {
    setLoadingRestart(true)
    const callApi: TSendDataNative = {
      callback: 'callbackRestartAberturaSms',
      endpoint: EMAIL_REINICIAR_FICHA,
      method: 'POSTWRESPONSE',
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_REVALIDAR_EMAIL_POPUP_CONTINUAR,
        parameters: {},
      },
    })
    sendToNewRelic('PIN_SMS', 'Reiniciar com outro celular')
  }

  const closePopUp = () => {
    setOpenModal(false)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_REVALIDAR_EMAIL_POPUP_VOLTAR,
        parameters: {},
      },
    })
  }

  const onNoAccessMobile = () => {
    setOpenModal(true)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_REVALIDAR_EMAIL_SEM_ACESSO,
        parameters: {},
      },
    })
  }

  useEffect(() => {
    const getDadosPessoaisStorage = localStorage.getItem('dadosPessoais')

    if (getDadosPessoaisStorage) {
      setDadosPessoaisStorage(JSON.parse(getDadosPessoaisStorage))
    }

    reSend(url.queryParam().destino ?? TypeTokensEnum.SMS)
  }, [])

  const queries = url.queryParam()

  useEffect(() => {
    const token = url.queryParam().destino as TypeTokensEnum

    if (token) {
      setTokenType(token ?? TypeTokensEnum.SMS)
    }
  }, [queries])

  const resendSMS = () => {
    setCounter(30)
    reSend(TypeTokensEnum.SMS)
  }

  return (
    <PageContainer>
      <PageText
        text={`Código enviado para o número ${
          dadosPessoaisStorage.celular && obscurePhoneNumber(dadosPessoaisStorage.celular)
        }`}
        margin="80px 0px 24px 0px"
      />
      {tokenType === TypeTokensEnum.WHATSAPP ? (
        <Text variant="body-3">
          Digite o código enviado via <strong color="var(--gray500)">WhatsApp</strong>{' '}
          <Icons variant={Whatsapp} color="var(--success500)" width={14} height={14} />.
          <div>
            Caso não receba, o código pode ser reenviado por <b>SMS</b>.
          </div>
        </Text>
      ) : (
        <Text variant="body-3" className="mb-4">
          Digite o código enviado via <b>SMS</b>. Caso não receba, o código poderá ser reenviado
          novamente
        </Text>
      )}

      <Spacing mt="lg" mb="lg">
        <BoxCenter>
          <InputAuthentication callbackValue={setPIN} error={error} />
        </BoxCenter>
      </Spacing>

      <BoxCenter>
        <Button
          variant="secondary"
          disabled={counter > 0}
          isLoading={loading}
          onClick={() => {
            tokenType === TypeTokensEnum.WHATSAPP ? setOpenModalReenviar(true) : resendSMS()
          }}
        >
          Reenviar código {counter > 0 ? `${counter}s` : null}
        </Button>
      </BoxCenter>
      <ButtonWrapper>
        <Button id="submit" variant="link" onClick={onNoAccessMobile}>
          Não tenho acesso a esse número
        </Button>
      </ButtonWrapper>

      {openModal && (
        <BottomSheet onClose={closePopUp}>
          <h3>Não tenho acesso a esse número</h3>
          <p className="mb-4">
            Você pode reiniciar o processo de abertura da conta com outro número. Quer mesmo
            reiniciar?
          </p>

          <ButtonWrapper>
            <Button isLoading={loadingRestart} onClick={sendBackToPrevStep}>
              Reiniciar
            </Button>
            <Button variant="secondary" onClick={closePopUp}>
              Voltar
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}

      {openModalReenviar && (
        <BottomSheet onClose={() => setOpenModalReenviar(false)}>
          <ButtonWrapper>
            <Button
              variant="secondary"
              onClick={() => {
                reSend('SMS')
                setCounter(30)
              }}
            >
              {loadingResendSms ? (
                <Loading />
              ) : (
                <>
                  Reenviar código por SMS{' '}
                  <div style={{ marginLeft: '8px' }}>
                    <Icons variant={Message} />
                  </div>
                </>
              )}
            </Button>

            <Button
              variant="secondary"
              isLoading={loadingRestart}
              onClick={() => {
                reSend('WHATSAPP')
                setCounter(30)
              }}
            >
              {loadingResendWpp ? (
                <Loading />
              ) : (
                <>
                  Enviar código por Whatsapp{' '}
                  <div style={{ marginLeft: '8px' }}>
                    <Icons variant={Whatsapp} color="var(--success500)" />
                  </div>
                </>
              )}
            </Button>

            <Button variant="primary" onClick={() => setOpenModalReenviar(false)}>
              Cancelar
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}
    </PageContainer>
  )
}

export default PinSMS
