export const justDigits = (value: string): string => value.replace(/[^\d]/g, '')

export const formatDate = (value: string): string =>
  `${value.substr(6, 4)}-${value.substr(3, 2)}-${value.substr(0, 2)}`

export const obscurePhoneNumber = (phone: string | undefined): string => {
  if (!phone) return new Array(7).fill('*').join('')
  const finalDigits = phone.substring(phone.length - 4)
  return new Array(5).fill('*').join('').concat(finalDigits)
}

export const ellipsis = (text: string, size: number): string => {
  if (text.length > size) {
    return `${text.substring(0, size)}...`
  }
  return text
}

export const formatCurrency = (value: string): number => +value.replace(/[^\d]/g, '') / 100

/**
 * @example
 * Elliot MrRobot         = Elliot MrRobot
 * Elliot Mr Robot        = Elliot M Robot
 * Elliot Mr Robot Robot  = Elliot M R Robot
 * @param fullName
 */
export const formatFullName = (fullName: string) => {
  if (!fullName) {
    return ''
  }
  let splited = fullName.trim().split(' ')
  if (splited.length < 2) {
    return fullName
  }
  const lastPos = splited.length - 1
  const firstName = splited[0]
  const lastName = splited[lastPos]
  const betweenNames = splited.slice(1, lastPos)
  splited = betweenNames.map((d: string) => d.charAt(0).toUpperCase())
  return [firstName, ...splited, lastName].join(' ')
}

/**
 * @example
 * stringFormat("/users/{0}/rules/{1}", "123", "admin")
 */
// const stringFormat = (s: string, ...args: string[]): string => {
//   for (const arg in args) {
//     s = s.replace(`{${arg}}`, args[arg])
//   }
//   return s
// }

export const isNullEmpty = (value: string) => {
  try {
    return value == null || value === undefined || value.trim() === ''
  } catch (error) {
    return true
  }
}

export const getAge = (d1: Date) => {
  const d2 = new Date()
  const diff = d2.getTime() - d1.getTime()
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25))
}

export const decodeBase64 = (str: string): string => Buffer.from(str, 'base64').toString('utf-8')

export const encodeToBase64 = (obj: unknown) => Buffer.from(JSON.stringify(obj)).toString('base64')

export const normalizeCaracters = (value = '') =>
  value
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s]/gi, '')

export const MaskTIN = (value: string): string => {
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{3})(\d)/, '$1-$2')
  value = value.replace(/(\d{2})(\d{3})/, '$1-$2')
  value = value.replace(/(\d{5})(\d)/, '$1-$2')
  return value
}

export const maskZipCode = (value: string): string => {
  if (value && value.length === 8) {
    value = `${value.substr(0, 2)}.${value.substr(2, 3)}-${value.substr(5, 3)}`
  }
  return value
}

export const toTitleCase = (str: string) => {
  if (!str) {
    return str
  }
  return str.replace(
    /[a-zà-ü]*/gi,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  )
}

export const toUpperCase = (str: string | undefined) => (str ? str.toUpperCase() : '')

export const isAllSameNumbers = (numberInput: string) => {
  if (!numberInput) return false

  const rgx = /^(.)\1*$/g
  return rgx.test(numberInput)
}
