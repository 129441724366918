import styled from 'styled-components'
import { Gray } from 'assets/styled-component/variables'

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  label {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: ${Gray[500]};
    margin-bottom: 0;
  }
`
