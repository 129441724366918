import React, { memo } from 'react'

import * as S from './style'
import { TagProps } from './types'

export const Component = ({
  size,
  theme,
  intensity,
  color,
  colorText,
  children,
  onClick,
  ...attrs
}: TagProps) => (
  <S.Container hasOnClick={Boolean(onClick)}>
    <S.Tag
      {...attrs}
      size={size}
      theme={theme}
      intensity={intensity}
      color={color}
      colorText={colorText}
      onClick={onClick}
    >
      <S.TagText
        variant={size === 'small' ? 'caption-2' : 'caption-1'}
        theme={theme}
        intensity={intensity}
        colorText={colorText}
        bold
      >
        {children}
      </S.TagText>
    </S.Tag>
  </S.Container>
)

Component.defaultProps = {
  size: 'small',
  theme: 'orange',
  intensity: 'dark',
}

export const Tag = memo(Component)
