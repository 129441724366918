import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { PageContainer, FormAddress, ButtonWrapper, Button, PageText } from 'components'
import { justDigits, normalizeCaracters } from 'shared/functions/utils'
import { IEnderecos, IEnderecoResidencial } from 'interfaces/enderecos.interface'
import { getRouter } from 'enums/routes.enum'
import { sendDataNative, TSendDataNative } from 'components/hooks/useFetchNative'
import { ENDERECOS } from 'components/urls'
import { ITrackEvent, TrackEventEnum } from 'interfaces/trackEvent.interface'
import { sendToNewRelic } from 'services'
import { ApiError, ApiResponse, GenericRes } from 'interfaces/response.interface'
import { useCallbackNative } from 'components/hooks/useCallbackNative'
import url from 'shared/functions/url'
import { getUrlParams } from 'shared/helpers/getUrlPrams'

import { BottomSheet } from '@inter/inter-ui'

type TEndereco = {
  endereco: IEnderecos
  setEndereco: React.Dispatch<React.SetStateAction<IEnderecos>>
}

type TForm = {
  cep: string
  tipoLogradouro: string
  logradouro: string
  changeDeliveryAddress: string
  numero: string
  complemento: string
  bairro: string
  uf: string
  cidade: string
  isRestrito: string
  bandeira: string
}

const EnderecoEntrega: React.FC<TEndereco> = ({ endereco, setEndereco }: TEndereco) => {
  const history = useHistory()
  const [attentionModal, setAttentionModal] = useState<boolean>(false)
  const [loadingOnSubmit, setLoadingOnSubmit] = useState<boolean>(false)

  const handleCorrespondenceAddress = () => {
    setLoadingOnSubmit(true)
    const callApi: TSendDataNative = {
      body: endereco,
      callback: 'callbackApiEndereco',
      endpoint: ENDERECOS,
      method: 'PUT',
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_CONFIRMARCAO_ENTREGA_CONTINUAR,
        parameters: {},
      },
    })
  }

  useCallbackNative('callbackApiEndereco', 'onCallbackApiEndereco', {
    onCallbackApiEndereco: (data: GenericRes) => {
      if (data instanceof ApiResponse) {
        const step = getRouter(data.telas.stepList[0].step)

        sendToNewRelic('CONFIRMACAO_ENDERECO', '[ApiResponse] - callbackApiEndereco', {
          PROXIMA_ETAPA: step,
          URL: data.telas.stepList[0].url,
        })

        if (step) {
          history.push(`${step}${url.buildQuery(getUrlParams(data.telas.stepList[0].url))}`)
        } else {
          const callApi: TSendDataNative = {
            body: data,
            callback: 'callbackMediaUpdate',
            endpoint: '',
            method: 'CALLMEDIAUPDATE',
          }
          sendDataNative(callApi)
        }
      } else if (data instanceof ApiError) {
        sendToNewRelic('CONFIRMACAO_ENDERECO', '[ApiError] - callbackApiEndereco', {
          ...data,
        })
      }
      setLoadingOnSubmit(false)
    },
  })

  const handleSendData = (data: Record<string, unknown>) => {
    const contentData = data as TForm
    const newAddress: IEnderecoResidencial = {
      cep: justDigits(contentData.cep),
      tipoLogradouro: contentData.tipoLogradouro,
      logradouro: normalizeCaracters(contentData.logradouro),
      numero: +contentData.numero,
      complemento: normalizeCaracters(contentData.complemento),
      cidade: normalizeCaracters(contentData.cidade) || '',
      uf: contentData.uf,
      bairro: normalizeCaracters(contentData.bairro),
      pais: 'BRASIL',
      isRestrito: contentData.isRestrito === 'true',
      bandeira: contentData.bandeira,
    }
    endereco.enderecoCartao = newAddress
    endereco.enderecoCartaoIgualEnderecoResidencial = false
    setEndereco(endereco)
    handleCorrespondenceAddress()
  }

  const handleCloseAttentionModal = () => {
    setAttentionModal(false)
  }

  return (
    <PageContainer>
      <PageText text="Em qual endereço seu cartão deve ser enviado?" />

      <FormAddress
        deliveryAddress
        handleSendData={handleSendData}
        setAttentionModal={setAttentionModal}
        idScreen="enderecoEntrega"
        submitLoading={loadingOnSubmit}
      />

      {attentionModal && (
        <BottomSheet onClose={handleCloseAttentionModal}>
          <h3>Entrega indisponível para esse CEP</h3>
          <p className="mb-4">
            Sua região está fora da área de cobertura de entrega. Mas não se preocupe, seu cartão
            digital estará disponível na área de cartões do Super App.
          </p>

          <ButtonWrapper>
            <Button data-anim="ripple" onClick={handleCloseAttentionModal}>
              Entendi
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}
    </PageContainer>
  )
}

export default EnderecoEntrega
