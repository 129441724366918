import { Gray, Orange, Status, White } from 'assets/styled-component/variables'
import styled from 'styled-components'

export const InputAuthenticationWrapper = styled.div`
  position: relative;
  width: 260px;
  margin-top: 24px;

  input,
  input::placeholder {
    border: none;
    width: 204px;
    font: 32px Droid Sans, monospace !important;
    font-weight: 700;
    font-style: normal;

    letter-spacing: 36px;
    position: initial;
    z-index: 2;
    height: 48px !important;
    background-color: ${White[500]};
    border: none;
    cursor: none;
    caret-color: ${Orange[500]};

    &::placeholder {
      color: ${Gray[200]};
      padding-left: 0px !important;
    }
  }

  &.erroValidation {
    input {
      color: ${Status.Error[500]};
    }

    input::placeholder {
      color: ${Status.Error[100]};
    }
  }

  .pin {
    box-sizing: border-box;
    position: absolute;
    left: 0px;
    top: 2px;
    height: 50px;
    width: 40px;

    &.customInternetBanking {
      border: none;
      border-radius: 0px;
      position: absolute;
      border-bottom: 2px solid ${Gray[200]};

      &.erroValidation {
        border-bottom: 2px solid ${Status.Error[100]};
      }

      &.pin {
        left: 5px;
        &2 {
          left: 59px;
        }
        &3 {
          left: 112px;
        }
        &4 {
          left: 166px;
        }
      }
    }
  }

  .error {
    display: flex;
    margin: 8px 0px !important;
    align-items: flex-start;
    justify-content: center;

    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    color: ${Status.Error[500]};
  }
`
