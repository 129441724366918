import * as yup from 'yup'
import {
  dontUseSpecialCharacters,
  INVALID_MESSAGE,
  LENGTH_MESSAGE,
  REQUIRED_MESSAGE,
} from 'validations/messages'
import { isAllSameNumbers, justDigits } from 'shared/functions/utils'

export const yupValidacaoDadosProfissionais = (
  hasCompany: boolean,
  isPep: boolean,
  isUSPerson: boolean,
) =>
  yup.object().shape({
    escolaridade: yup.string().required(REQUIRED_MESSAGE('Escolaridade')),
    descricaoProfissao: yup.string().required(REQUIRED_MESSAGE('Profissão')),
    nomeEmpresa: hasCompany
      ? yup
          .string()
          .trim()
          .required(REQUIRED_MESSAGE('Empresa'))
          .matches(dontUseSpecialCharacters.rule, dontUseSpecialCharacters.message)
      : yup.string().nullable(),
    rendaMensal: yup.string().required(REQUIRED_MESSAGE('Renda mensal')),
    faixaPatrimonial: yup.string().required(REQUIRED_MESSAGE('Valor do patrimônio')),
    tipoPessoaExposta: isPep
      ? yup
          .string()
          .trim()
          .required(REQUIRED_MESSAGE('PEP'))
          .matches(dontUseSpecialCharacters.rule, dontUseSpecialCharacters.message)
      : yup.string().nullable(),
    tin: isUSPerson
      ? yup
          .string()
          .required(REQUIRED_MESSAGE('TIN'))
          .length(11, LENGTH_MESSAGE('TIN', 9))
          .test('isAllEqualsOrSequence', INVALID_MESSAGE('TIN'), (value: string | undefined) => {
            const digits = justDigits(value || '')
            return !isAllSameNumbers(digits) && !digits.includes('123456789')
          })
      : yup.string().nullable(),
  })
