export enum RoutesEnum {
  /** HOME - ONBOARDING */
  STEP_DADOS_PESSOAIS = '/',
  DADOS_PESSOAIS_ESTADOCIVIL = '/dadosPessoais-estadoCivil',
  DADOS_PESSOAIS_CONTATO = '/dadosPessoais-contato',

  STEP_VALIDACAO_SMS = '/validar-sms',
  STEP_VALIDACAO_EMAIL = '/validar-email',
  STEP_REVALIDAR_EMAIL = '/revalidar-email',

  STEP_NATURALIDADE = '/naturalidade',
  STEP_ESCOLHA_CARTAO = '/escolha-cartao',

  STEP_ENDERECO_RESIDENCIAL = '/endereco-residencial',
  ENDERECO_ENTREGA = '/endereco-entrega',

  STEP_DADOS_PROFISSIONAIS = '/dados-profissionais',
  STEP_SENHA = '/criar-senha',

  STEP_EM_ANALISE = '/finalizar-pf',

  PENDENCIAMENTO = '/pendenciamento', // Não será necessário, pois esta tela já está em refazer itens.
  STEP_CONTA_ABERTA = '/pendenciamento-aprovado',
  STEP_CONTA_NEGADA = '/pendenciamento-reprovado',
  STEP_CONTA_BASICA = '/conta-basica-aberta',

  AVISO_REFAZER_ITENS = '/pendencias-refazer',
  STEP_REINICIAR_FICHA = '/reiniciar-ficha',

  CODIGO_INDICACAO = '/indicacao',

  CHAVE_PIX_INFO = '/chave-pix-info',
  CHAVE_PIX = '/chave-pix',
  INDICAR_AMIGOS = '/indicar-amigos',

  HOME_POS_ABERTURA = '/pos-abertura',
  CONTA_MEI = '/conta-mei',
  CONTA_MEI_INFOS = '/informacoes-empresa',
  CONTA_GLOBAL = '/conta-global',
  INTER_LOOP = '/inter-loop',

  FIM_ONBOARDING = '/fim-onboarding',
}

export type TRoute = keyof typeof RoutesEnum

export const getRouter = (step: TRoute): RoutesEnum => RoutesEnum[step]
