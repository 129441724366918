import axios, { AxiosError } from 'axios'
import { ServiceResponseError } from 'exceptions'

import { TParamsRequest, TResponseType } from './types'

export * from './types'

export class BaseServices {
  static request = async <T>({
    headers = { 'Content-Type': 'application/json' },
    pathname,
    method,
    host,
    data,
    params,
  }: TParamsRequest): Promise<TResponseType<T>> =>
    BaseServices.callByAxios<T>({ headers, pathname, method, data, params, host })

  static callByAxios = async <T>({
    headers,
    pathname,
    method,
    data,
    params,
    host,
  }: TParamsRequest) => {
    let response = {} as TResponseType<T>

    try {
      response = await axios({
        headers: { ...headers },
        url: `${host}${pathname}`,
        method,
        params,
        data,
      })
    } catch (error) {
      const axiosError = error as AxiosError

      throw new ServiceResponseError({
        status: axiosError.response?.status,
        data: axiosError.response?.data,
        message: axiosError.message,
      })
    }
    return response
  }
}
