import styled from 'styled-components'
import { Orange, Gray, INPUT_HEIGHT } from 'assets/styled-component/variables'

export const SelectList = styled.ul`
  max-height: 400px;
  overflow: auto;

  li:first-child {
    border: none;
  }
`

export const Header = styled.div`
  margin-top: 8px;

  svg {
    path {
      fill: ${Orange[500]};
    }
    path:first-child {
      fill: ${Orange[500]};
    }
  }

  div.line-header {
    margin: auto;
    height: 4px;
    width: 48px;
    background: ${Gray[400]};
    border-radius: 8px;
  }

  div.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;

    span {
      font-family: Sora;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: ${Gray[500]};
      padding: 14.5px 0;
    }

    button {
      margin-right: -16px;
    }
  }

  .filterOptions {
    width: 100%;

    > div:first-child {
      height: ${INPUT_HEIGHT};
    }
  }
`
