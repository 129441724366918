export class ApiError {
  public httpCodigo!: number

  public campo!: string

  public mensagem!: string

  public codigo!: string
}

export const convertErrorsFrom = (error: ApiError): Record<string, string> => {
  const err: Record<string, string> = {}
  err[error.campo] = error.mensagem || ''
  return err
}
