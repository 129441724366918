/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, FormEvent } from 'react'

import { useHistory } from 'react-router-dom'
import { MaskCURRENCY } from 'inter-frontend-lib-util-form/lib/masks/currency'
import { useForm, SubmitHandler } from 'react-hook-form'
import AtencaoPreencherInformacoes from 'pages/atencaoPreencherInformacoes'
import { getRouter, RoutesEnum } from 'enums/routes.enum'
import {
  PageContainer,
  ButtonWrapper,
  Button,
  ButtonLink,
  FlexForm,
  Select,
  Input,
  FullPage,
  Radio,
  BoxRow,
  PageText,
  Separator,
  Text,
  Spacing,
} from 'components'
import { useCallbackNative } from 'components/hooks/useCallbackNative'
import { STEP_DADOS_PROFISSIONAIS } from 'components/urls/index'
import { TSendDataNative, sendDataNative } from 'components/hooks/useFetchNative'
import faixasPatrimonios from 'shared/json/faixasPatrimoniais.json'
import profissoes from 'shared/json/profissoes.json'
import escolaridades from 'shared/json/grausEscolaridades.json'
import { formatCurrency, justDigits } from 'shared/functions/utils'
import { ITrackEvent, TrackEventEnum } from 'interfaces/trackEvent.interface'
import { yupValidacaoDadosProfissionais } from 'validations'
import { ApiResponseCtx } from 'App'
import { IDadosProfissionais } from 'interfaces/dadosProfissionais.interface'
import { GenericRes, ApiResponse, IApiResponseCtx, ApiError } from 'interfaces/response.interface'
import { sendToNewRelic } from 'services'
import { checkIsUnderForteen } from 'utils/profissoes/checkIsUnderForteen'
import { Help, Icons } from 'components/elements/Icons'
import url from 'shared/functions/url'

import { BottomSheet } from '@inter/inter-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { formatBRLToNumber } from '@interco/lib-util'

type TModal = {
  [key: string]: boolean
}

type TModalName = 'patrimonio' | 'PEP' | 'rendaMensal' | 'tin' | 'USPerson' | 'Erro'

const initialModal: TModal = {
  rendaMensal: false,
  patrimonio: false,
  tin: false,
  USPerson: false,
  PEP: false,
  erro: false,
}

type TFormInput = {
  escolaridade?: string
  descricaoProfissao?: string
  nomeEmpresa?: string
  rendaMensal?: string
  faixaPatrimonial?: string
  pep?: boolean
  tipoPessoaExposta?: string
  usPerson?: boolean
  tin?: string
}

type TJson = {
  key: string | number
  value: string
}

const DadosProfissionais = () => {
  const [openModal, setOpenModal] = useState<TModal>(initialModal)
  const [openModalAttetion, setOpenModalAttetion] = useState<boolean>(true)

  const [loading, setLoading] = useState<boolean>(false)
  const [hasCompany, setHasCompany] = useState<boolean>(false)
  const [isPep, setIsPep] = useState<boolean>(false)
  const [isUSPerson, setIsUSPerson] = useState<boolean>(false)

  const [validForm, setValidForm] = useState<boolean>(false)

  const history = useHistory()
  const { setApiResponse }: IApiResponseCtx = useContext<IApiResponseCtx>(ApiResponseCtx)
  const [isMinor, setIsMinor] = useState<boolean>(url.queryParam().isMinor === 'true')
  const [isUnderFourteen, setIsUnderFourteen] = useState<boolean>(
    url.queryParam().isUnderFourteen === 'true',
  )

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    setError,
    formState: { errors },
    getValues,
  } = useForm<TFormInput>({
    resolver: yupResolver(yupValidacaoDadosProfissionais(hasCompany, isPep, isUSPerson)),
  })

  const onSubmit: SubmitHandler<TFormInput> = (form: TFormInput) => {
    const selectedProfissao = profissoes.find((v: TJson) => v.value === form.descricaoProfissao)
    const payload: IDadosProfissionais = {
      escolaridade: escolaridades.find((v: TJson) => v.value === form.escolaridade)?.key || '',
      descricaoProfissao: selectedProfissao?.description || '',
      codigoProfissao: selectedProfissao?.key || '',
      nomeEmpresa: (hasCompany && form.nomeEmpresa) || undefined,
      rendaMensal: formatCurrency(form.rendaMensal || ''),
      faixaPatrimonial: form.faixaPatrimonial || '',
      codigoFaixaPatrimonial:
        faixasPatrimonios.find((v: TJson) => v.value === form.faixaPatrimonial)?.key || 1,
      pep: isPep,
      tipoPessoaExposta: (isPep && form.tipoPessoaExposta) || undefined,
      usPerson: isUSPerson,
      tin: isUSPerson && form.tin ? justDigits(form.tin) : undefined,
    }
    setLoading(true)
    const callApi: TSendDataNative = {
      body: payload,
      callback: 'callbackApiDadosProfissionais',
      endpoint: STEP_DADOS_PROFISSIONAIS,
      method: 'PUT',
      headers: {},
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_DADOS_PROFISSIONAIS_CONTINUAR,
        parameters: {
          escolaridade: payload.escolaridade,
          profisao: payload.descricaoProfissao,
          nome_da_empresa: payload.nomeEmpresa || undefined,
          renda_mensal: MaskCURRENCY(payload?.rendaMensal?.toString() || ''),
          valor_patrimonio: payload.faixaPatrimonial,
          pep: payload.pep ? 'Sim' : 'Não',
          usPerson: payload.usPerson ? 'Sim' : 'Não',
        },
      },
    })
  }

  useCallbackNative('callbackApiDadosProfissionais', 'onCallbackApiDadosProfissionais', {
    onCallbackApiDadosProfissionais: (data: GenericRes) => {
      setLoading(false)
      if (data instanceof ApiResponse) {
        const step = getRouter(data.telas.stepList[0].step)
        sendToNewRelic('DADOS_PROFISSIONAIS', '[ApiResponse] - callbackApiDadosProfissionais', {
          PROXIMA_ETAPA: step,
          URL: data.telas.stepList[0].url,
        })
        if (step) {
          if (step === RoutesEnum.AVISO_REFAZER_ITENS) {
            setApiResponse({
              ...data,
              telas: {
                ...data.telas,
                stepList: data.telas?.stepList?.splice(1),
              },
            })
          }
          history.push(step)
        } else {
          const callApi: TSendDataNative = {
            body: data,
            callback: 'callbackMediaUpdate',
            endpoint: '',
            method: 'CALLMEDIAUPDATE',
          }
          sendDataNative(callApi)
        }
      } else if (data instanceof ApiError) {
        if (
          data.campo &&
          (data.campo === 'escolaridade' ||
            data.campo === 'descricaoProfissao' ||
            data.campo === 'nomeEmpresa' ||
            data.campo === 'rendaMensal' ||
            data.campo === 'faixaPatrimonial' ||
            data.campo === 'tipoPessoaExposta' ||
            data.campo === 'tin')
        ) {
          setError(data.campo, {
            message: data.mensagem || 'Campo obrigatório.',
          })
        } else {
          sendToNewRelic('DADOS_PROFISSIONAIS', '[ApiError] - callbackApiDadosProfissionais', {
            ...data,
          })
          handleModalChange('Erro', 'open')
        }
      }
    },
  })

  useEffect(() => {
    if (
      !getValues('escolaridade') ||
      !getValues('descricaoProfissao') ||
      (hasCompany && !getValues('nomeEmpresa')) ||
      !getValues('rendaMensal') ||
      formatBRLToNumber(String(getValues('rendaMensal'))) <= 0 ||
      !getValues('faixaPatrimonial') ||
      (isPep && !getValues('tipoPessoaExposta')) ||
      (isUSPerson && !getValues('tin'))
    ) {
      setValidForm(true)
    } else {
      setValidForm(false)
    }
  }, [watch()])

  const handleModalChange = (name: TModalName, value: string) => {
    setOpenModal({
      ...openModal,
      [name]: value === 'open',
    })
  }
  const closeModal = () => setOpenModal(initialModal)

  const handleChangeEscolaridade = (value: string) => {
    clearErrors('escolaridade')
    setValue('escolaridade', value)
  }
  const handleChangeProfissoes = (value: string) => {
    setHasCompany(!['Do Lar', 'Estudante', 'Aposentado'].includes(value))
    clearErrors('descricaoProfissao')
    setValue('descricaoProfissao', value)
  }
  const handleChangeNomeEmpresa = (e: FormEvent<HTMLInputElement>) => {
    clearErrors('nomeEmpresa')
    const { value } = e.currentTarget
    setValue('nomeEmpresa', value)
  }
  const handleChangeRendaMensal = (value: string) => {
    clearErrors('rendaMensal')
    setValue('rendaMensal', MaskCURRENCY(value))
  }
  const handleChangePatrimonio = (value: string) => {
    clearErrors('faixaPatrimonial')
    setValue('faixaPatrimonial', value)
  }
  const handleChangeTin = (e: FormEvent<HTMLInputElement>) => {
    clearErrors('tin')
    const { value } = e.currentTarget
    setValue('tin', value)
  }
  const handleChangeUsPerson = () => {
    setIsUSPerson(!isUSPerson)
    if (!isUSPerson) {
      setValue('tin', '')
      clearErrors('tin')
    }
  }
  const handleChangeTipoPessoaExposta = (e: FormEvent<HTMLInputElement>) => {
    clearErrors('tipoPessoaExposta')
    const { value } = e.currentTarget
    setValue('tipoPessoaExposta', value)
  }
  const handleChangePep = () => {
    setIsPep(!isPep)
    if (!isPep) {
      setValue('tipoPessoaExposta', '')
      clearErrors('tipoPessoaExposta')
    }
  }

  const queries = url.queryParam()

  useEffect(() => {
    setIsUnderFourteen(queries.isUnderFourteen === 'true')

    if (isMinor) {
      setIsMinor(!!isMinor)
    }
  }, [queries])

  return (
    <>
      <PageContainer>
        <PageText text="Dados Profissionais" margin="80px 0px 24px 0px" />

        <FlexForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Select
            id="escolaridade"
            label="Escolaridade"
            options={escolaridades}
            placeholder="Selecione sua escolaridade"
            value={watch('escolaridade')}
            setSelectValue={handleChangeEscolaridade}
            error={Boolean(errors.escolaridade)}
            infoText={errors.escolaridade?.message}
            {...register('escolaridade')}
          />
          <Select
            id="profissao"
            label="Profissão"
            options={checkIsUnderForteen(isUnderFourteen, isMinor)}
            placeholder="Selecione sua profissão"
            value={watch('descricaoProfissao')}
            setSelectValue={handleChangeProfissoes}
            error={Boolean(errors.descricaoProfissao)}
            infoText={errors.descricaoProfissao?.message}
            isUsingFilter
            placeholderFilter="Busque a sua profissão"
            {...register('descricaoProfissao')}
          />
          {hasCompany && (
            <Input
              id="nomeEmpresa"
              label="Empresa em que trabalha"
              placeholder="Informe a empresa"
              maxLength={40}
              defaultValue={watch('nomeEmpresa')}
              error={Boolean(errors.nomeEmpresa)}
              infoText={errors.nomeEmpresa?.message}
              {...register('nomeEmpresa')}
              onChange={handleChangeNomeEmpresa}
            />
          )}
          <Input
            id="rendaMensal"
            type="tel"
            label="Renda mensal"
            placeholder="R$ 0.000,00"
            maxLength={13}
            defaultValue={watch('rendaMensal')}
            error={
              Boolean(errors.rendaMensal) ||
              (watch('rendaMensal') && formatBRLToNumber(String(getValues('rendaMensal'))) <= 0)
            }
            infoText={errors.rendaMensal?.message}
            helper={
              <Icons variant={Help} onClick={() => handleModalChange('rendaMensal', 'open')} />
            }
            {...register('rendaMensal')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeRendaMensal(event.target.value)
            }
          />

          <Select
            id="faixaPatrimonial"
            label="Valor do patrimônio"
            options={faixasPatrimonios}
            placeholder="Selecione seu patrimônio"
            value={watch('faixaPatrimonial')}
            setSelectValue={handleChangePatrimonio}
            error={Boolean(errors.faixaPatrimonial)}
            infoText={errors.faixaPatrimonial?.message}
            helper={
              <Icons variant={Help} onClick={() => handleModalChange('patrimonio', 'open')} />
            }
            {...register('faixaPatrimonial')}
          />

          <Separator />

          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text variant="body-3">
                Você é uma Pessoa Politicamente Exposta (PEP) ou possui alguma relação com pessoa
                que se encaixe nessa classificação?
              </Text>
              <Spacing ml="xxxs" style={{ display: 'flex' }}>
                <Icons variant={Help} onClick={() => handleModalChange('PEP', 'open')} />
              </Spacing>
            </div>

            <BoxRow style={{ justifyContent: 'left', marginTop: '16px' }}>
              <Radio
                id="radio-false"
                name="changePep"
                label="Não"
                value={!isPep}
                checked={!isPep}
                onChange={handleChangePep}
                style={{ marginRight: '68px' }}
              />
              <Radio
                id="radio-true"
                name="changePep"
                label="Sim"
                value={isPep}
                checked={isPep}
                onChange={handleChangePep}
              />
            </BoxRow>

            {isPep && (
              <Input
                id="tipoPessoaExposta"
                type="text"
                label="Qual o cargo ocupado?"
                placeholder="Ex: vereador, juiz, diplomata"
                maxLength={100}
                defaultValue={watch('tipoPessoaExposta')}
                error={Boolean(errors.tipoPessoaExposta)}
                infoText={errors.tipoPessoaExposta?.message}
                {...register('tipoPessoaExposta')}
                className={`mt-3 ${!errors.tipoPessoaExposta && 'pb-2'}`}
                onChange={handleChangeTipoPessoaExposta}
                style={{ display: 'flex' }}
              />
            )}
          </div>

          <Separator />

          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text variant="body-3">Você declara e paga imposto de renda nos Estados Unidos?</Text>

              <Spacing ml="xxxs" style={{ display: 'flex' }}>
                <Icons variant={Help} onClick={() => handleModalChange('USPerson', 'open')} />
              </Spacing>
            </div>

            <BoxRow style={{ justifyContent: 'left', marginTop: '16px' }}>
              <Radio
                id="radio-false"
                name="changeUsPerson"
                label="Não"
                value={!isUSPerson}
                checked={!isUSPerson}
                onChange={handleChangeUsPerson}
                style={{ marginRight: '68px' }}
              />
              <Radio
                id="radio-true"
                name="changeUsPerson"
                label="Sim"
                value={isUSPerson}
                checked={isUSPerson}
                onChange={handleChangeUsPerson}
              />
            </BoxRow>
            {isUSPerson && (
              <Input
                id="tin"
                type="tel"
                label="TIN - Tax Identification Number"
                placeholder="Informe o número fiscal dos EUA"
                maxLength={11}
                defaultValue={watch('tin')}
                error={Boolean(errors.tin)}
                infoText={errors.tin?.message}
                mask="999-99-9999"
                {...register('tin')}
                helper={
                  <ButtonLink variant="link" onClick={() => handleModalChange('tin', 'open')}>
                    Saiba mais
                  </ButtonLink>
                }
                className={`mt-3 ${!errors.tin && 'pb-2'}`}
                onChange={handleChangeTin}
                style={{ display: 'flex' }}
              />
            )}
          </div>
          <ButtonWrapper style={{ paddingTop: '20px' }}>
            <Button type="submit" disabled={validForm} isLoading={loading}>
              Continuar
            </Button>
          </ButtonWrapper>
        </FlexForm>

        {openModal.rendaMensal && (
          <BottomSheet onClose={closeModal}>
            <h3>Renda mensal</h3>
            <p className="mb-4">
              É a soma dos valores que você recebe mensalmente no trabalho (salários, bônus,
              benefícios, comissões), de aluguéis, de benefícios da previdência pública ou privada,
              de pensões alimentícias e aplicações financeiras.
            </p>
            <ButtonWrapper>
              <Button data-anim="ripple" onClick={() => handleModalChange('rendaMensal', 'close')}>
                Entendi
              </Button>
            </ButtonWrapper>
          </BottomSheet>
        )}
        {openModal.patrimonio && (
          <BottomSheet onClose={closeModal}>
            <h3>Patrimônio</h3>
            <p className="mb-4">
              É a soma de valor dos seus bens que podem ser transformados em dinheiro. Exemplos:
              dinheiro, veículos, terrenos, apartamentos, participações societárias, aplicações
              financeiras e etc.
            </p>

            <ButtonWrapper>
              <Button data-anim="ripple" onClick={() => handleModalChange('patrimonio', 'close')}>
                Entendi
              </Button>
            </ButtonWrapper>
          </BottomSheet>
        )}
        {openModal.tin && (
          <BottomSheet onClose={closeModal}>
            <h3>TIN</h3>
            <p className="mb-4">
              É o número de Identificação Fiscal de 9 dígitos, gerado pela Receita Federal dos EUA.
              É o mesmo número usado para Previdência Social (SSN), Identificação do Empregado (EIN)
              e Identificação Individual do Contribuinte (ITIN).
            </p>

            <ButtonWrapper>
              <Button data-anim="ripple" onClick={() => handleModalChange('tin', 'close')}>
                Entendi
              </Button>
            </ButtonWrapper>
          </BottomSheet>
        )}
        {openModal.PEP && (
          <BottomSheet onClose={closeModal}>
            <h3>Pessoa politicamente exposta</h3>
            <p className="mb-4">
              É a pessoa que tenha desempenhado, nos últimos 5 (cinco) anos, no Brasil ou em outros
              países, cargos, empregos ou funções públicas relevantes, e também seus representantes,
              familiares e pessoas de seus relacionamentos (Circular 3.461, de 24/7/2009, do Banco
              Central do Brasil).
            </p>

            <ButtonWrapper>
              <Button data-anim="ripple" onClick={() => handleModalChange('PEP', 'close')}>
                Entendi
              </Button>
            </ButtonWrapper>
          </BottomSheet>
        )}
        {openModal.USPerson && (
          <BottomSheet onClose={closeModal}>
            <h3>US Person</h3>
            <p className="mb-4">
              Pessoa que se enquadra em pelo menos uma das condições abaixo:
              <br />
              1 - É residente fiscal nos EUA;
              <br />
              2 - Nasceu nos EUA;
              <br />
              3 - Possui nacionalidade ou cidadania americana;
              <br />
              4 - Possui visto de residência (Green Card);
              <br />5 - É residente permanente dos EUA: está nos EUA por mais de 183 dias,
              considerando os critérios de contagem de acordo com a legislação específica.
            </p>

            <ButtonWrapper>
              <Button data-anim="ripple" onClick={() => handleModalChange('USPerson', 'close')}>
                Entendi
              </Button>
            </ButtonWrapper>
          </BottomSheet>
        )}
        {openModal.Erro && (
          <BottomSheet onClose={closeModal}>
            <h3>Ops! Algo deu errado!</h3>
            <p className="mb-4">
              Já estamos cientes e trabalhando nisto. Tente novamente em instantes.
            </p>

            <ButtonWrapper>
              <Button
                data-anim="ripple"
                onClick={() => {
                  handleModalChange('Erro', 'close')
                  setLoading(false)
                }}
              >
                Entendi
              </Button>
            </ButtonWrapper>
          </BottomSheet>
        )}

        <FullPage activeCloseFullPage={openModalAttetion}>
          <AtencaoPreencherInformacoes onButtonClick={() => setOpenModalAttetion(false)} />
        </FullPage>
      </PageContainer>
    </>
  )
}

export default DadosProfissionais
