import React from 'react'

import { ArrowLeft, Info } from 'components/elements/Icons'
import { Icons } from 'components'

import * as S from './style'

type TPageTitle = {
  title?: string
  onClickPreview?: () => void
  onClickInfo?: () => void
}

export const PageTitle: React.FC<TPageTitle> = ({
  title,
  onClickPreview,
  onClickInfo,
}: TPageTitle) => (
  <S.TitleContainer>
    {onClickPreview ? <Icons variant={ArrowLeft} onClick={onClickPreview} /> : <S.BlankImg />}
    <h3>{title}</h3>
    {onClickInfo ? <Icons variant={Info} onClick={onClickInfo} /> : <S.BlankImg />}
  </S.TitleContainer>
)
