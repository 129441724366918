import styled from 'styled-components'

export const TitleContainer = styled.div`
  height: 56px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const BlankImg = styled.div`
  width: 24px;
`
