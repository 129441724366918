import React, { useEffect } from 'react'

import { PageContainer, ButtonWrapper, Button, BoxVerticalCenter, BoxCenter } from 'components'
import { sendDataNative } from 'components/hooks/useFetchNative'
import { TrackEventEnum, ITrackEvent } from 'interfaces/trackEvent.interface'
import sinalError from 'assets/images/sinalError.svg'
import { sendToNewRelic } from 'services'

import { Text } from '@inter/inter-ui'

const InativarFicha = () => {
  const onNext = () => {
    sendDataNative({
      callback: 'callbackFinishOnBoarding',
      method: 'FINISHONBOARDING',
    })
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_PENDENCIAMENTO_REPROVADO_CONTINUAR,
        parameters: {},
      },
    })
  }

  useEffect(() => {
    sendToNewRelic('REINICIAR_FICHA', 'Mensagem exibida para o usuário')
  }, [])

  return (
    <PageContainer>
      <BoxVerticalCenter>
        <BoxCenter>
          <img src={sinalError} alt="Pendenciamento reprovado" width={64} />
        </BoxCenter>
        <Text variant="headline-h1" as="h1" style={{ textAlign: 'center', marginTop: '24px' }}>
          Ops! Algo deu errado!
        </Text>

        <Text variant="body-3" as="p" style={{ textAlign: 'center' }}>
          Seu cadastro foi reiniciado, informe seus dados novamente.
        </Text>
      </BoxVerticalCenter>

      <ButtonWrapper>
        <Button id="submit" onClick={onNext}>
          Entendi
        </Button>
      </ButtonWrapper>
    </PageContainer>
  )
}

export default InativarFicha
