import { PixOutline, Receipt, TransferMoney, Gift } from 'components/elements/Icons'

export const mountImages = (icon: string) => {
  switch (icon) {
    case 'gift':
      return Gift
    case 'pix':
      return PixOutline
    case 'transfer-money':
      return TransferMoney
    default:
      return Receipt
  }
}

export const homePixInfoList = [
  {
    icon: 'transfer-money',
    title: 'Envie e receba em tempo real',
    value: 'Pagamentos por QR Code, chave Pix ou dados da conta, concluídos em segundos',
  },
  {
    icon: 'pix',
    title: 'Disponível a qualquer momento',
    value: '24 horas por dia, 7 dias da semana e em feriados. E o melhor: sem custo!',
  },
  {
    icon: 'gift',
    title: 'Seguro Pix',
    value: 'Sua conta segura em transações PIX feitas sob coação ou após roubo de celular.',
    tagText: '1 mês grátis',
    tagColor: 'var(--success)',
  },
]

export const pixInsuranceInfoList = [
  {
    text: 'Transações sob coação ou violência',
    value: 'R$ 2.000,00',
  },
  {
    text: 'Transações com o celular roubado ou furtado',
    value: 'R$ 2.000,00',
  },
]
