import { sendDataNative } from 'components/hooks/useFetchNative'
import { ITrackEvent } from 'interfaces/trackEvent.interface'

export function useTrackEvent() {
  return (eventName: string, params?: Record<string, unknown>) => {
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: eventName,
        parameters: {
          ...params,
        },
      },
    })
  }
}
