import React from 'react'

import { PageContainer, ButtonWrapper, Button, ListInfo } from 'components'
import { TSendDataNative, sendDataNative } from 'components/hooks/useFetchNative'
import { DESCRIPTION_SHARE_FRIENDS } from 'shared/functions/constants'
import { ITrackEvent, TrackEventEnum } from 'interfaces/trackEvent.interface'
import { IShare } from 'interfaces/share.interface'
import imgContaBasica from 'assets/images/contaBasica.png'

import { Text } from '@inter/inter-ui'

const ContaBasicaAberta = () => {
  const indicarAmigos = () => {
    const callApi: TSendDataNative<IShare> = {
      callback: 'callbackIndicarAmigos',
      body: { description: DESCRIPTION_SHARE_FRIENDS },
      endpoint: '',
      method: 'SHARE',
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_CONTA_BASICA_ABERTA_INDICAR,
        parameters: {},
      },
    })
  }

  const completeOnboarding = () => {
    const callApi: TSendDataNative = {
      callback: 'callbackCompleteOnboarding',
      endpoint: '',
      method: 'COMPLETEONBOARDING',
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_PENDENCIAMENTO_APROVADO_CONTINUAR,
        parameters: {},
      },
    })
  }

  return (
    <PageContainer>
      <img
        src={imgContaBasica}
        alt="Conta Básica Aberta!"
        style={{ margin: '24px -24px', width: 'calc(100% + 48px)' }}
      />

      <Text variant="headline-h1" as="h1">
        Primeiro, acesse sua conta
      </Text>

      <Text variant="body-3" as="p">
        Vimos que você já tem um Cadastro Básico aqui no Inter! Para ter uma Conta Completa com
        acesso a todos os serviços do Inter, incluindo a conta corrente, e facilitar ainda mais sua
        vida, é simples:
      </Text>

      <ListInfo pageNumber="1">
        <p>
          <strong>Acesse sua conta;</strong>
        </p>
      </ListInfo>

      <ListInfo pageNumber="2">
        <p>
          Na home do app, toque no botão “<strong>Abrir conta completa</strong>
          ”.
        </p>
      </ListInfo>

      <Text variant="body-3" as="p" style={{ marginTop: '16px' }}>
        Vai ser tudo bem rapidinho. Vamos lá?
      </Text>

      <ButtonWrapper>
        <Button id="submit" onClick={completeOnboarding}>
          Acessar conta
        </Button>
        <Button id="indicate" variant="secondary" onClick={indicarAmigos}>
          Indicar amigos
        </Button>
      </ButtonWrapper>
    </PageContainer>
  )
}

export default ContaBasicaAberta
