import homemCelular from 'assets/images/contaMei/homemSegurandoCelularCartao.png'
import homemMaquininha from 'assets/images/contaMei/homemSegurandoMaquininha.png'
import mulherFalando from 'assets/images/contaMei/mulherFalandoCelular.png'
import mulherCelular from 'assets/images/contaMei/mulherSegurandoCelular.png'

export const slidersAccountInfo = [
  {
    img: mulherCelular,
    title: 'Também quer abrir uma conta Empresa ou MEI?',
    contents: 'É muito simples e muito rápido!',
  },
  {
    img: homemCelular,
    title: 'Crédito com facilidade pra sua empresa',
    contents:
      'Cartão de credito sob análise sem anuidade, com muito cashback ao pagar a fatura e investimento em CDB para turbinar seu limite.',
  },
  {
    img: homemMaquininha,
    title: 'Solução completa em maquininha',
    contents: 'Contrate sua máquina de cartão, receba antecipadamente e acompanhe suas transações.',
  },
  {
    img: mulherFalando,
    title: 'Carteira de investimentos',
    contents:
      'Investimentos em fundos de renda fixa e muitas outras opções com toda a facilidade do Inter Empresas.',
  },
]

export const contaMeiLabels = {
  CNPJ: 'Qual é o CNPJ da sua empresa?',
  FATURAMENTO: 'Qual o faturamento ou previsão mensal da empresa?',
  PAGE_NAME: 'Conta MEI',
  OPEN_MEI_ACCOUNT: 'Abrir conta Empresa ou MEI',
  NOT_OPEN_ACCOUNT: 'Agora não',
  CONTINUE: 'Continuar',
  UNDERSTOOD: 'Entendi',
  COMPANY_CNPJ: 'Qual é o CNPJ da sua empresa?',
  SENT_SUCCESS: 'Solicitação enviada com sucesso',
  SENT_FAILT: 'Não conseguimos enviar seu CNPJ',
}
