import * as yup from 'yup'
import { REQUIRED_MESSAGE, INVALID_MESSAGE, dontUseSpecialCharacters } from 'validations/messages'

const VALID_CEP_LENGHT = 8

const cleanNumber = (value: string) => {
  const rgx = /\D/g

  return value.replace(rgx, '')
}

const isValidNumber = (value: string | undefined) => {
  if (!value) {
    return false
  }
  const number = cleanNumber(value)

  return parseInt(number, 10) > 0
}

const isValidCEP = (value: string | undefined) => {
  if (!value) {
    return false
  }

  const cep = cleanNumber(value)

  return cep.length === VALID_CEP_LENGHT
}

type TFormAddress = {
  disableStreet: boolean
  disableDistrict: boolean
  genericZipCode: boolean
}

export const yupValidacaoFormAddress = ({
  disableStreet,
  disableDistrict,
  genericZipCode,
}: TFormAddress) =>
  yup.object().shape({
    cep: yup
      .string()
      .required(REQUIRED_MESSAGE('CEP'))
      .test('isValidCEP', INVALID_MESSAGE('CEP'), (value: string | undefined) => isValidCEP(value)),
    noNumber: yup.string(),
    tipoLogradouro: yup.string().required(REQUIRED_MESSAGE('Tipo')),
    logradouro: disableStreet
      ? yup.string().trim().required(REQUIRED_MESSAGE('Endereço'))
      : yup
          .string()
          .trim()
          .required(REQUIRED_MESSAGE('Endereço'))
          .matches(dontUseSpecialCharacters.rule, dontUseSpecialCharacters.message),
    bairro: disableDistrict
      ? yup.string().trim().required(REQUIRED_MESSAGE('Bairro'))
      : yup
          .string()
          .trim()
          .required(REQUIRED_MESSAGE('Bairro'))
          .matches(dontUseSpecialCharacters.rule, dontUseSpecialCharacters.message),
    numero: yup.string().when('noNumber', {
      is: (value: string) => value === 'on',
      then: yup.string().nullable(),
      otherwise: yup
        .string()
        .required(REQUIRED_MESSAGE('Número'))
        .test('isZero', INVALID_MESSAGE('Número'), (value: string | undefined) =>
          isValidNumber(value),
        ),
    }),
    referencia: genericZipCode
      ? yup
          .string()
          .trim()
          .required(REQUIRED_MESSAGE('Complemento'))
          .matches(dontUseSpecialCharacters.rule, dontUseSpecialCharacters.message)
      : yup.string().when('noNumber', {
          is: (value: string) => value === 'on',
          then: yup
            .string()
            .trim()
            .required(REQUIRED_MESSAGE('Complemento'))
            .matches(dontUseSpecialCharacters.rule, dontUseSpecialCharacters.message),
          otherwise: yup
            .string()
            .nullable()
            .matches(dontUseSpecialCharacters.rule, dontUseSpecialCharacters.message),
        }),
  })
