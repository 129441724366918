import styled from 'styled-components'
import { Gray } from 'assets/styled-component/variables'

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid ${Gray[200]};
`
