import profissoesMinor from 'shared/json/profissoesMinor.json'
import profissoesUnderFourteen from 'shared/json/profissoesUnderFourteen.json'
import profissoes from 'shared/json/profissoes.json'

export function checkIsUnderForteen(isUnderFourteen?: boolean, isMinor?: boolean) {
  if (isUnderFourteen) {
    return profissoesUnderFourteen
  }
  if (isMinor) {
    return profissoesMinor
  }

  return profissoes
}
