/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState, ChangeEvent } from 'react'

import { useForm, SubmitHandler } from 'react-hook-form'
import { getPathCEP } from 'components/urls'
import estados from 'shared/json/estados.json'
import tipoLogradouroList from 'shared/json/tiposLogradouros.json'
import { GenericRes, ApiResponse, ApiError } from 'interfaces/response.interface'
import { useCallbackNative } from 'components/hooks/useCallbackNative'
import { sendDataNative } from 'components/hooks/useFetchNative'
import {
  Select,
  Button,
  ButtonWrapper,
  FlexForm,
  Checkbox,
  Input,
  BoxRow,
  Radio,
  Alert,
  BottomSheet,
  Text,
} from 'components'
import { yupValidacaoFormAddress } from 'validations'
import { sendToNewRelic } from 'services'
import { maskZipCode, toTitleCase } from 'shared/functions/utils'
import { Gray, Orange } from 'assets/styled-component/variables'

import { Icon } from '@inter/inter-ui'
import { yupResolver } from '@hookform/resolvers/yup'

import { ListAddress } from './styled'

type TAddressComplete = {
  codCidade: string
  uf: string
  numero: string
  noNumber: string
  cidade: string
  bairro: string
  tipoLogradouro: string
  logradouro: string
  referencia: string
  cep: string
  isRestrito: boolean
  isSameAddress: boolean
  complemento: string
  bandeira: string
}

type TRespondeAddress = {
  endereco: TAddressComplete
}

export type TAddress = {
  children?: ReactNode
  handleSendData: (data: Record<string, unknown>) => void
  deliveryAddress?: boolean
  callbackDefault?: (status: boolean) => void
  errors?: TAddressComplete
  setAttentionModal?: React.Dispatch<React.SetStateAction<boolean>>
  idScreen: string
  submitLoading: boolean
}

type TOptionAddress = 'sim' | 'nao'

const CEP_AMARELO = 'AMARELO'
const CEP_VERMELHO = 'VERMELHO'

export const FormAddress: React.FC<TAddress> = ({
  handleSendData,
  deliveryAddress,
  callbackDefault,
  setAttentionModal = () => null,
  idScreen,
  submitLoading,
}: TAddress) => {
  const [validate, setValidate] = useState<boolean>(false)
  const [noNumber, setNoNumber] = useState<boolean>(false)
  const [isReceiveEqualAddress, setIsReceiveEqualAddress] = useState<boolean>(!deliveryAddress)
  const [sameAddress, setSameAddress] = useState<TOptionAddress>('sim')
  const [loading, setLoading] = useState<boolean>(false)
  const [state, setState] = useState<string>('')
  const [disableStreetType, setDisableStreetType] = useState<boolean>(false)
  const [disableStreet, setDisableStreet] = useState<boolean>(false)
  const [disableDistrict, setDisableDistrict] = useState<boolean>(false)
  const [confirmNoNumber, setConfirmNoNumber] = useState<boolean>(false)
  const [genericZipCode, setGenericZipCode] = useState<boolean>(false)
  const [isDeliveryAddres, setIsDeliveryAddres] = useState<boolean>(false)
  const [isValidForm, setIsValidForm] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    getValues,
    setError,
    formState: { errors },
    trigger,
  } = useForm<TAddressComplete>({
    resolver: yupResolver(
      yupValidacaoFormAddress({
        disableStreet,
        disableDistrict,
        genericZipCode,
      }),
    ),
  })

  const isRedFlagOrRestrict = () =>
    getValues('bandeira') ? getValues('bandeira') === CEP_VERMELHO : getValues('isRestrito')

  const verifySameAddress = (bandeira: string, isRestrito: boolean) => {
    if (bandeira) {
      if (bandeira === CEP_VERMELHO) return 'nao'
      return 'sim'
    }
    if (isRestrito) return 'nao'
    return 'sim'
  }

  const verifyReceiveEqualAddress = (bandeira: string, isRestrito: boolean) => {
    if (bandeira) {
      if (bandeira === CEP_VERMELHO) return false
      return true
    }
    if (isRestrito) return false
    return true
  }

  const setAllValues = (endereco: TAddressComplete) => {
    setValue('cep', endereco.cep)
    setValue('uf', endereco.uf)
    setValue('cidade', toTitleCase(endereco.cidade))
    setValue('codCidade', endereco.codCidade)
    setValue('bairro', endereco.bairro)
    setValue('isRestrito', endereco.isRestrito)
    setValue('logradouro', endereco.logradouro)
    setValue('numero', endereco.numero)
    setValue('referencia', endereco.referencia)
    setValue('tipoLogradouro', endereco.tipoLogradouro)
    setValue('bandeira', endereco.bandeira)

    if (endereco.tipoLogradouro) setDisableStreetType(true)
    if (endereco.logradouro) {
      setDisableStreet(true)
      setGenericZipCode(false)
    } else {
      setGenericZipCode(true)
    }
    if (endereco.bairro) setDisableDistrict(true)

    if (endereco.uf) {
      const ufEstado = estados.find((e) => e.key === endereco.uf)
      setState(ufEstado?.value ?? '')
    }
    setSameAddress(verifySameAddress(endereco.bandeira || '', endereco.isRestrito))
    setIsReceiveEqualAddress(
      verifyReceiveEqualAddress(endereco.bandeira || '', endereco.isRestrito),
    )
  }

  useCallbackNative(
    `callbackApiCEP${idScreen}`,
    `onCallbackApiCEP${idScreen}`,
    (() => {
      const listenerCallback: Partial<(data: GenericRes) => void> = {}
      listenerCallback[`onCallbackApiCEP${idScreen}`] = (data: GenericRes<TRespondeAddress>) => {
        if (data instanceof ApiResponse) {
          setAllValues(data.dados.endereco)
          if (
            deliveryAddress &&
            (data.dados.endereco.bandeira
              ? data.dados.endereco.bandeira === CEP_VERMELHO
              : data.dados.endereco.isRestrito)
          ) {
            setAttentionModal(true)
          }
          sendToNewRelic('FORMULARIO_ENDERECO', `[ApiResponse] - callbackApiCEP${idScreen}`, {
            CEP: data.dados.endereco.cep,
            CIDADE: data.dados.endereco.cidade,
            UF: data.dados.endereco.uf,
          })
          setValidate(true)
        } else if (data instanceof ApiError) {
          setValidate(false)
          if (
            data.campo &&
            (data.campo === 'cep' ||
              data.campo === 'tipoLogradouro' ||
              data.campo === 'logradouro' ||
              data.campo === 'numero' ||
              data.campo === 'referencia' ||
              data.campo === 'bairro')
          ) {
            setError(data.campo, {
              message: data.mensagem || 'Campo obrigatório.',
            })
          } else {
            sendToNewRelic('FORMULARIO_ENDERECO', `[ApiError] - callbackApiCEP${idScreen}`, {
              ...data,
            })
          }
        }
        setLoading(false)
      }
      return listenerCallback
    })(),
  )

  const onChangeReceiveAddress = (element: ChangeEvent<HTMLInputElement>) => {
    const { value } = element.currentTarget
    if (value) {
      setSameAddress(value as TOptionAddress)
    }
    return setIsReceiveEqualAddress(value === 'sim')
  }

  const handleChangeZipCode = (event: ChangeEvent<HTMLInputElement>) => {
    triggerCleanFormWhenChangeZipCode()
    const cepValue = event.target.value
    if (cepValue?.length === 10) {
      setLoading(true)
      event.target.blur()
      clearErrors()
      sendDataNative({
        callback: `callbackApiCEP${idScreen}`,
        endpoint: getPathCEP(cepValue || ''),
      })
      if (!validate) {
        if (callbackDefault) callbackDefault(true)
      }
    } else {
      setValidate(false)
    }
  }

  const triggerCleanFormWhenChangeZipCode = () => {
    setDisableDistrict(false)
    setDisableStreet(false)
    setDisableStreetType(false)
    setGenericZipCode(false)
    changeNoNumber(false)
  }

  const isRestrictValidate = () => {
    if (loading) return true
    if (!getValues('cep')) return true
    if (!deliveryAddress) {
      return isReceiveEqualAddress && isRedFlagOrRestrict()
    }
    return isRedFlagOrRestrict()
  }

  const handleToggleNoNumber = () => {
    const actualValue = !noNumber
    changeNoNumber(actualValue)
    setConfirmNoNumber(false)
  }

  const changeNoNumber = (newValue: boolean) => {
    setNoNumber(newValue)
    clearErrors('numero')
    clearErrors('referencia')
    setValue('numero', '')
    setValue('noNumber', newValue ? 'on' : 'off')
  }
  const handleTipoLogradouro = (value: string) => {
    setValue('tipoLogradouro', value)
    clearErrors('tipoLogradouro')
  }
  const handleLogradouro = () => {
    clearErrors('logradouro')
  }
  const handleNumero = () => {
    clearErrors('numero')
  }
  const handleReferencia = () => {
    clearErrors('referencia')
  }
  const handleBairro = () => {
    clearErrors('bairro')
  }

  const onSubmit: SubmitHandler<TAddressComplete> = (form: TAddressComplete) => {
    if (noNumber) {
      form.numero = '0'
    }

    form.uf = getValues('uf')
    form.complemento = getValues('referencia')
    form.isSameAddress = isReceiveEqualAddress

    handleModalDeliveryAddres()
    handleSendData(form)
  }

  const displayFields = () => {
    if (validate) {
      return (
        !deliveryAddress ||
        (getValues('bandeira') ? getValues('bandeira') !== CEP_VERMELHO : !getValues('isRestrito'))
      )
    }
    return validate
  }

  const handleModalDeliveryAddres = () => {
    setIsValidForm(false)
    setIsDeliveryAddres(!isDeliveryAddres)
  }

  useEffect(() => {
    const handleSubmitAddress = () => {
      if (isValidForm) {
        if (isReceiveEqualAddress || deliveryAddress) {
          handleModalDeliveryAddres()
        } else {
          onSubmit(getValues())
        }
      }
    }

    handleSubmitAddress()
  }, [isValidForm])

  return (
    <FlexForm onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="isRestrito" value={watch('isRestrito') ? 'true' : 'false'} />
      <Input
        id="cep"
        type="tel"
        label="CEP"
        placeholder="00.000-000"
        mask="99.999-999"
        maxLength={10}
        error={Boolean(errors.cep)}
        infoText={errors.cep?.message}
        iconRight={loading && <Icon name="loading" size={24} color={Orange[500]} />}
        {...register('cep')}
        onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeZipCode(event)}
      />

      {displayFields() && (
        <>
          {genericZipCode && (
            <div style={{ paddingBottom: '20px' }}>
              <Alert title="Esse CEP não possui endereço completo na base dos Correios">
                <p className="caption">
                  Utilize o campo Complemento para descrever uma forma de encontrarmos você.
                </p>
              </Alert>
            </div>
          )}

          <Select
            id="tipoLogradouro"
            label="Tipo"
            options={tipoLogradouroList}
            placeholder="Selecione o tipo"
            value={watch('tipoLogradouro')}
            setSelectValue={handleTipoLogradouro}
            style={{ width: '50%' }}
            readOnly={disableStreetType}
            error={Boolean(errors.tipoLogradouro)}
            infoText={errors.tipoLogradouro?.message}
            {...register('tipoLogradouro')}
            disabled={disableStreetType}
          />

          <Input
            id="logradouro"
            type="text"
            maxLength={40}
            placeholder="Endereço"
            label="Endereço"
            readOnly={disableStreet}
            className={disableStreet ? 'readonlyDisabled' : ''}
            error={Boolean(errors.logradouro)}
            infoText={errors.logradouro?.message}
            {...register('logradouro')}
            onChange={handleLogradouro}
          />

          <BoxRow>
            <div style={{ width: '50%' }}>
              <Input
                id="numero"
                disabled={noNumber}
                type="tel"
                mask="999999"
                placeholder="Informe o número"
                label="Número"
                maxLength={6}
                error={Boolean(errors.numero)}
                infoText={errors.numero?.message}
                style={{ width: '100%' }}
                {...register('numero')}
                onChange={handleNumero}
              />
            </div>

            <Checkbox
              id="noNumber"
              name="noNumber"
              checked={noNumber}
              onChange={noNumber ? handleToggleNoNumber : () => setConfirmNoNumber(true)}
              label="Sem número"
            />
          </BoxRow>

          <Input
            id="complemento"
            type="text"
            placeholder="Ex: Bloco, quadra, apartamento..."
            label="Complemento"
            maxLength={40}
            error={Boolean(errors.referencia)}
            infoText={errors.referencia?.message}
            {...register('referencia')}
            onChange={handleReferencia}
          />

          <Input
            type="text"
            id="bairro"
            placeholder="Informe o bairro"
            label="Bairro"
            maxLength={40}
            readOnly={disableDistrict}
            className={disableDistrict ? 'readonlyDisabled' : ''}
            error={Boolean(errors.bairro)}
            infoText={errors.bairro?.message}
            {...register('bairro')}
            onChange={handleBairro}
          />

          <Input
            type="text"
            id="estado"
            value={state}
            placeholder="Informe o estado"
            label="Estado"
            maxLength={40}
            readOnly
            className="readonlyDisabled"
            iconRight={<Icon name="arrow-down" size={24} color={Gray[200]} />}
          />

          <Input
            type="text"
            id="cidade"
            label="Cidade"
            value={getValues('cidade')}
            placeholder="Informe a cidade"
            maxLength={40}
            readOnly
            className="readonlyDisabled"
            {...register('cidade')}
          />

          {!deliveryAddress && (
            <>
              <p>Quer receber o cartão neste mesmo endereço?</p>
              <BoxRow style={{ justifyContent: 'left', marginBottom: '16px' }}>
                <Radio
                  id="radio-false"
                  name="changeDeliveryAddress"
                  label="Não"
                  value="nao"
                  checked={sameAddress === 'nao'}
                  onChange={onChangeReceiveAddress}
                  style={{ marginRight: '68px' }}
                />
                <Radio
                  id="radio-true"
                  name="changeDeliveryAddress"
                  label="Sim"
                  value="sim"
                  disabled={isRedFlagOrRestrict()}
                  checked={sameAddress === 'sim'}
                  onChange={onChangeReceiveAddress}
                />
              </BoxRow>
            </>
          )}
        </>
      )}

      <ButtonWrapper>
        {validate && !deliveryAddress && isRedFlagOrRestrict() && (
          <Alert title="CEP fora da área de cobertura de entrega">
            <p className="caption">
              Você poderá adicionar outro endereço de entrega na tela seguinte.
            </p>
          </Alert>
        )}

        {validate && getValues('bandeira') === CEP_AMARELO && sameAddress === 'sim' && (
          <Alert>
            <p className="caption">
              O prazo de entrega neste CEP será maior que o usual, de 15 dias úteis.
            </p>
          </Alert>
        )}

        <Button
          disabled={isRestrictValidate()}
          onClick={async () => {
            setIsValidForm(await trigger())
          }}
          isLoading={submitLoading}
        >
          Continuar
        </Button>
      </ButtonWrapper>

      {isDeliveryAddres && (
        <BottomSheet onClose={handleModalDeliveryAddres}>
          <h3>Confirmar endereço de entrega do cartão</h3>

          <ListAddress>
            {`${getValues('tipoLogradouro') || ''} ${getValues('logradouro') || ''}`},{' '}
            {getValues('numero') || 'S/N'}
            <br />
            {getValues('bairro')}
            {getValues('referencia') ? ` - ${getValues('referencia')}` : ''}
            <br />
            {toTitleCase(getValues('cidade'))}/{getValues('uf')} - {maskZipCode(getValues('cep'))}
          </ListAddress>

          <ButtonWrapper>
            <Button type="submit">Confirmar</Button>
            <Button onClick={handleModalDeliveryAddres} variant="secondary">
              Editar
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}

      {confirmNoNumber && (
        <BottomSheet onClose={() => setConfirmNoNumber(false)}>
          <Text variant="headline-h3" semiBold colorWeight={500}>
            Seu endereço não tem número?
          </Text>
          <Text variant="body-3" as="p" style={{ marginTop: '4px' }}>
            Confirme o número do seu endereço para que sua conta seja aberta com sucesso.
          </Text>

          <ButtonWrapper>
            <Button onClick={handleToggleNoNumber}>Meu endereço não tem número</Button>
            <Button onClick={() => setConfirmNoNumber(false)} variant="secondary">
              Voltar
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}
    </FlexForm>
  )
}
