import { ServiceResponseError } from 'exceptions'
import { BaseServices, EHttpMethod } from 'services/base'
import { urls } from 'services/base/url'

import { TResponseInfo } from '../types'
import { buildCodeValidateResponse } from '../utils'
import { TResponseValidaCodigo, TRequestCodeValidationPayload } from './types'

const request = (payload: TRequestCodeValidationPayload) =>
  BaseServices.request<TResponseValidaCodigo>({
    pathname: urls.GET_CODIGO_VALIDAR,
    method: EHttpMethod.GET,
    host: process.env.REACT_APP_API_HOST,
    params: { ...payload.params, campanha: process.env.REACT_APP_CAMPANHA_MGM },
    headers: { 'x-id-ficha-cadastral': payload.header.hsfc },
  })

export const getValidaCodigo = async (payload: TRequestCodeValidationPayload) => {
  try {
    const response = await request(payload)
    const resp: TResponseInfo = buildCodeValidateResponse(response)

    payload.success && payload.success(resp)
  } catch (error: unknown) {
    if (error instanceof ServiceResponseError) {
      const errorCallback: TResponseInfo = buildCodeValidateResponse(error)
      payload.error && payload.error(errorCallback)
    }
  } finally {
    payload.finally && payload.finally()
  }
}
