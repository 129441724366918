import styled from 'styled-components'

export const CustomFlexForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`

export const DivFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`
