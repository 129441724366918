import { Receipt, CardProtection } from 'components/elements/Icons'

import {
  InsuranceCrossSellingCardBottomSheetInfo,
  InsuranceCrossSellingCardSwitchInfo,
} from './types'

export const getIcons = (icon: string) => {
  if (icon === 'Card-Protection') {
    return CardProtection
  }

  console.warn('Icon not supported')
  return Receipt
}

export const protectedCardBottomSheetInfo: InsuranceCrossSellingCardBottomSheetInfo = {
  bottomSheetTitle: 'Proteção adicional em casos de:',
  bottomSheetCoverageList: [
    { label: 'Perda, roubo ou furto do cartão', value: 'R$ 3.000,00' },
    { label: 'Saque ou compra sob coação', value: 'R$ 3.000,00' },
    { label: 'Concorra a sorteios de', value: 'R$ 5.000,00' },
  ],
  bottomSheetButtonLabel: 'Entendi',
}

export const protectedCardInfo: InsuranceCrossSellingCardSwitchInfo = {
  switchTag: { label: '1 mês grátis' },
  switchCardIcon: 'Card-Protection',
  switchCardTitle: 'Cartão + Protegido',
  switchCardSubTitle: 'R$ 1,90 a partir do 2º mês.',
  switchCardMsg: 'Seu cartão estará mais protegido',
  switchCardMsgOff: 'Proteção extra contra perda, roubo ou furto',
  switchCardInfoButtonLabel: 'Saiba mais',
}
