import React, { memo } from 'react'

import * as S from './style'
import { TagProps } from './types'

export const Component = ({ title, description, subDescription, icon, onClick }: TagProps) => (
  <S.Container hasOnClick={Boolean(onClick)}>
    <S.TooltipCard>
      <S.TooltipIcon>{icon}</S.TooltipIcon>

      <S.TooltipBox>
        <b>{title}</b>
        <p>{description}</p>
        <span>{subDescription}</span>
      </S.TooltipBox>
    </S.TooltipCard>
  </S.Container>
)

export const Tooltip = memo(Component)
