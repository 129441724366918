import React, { PropsWithChildren } from 'react'

import { Checkbox as InterCheckbox, CheckboxProps } from '@inter/inter-ui'

import { CheckboxWrapper } from './style'

type ICheckboxProps = CheckboxProps

export const Checkbox: React.FC<PropsWithChildren<ICheckboxProps>> = ({
  children,
  ...props
}: PropsWithChildren<ICheckboxProps>) => (
  <CheckboxWrapper>
    {children}
    <InterCheckbox {...props} />
  </CheckboxWrapper>
)
