import * as yup from 'yup'
import { REQUIRED_MESSAGE } from 'validations/messages'
import { checkValidCNPJ } from 'inter-frontend-lib-util-form/lib/validations/cnpj/index'

export const yupValidacaoContaMei = yup.object().shape({
  termsAccepted: yup.boolean(),
  cnpj: yup
    .string()
    .required(REQUIRED_MESSAGE('CNPJ'))
    .test(
      'is-valid-cnpj',
      'CNPJ inválido',
      (value: string | undefined) => value === undefined || checkValidCNPJ(value),
    ),
  faturamento: yup
    .string()
    .required(REQUIRED_MESSAGE('Faturamento'))
    .test(
      'is-valid-currency',
      'Valor inválido',
      (value: string | undefined) =>
        value === undefined || !(value.replace('R$', '').trim() === '0,00'),
    ),
})
