import { NEW_RELIC_ACCOUNT, NEW_RELIC_KEY } from 'common/constants'
import { useTrack as trackData } from 'inter-frontend-hooks'

const sendToNewRelic = (
  eventType: string,
  eventSubType: string,
  moreInfo?: Record<string, unknown>,
) =>
  trackData({
    type: 'insightsCollector',
    dataReport: {
      ...moreInfo,
      transactionType: eventType,
      eventType: 'AberturaContaPFReact',
      name: 'Abertura de Conta Digital PF',
      appName: 'CDPF REACT',
      transactionSubType: eventSubType,
    },
    account: NEW_RELIC_ACCOUNT,
    key: NEW_RELIC_KEY,
  })

export default sendToNewRelic
