import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { PageContainer, PageText, Button, FlexForm, ButtonWrapper, Radio } from 'components'
import { sendDataNative } from 'components/hooks/useFetchNative'
import { RoutesEnum } from 'enums/routes.enum'
import estadosCivis from 'shared/json/estadoCivil.json'
import { IDadosPessoaisCtx } from 'interfaces/dadosPessoais.interface'
import { TrackEventEnum, ITrackEvent } from 'interfaces/trackEvent.interface'
import { yupValidacaoEstadoCivil } from 'validations'
import { DadosPessoaisCtx } from 'App'

import { yupResolver } from '@hookform/resolvers/yup'

type TFormInput = {
  estadoCivil?: string
}

const DadosPessoaisEstadoCivil = () => {
  const [loading] = useState<boolean>(false)
  const [maritalStatus, setMaritalStatus] = useState<string>('')
  const history = useHistory()
  const { dadosPessoais, setDadosPessoais }: IDadosPessoaisCtx =
    useContext<IDadosPessoaisCtx>(DadosPessoaisCtx)

  const { register, handleSubmit, setValue, watch } = useForm<TFormInput>({
    resolver: yupResolver(yupValidacaoEstadoCivil),
  })

  const onSubmit: SubmitHandler<TFormInput> = (form: TFormInput) => {
    setDadosPessoais({ ...dadosPessoais, estadoCivil: form.estadoCivil })

    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_DADOS_BASICOS_ESTADO_CIVIL_CONTINUAR,
        parameters: {
          estado_civil: form.estadoCivil,
        },
      },
    })
    history.push(RoutesEnum.DADOS_PESSOAIS_CONTATO)
  }

  const handleChangeMaritalStatus = (value: string) => {
    setMaritalStatus(value)
    setValue('estadoCivil', value)
  }

  return (
    <PageContainer>
      <PageText text="Qual seu estado civil?" />

      <FlexForm onSubmit={handleSubmit(onSubmit)}>
        {estadosCivis.map((item, index) => (
          <div key={item.key}>
            <Radio
              id="estadoCivil"
              label={item.value}
              value={item.value}
              checked={maritalStatus === item.value}
              {...register('estadoCivil')}
              onChange={() => handleChangeMaritalStatus(item.value)}
              style={{ justifyContent: 'space-between' }}
            />
            {index !== estadosCivis.length - 1 && <hr />}
          </div>
        ))}
        <ButtonWrapper>
          <Button isLoading={loading} disabled={!watch('estadoCivil')} type="submit">
            Continuar
          </Button>
        </ButtonWrapper>
      </FlexForm>
    </PageContainer>
  )
}

export default DadosPessoaisEstadoCivil
