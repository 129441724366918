import conteudo01 from 'assets/images/contaGlobal/conteudo01.png'
import conteudo02 from 'assets/images/contaGlobal/conteudo02.png'
import conteudo03 from 'assets/images/contaGlobal/conteudo03.png'
import conteudo04 from 'assets/images/contaGlobal/conteudo04.png'

export const slidersAccountInfo = [
  {
    img: conteudo01,
    title: 'Conquistar o mundo pode ser simples',
    contents:
      'A Global Account é uma solução completa que traz autonomia financeira e mais facilidade para viagens e compras no exterior.',
  },
  {
    img: conteudo02,
    title: 'Serviços financeiros para uma vida global',
    contents:
      'Envie e receba em dólar. Use seu cartão de débito online para compras em dólar e faça transações internacionais mais rápidas, com taxa zero de abertura e manutenção.',
  },
  {
    img: conteudo03,
    title: 'Segurança e confiança direto no Super App',
    contents:
      'Acesse sua Global Account no Super App e aproveite todas essas vantagens. O serviço é prestado pela Usend, empresa do Grupo Inter.',
  },
  {
    img: conteudo04,
    title: 'Já tem uma Global Account?',
    contents:
      'Se você já possui uma conta na Usend, ao prosseguir e aceitar os termos e condições sua conta será migrada para o Super App Inter e você não terá mais acesso a sua conta pelo app Usend.',
  },
]

export const contaGlobalLabels = {
  OPEN_GLOBAL_ACCOUNT: 'Abrir Global Account',
  NOT_OPEN_ACCOUNT: 'Agora não',
  TRY_AGAIN: 'Tentar novamente',
  PAGE_NAME: 'Global Account',
  UNDERSTOOD: 'Entendi',
  CONTINUE: 'Continuar',
}
