import styled, { css } from 'styled-components'

import { Tag as UITag } from '@interco/inter-ui/components/Tag'

export const ContentWrapper = styled.div<{ gap?: string; mb?: string; mt?: string }>`
  ${({ gap = '4px', mb = undefined, mt = undefined }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${gap};
    margin-bottom: ${mb};
    margin-top: ${mt};
  `}
`

export const InsuranceWrapper = styled.div`
  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: 24px;
`

export const InsuranceContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
`

export const IconWrapper = styled.div`
  border-radius: 8px;
  width: 48px;
  height: 48px;
  padding: 12px;
  background-color: var(--primary100);
`

export const BottomSheetContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`

export const TagStyled = styled(UITag)`
  height: 16px;
  align-self: center;
  margin: 0;
`

export const InsuranceSwitch = styled.div`
  align-self: center;
  margin-left: auto;
`

export const InsuranceBottomSheetList = styled.div`
  display: grid;
  grid-template-columns: 60% 30%;
  justify-content: space-between;
  padding-bottom: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray200);
  }
`

export const InsuranceBottomSheetContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
