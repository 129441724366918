import React, { ReactNode } from 'react'

import {
  PageContainerWrapper,
  BoxInfoWrapper,
  BoxCenterWrapper,
  BoxVerticalCenterWrapper,
} from './style'

type TPageContainer = {
  children?: ReactNode
  className?: string
}

type TBoxInfo = {
  children?: ReactNode
}

type TBoxCenter = {
  children?: ReactNode
}

type TBoxVerticalCenter = {
  children?: ReactNode
}

export const PageContainer = ({ children, className }: TPageContainer) => (
  <PageContainerWrapper className={`${className} container-app`}>{children}</PageContainerWrapper>
)

export const BoxInfo = ({ children }: TBoxInfo) => <BoxInfoWrapper>{children}</BoxInfoWrapper>

export const BoxCenter = ({ children, ...attrs }: TBoxCenter) => (
  <BoxCenterWrapper {...attrs}>{children}</BoxCenterWrapper>
)

export const BoxVerticalCenter = ({ children }: TBoxVerticalCenter) => (
  <BoxVerticalCenterWrapper>{children}</BoxVerticalCenterWrapper>
)

PageContainer.defaultProps = {
  children: undefined,
  className: undefined,
}

BoxInfo.defaultProps = {
  children: undefined,
}

BoxCenter.defaultProps = {
  children: undefined,
}

BoxVerticalCenter.defaultProps = {
  children: undefined,
}
