import { UIButton } from 'components'
import styled, { css } from 'styled-components'

type isTermsList = {
  showTermsList?: boolean
}

export const TitleContainer = styled.div<isTermsList>`
  margin: 0 -24px;
  padding: 0 24px;

  display: flex;
  align-items: center;
  ${({ showTermsList }) =>
    showTermsList
      ? css`
          h3 {
            margin-left: 45% !important;
            transform: translateX(-50%);
          }
        `
      : css`
          justify-content: space-between;
        `}

  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: white;
`

export const FooterContainer = styled.div`
  margin: 0 -24px;
  padding: 24px 24px 0;

  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;

  z-index: 5;
  background-color: white;

  border-top: 1px solid var(--gray200);
`

export const Divider = styled.hr`
  margin: 12px -24px;
  border-top: 2px solid var(--gray200);
`

export const Controller = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const SButton = styled(UIButton)`
  display: inline;
  padding: 0;
  text-align: left;
`
