import styled from 'styled-components'

import { Card } from '@interco/inter-ui/components/Card'

export const CarouselWrapper = styled.div`
  margin: 24px -24px;
  width: calc(100% + 48px);

  .slick-slider {
    min-height: 467px;
  }
`

export const PDFContainer = styled.div`
  margin: 0 -12px;
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`
export const SCard = styled(Card)`
  & + div {
    margin-top: 16px;
  }
`
