export const REQUIRED_MESSAGE = (field: string) => `${field} é obrigatório`
export const MIN_MESSAGE = (field: string, value: number) =>
  `${field} deve ter no mínino ${value} dígitos`
export const LENGTH_MESSAGE = (field: string, value: number) => `${field} deve ter ${value} dígitos`
export const INVALID_MESSAGE = (field: string) => `Informe um ${field} válido`

export const dontUseSpecialCharacters = {
  rule: /^[\w á-úÁ-Úà-ùÀ-Ù-'`´]*$/,
  message: 'Não use caracteres especiais (ex: *, #, $)',
}
