import React from 'react'

import imgDadosProfissionais from 'assets/images/dadosProfissionais.png'
import { PageContainer, ButtonWrapper, Button, Text, Spacing } from 'components'

type TAtencaoPreencherInformacoesProps = {
  onButtonClick: () => void
}

const AtencaoPreencherInformacoes = ({ onButtonClick }: TAtencaoPreencherInformacoesProps) => (
  <PageContainer>
    <img
      src={imgDadosProfissionais}
      alt="Preencher todas as informações!"
      style={{ margin: '24px -24px', width: 'calc(100% + 48px)' }}
    />
    <Spacing mt="xs" mb="xs">
      <Text variant="headline-h1">Avaliação de crédito</Text>
    </Spacing>

    <Text variant="body-3" as="p">
      Preencha as informações a seguir corretamente, elas serão validadas e contribuem para a
      avaliação do seu limite de crédito.
    </Text>

    <ButtonWrapper>
      <Button id="submit" onClick={onButtonClick}>
        Continuar
      </Button>
    </ButtonWrapper>
  </PageContainer>
)

export default AtencaoPreencherInformacoes
