import styled from 'styled-components'
import { Gray, Yellow } from 'assets/styled-component/variables'

export const AlertWrapper = styled.div`
  background: ${Yellow[300]};
  border-radius: 8px;
  position: relative;
  z-index: 10;
  padding: 16px 12px;
  display: flex;

  p {
    line-height: 15px !important;
    color: ${Gray[500]} !important;
    margin-bottom: 0px;
  }
`

export const AlertTitle = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 4px;
`

export const AlertContent = styled.div`
  padding-left: 13px;
`
