import styled from 'styled-components'
import { Gray, Green, Orange } from 'assets/styled-component/variables'

export const PageContainerWrapper = styled.div`
  position: relative;
  padding: 0 24px 24px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch !important;

  font-family: Inter;

  ::-webkit-scrollbar {
    display: none !important;
  }

  h1 {
    font-size: 24px;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    color: ${Gray[500]};
    text-align: left;
    line-height: 28px;
    letter-spacing: 0px;
  }

  h3 {
    font-size: 16px;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    color: ${Gray[500]};
    text-align: left;
    line-height: 19px;
    letter-spacing: 0px;
    margin-bottom: 0.75rem;
  }

  p {
    font-style: normal;
    font-weight: 400;
    color: ${Gray[400]};
    font-size: 14px;
    line-height: 17px;
    display: inline-block;
    width: 100%;

    &.caption {
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
      color: ${Gray[500]};
      margin-bottom: 0;
    }
  }

  a {
    color: ${Orange[500]};
    text-decoration: none;

    :hover {
      color: ${Orange[500]};
    }

    &.moreInfo {
      font-size: 12px;
    }
  }
`

export const BoxInfoWrapper = styled.div`
  margin-bottom: 24px;

  .title {
    color: ${Gray[400]};
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0px;
  }

  ul {
    padding: 0px;
    margin: 0px;

    li {
      list-style: none;
      padding: 0px;
      margin: 0px;
      font-size: 12px;
      line-height: 15px;
      color: ${Gray[400]};
      font-weight: 700;

      &.checked {
        color: ${Green[400]};
      }
    }
  }
`

export const BoxCenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const BoxVerticalCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`
