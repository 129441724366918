import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { FormAddress, PageContainer, PageText } from 'components'
import { sendDataNative, TSendDataNative } from 'components/hooks/useFetchNative'
import { getRouter, RoutesEnum } from 'enums/routes.enum'
import { justDigits, normalizeCaracters } from 'shared/functions/utils'
import { IEnderecos, IEnderecoResidencial } from 'interfaces/enderecos.interface'
import { ITrackEvent, TrackEventEnum } from 'interfaces/trackEvent.interface'
import { useCallbackNative } from 'components/hooks/useCallbackNative'
import { ApiError, ApiResponse, GenericRes } from 'interfaces/response.interface'
import { sendToNewRelic } from 'services'
import { ENDERECOS } from 'components/urls'
import url from 'shared/functions/url'
import { getUrlParams } from 'shared/helpers/getUrlPrams'

type TEndereco = {
  endereco: IEnderecos
  setEndereco: React.Dispatch<React.SetStateAction<IEnderecos>>
}

type TForm = {
  cep: string
  tipoLogradouro: string
  logradouro: string
  isSameAddress: boolean
  numero: string
  complemento: string
  bairro: string
  uf: string
  cidade: string
  isRestrito: string
  bandeira: string
}

const Endereco: React.FC<TEndereco> = ({ endereco, setEndereco }: TEndereco) => {
  const history = useHistory()
  const [loadingOnSubmit, setLoadingOnSubmit] = useState<boolean>(false)

  const handleSendHomeAddress = () => {
    setLoadingOnSubmit(true)
    const callApi: TSendDataNative = {
      body: endereco,
      callback: 'callbackApiEndereco',
      endpoint: ENDERECOS,
      method: 'PUT',
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_ENDERECO_RESIDENCIAL_CONTINUAR,
        parameters: {},
      },
    })
  }

  useCallbackNative('callbackApiEndereco', 'onCallbackApiEndereco', {
    onCallbackApiEndereco: (data: GenericRes) => {
      if (data instanceof ApiResponse) {
        const step = getRouter(data.telas.stepList[0].step)

        sendToNewRelic('CONFIRMACAO_ENDERECO', '[ApiResponse] - callbackApiEndereco', {
          PROXIMA_ETAPA: step,
          URL: data.telas.stepList[0].url,
        })

        if (step) {
          history.push(`${step}${url.buildQuery(getUrlParams(data.telas.stepList[0].url))}`)
        } else {
          const callApi: TSendDataNative = {
            body: data,
            callback: 'callbackMediaUpdate',
            endpoint: '',
            method: 'CALLMEDIAUPDATE',
          }
          sendDataNative(callApi)
        }
      } else if (data instanceof ApiError) {
        sendToNewRelic('CONFIRMACAO_ENDERECO', '[ApiError] - callbackApiEndereco', {
          ...data,
        })
      }
      setLoadingOnSubmit(false)
    },
  })

  const handleSendData = (data: Record<string, unknown>) => {
    const contentData = data as TForm
    const newAddress: IEnderecoResidencial = {
      cep: justDigits(contentData.cep),
      tipoLogradouro: contentData.tipoLogradouro,
      logradouro: normalizeCaracters(contentData.logradouro),
      numero: +contentData.numero,
      complemento: normalizeCaracters(contentData.complemento),
      cidade: normalizeCaracters(contentData.cidade),
      uf: contentData.uf,
      bairro: normalizeCaracters(contentData.bairro),
      pais: 'BRASIL',
      isRestrito: contentData.isRestrito === 'true',
      bandeira: contentData.bandeira,
    }
    endereco.enderecoResidencial = newAddress
    endereco.enderecoCartaoIgualEnderecoResidencial = contentData.isSameAddress
    if (contentData.isSameAddress) {
      endereco.enderecoCartao = newAddress
    }
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_ENDERECO_RESIDENCIAL_CEP_CONTINUAR,
        parameters: {},
      },
    })
    setEndereco(endereco)
    if (contentData.isSameAddress) {
      handleSendHomeAddress()
    } else {
      history.push(RoutesEnum.ENDERECO_ENTREGA)
    }
  }

  return (
    <PageContainer>
      <PageText text="Qual é seu endereço?" />

      <FormAddress
        deliveryAddress={false}
        handleSendData={handleSendData}
        idScreen="enderecoResidencial"
        submitLoading={loadingOnSubmit}
      />
    </PageContainer>
  )
}

export default Endereco
