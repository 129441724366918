import React, { useContext, useEffect, useState } from 'react'

import {
  PageTitle,
  PageContainer,
  ButtonWrapper,
  Button,
  Icons,
  PageText,
  ButtonLink,
} from 'components'
import { RoutesEnum } from 'enums/routes.enum'
import { useHistory } from 'react-router-dom'
import { IFichaCadastralCtx } from 'interfaces/fichaCadastral.interface'
import { FichaCadastralCtx, PixInformationCtx } from 'App'
import { IPixInformationCtx } from 'interfaces/pixData.interface'
import { postDadosPix } from 'services/axios'
import { sendToNewRelic } from 'services'
import { CheckCircle, Help } from 'components/elements/Icons'

import { Alert, AlertType } from '@interco/inter-ui/components/Alert'
import { BottomSheet, Checkbox, Text } from '@inter/inter-ui'
import { Switch as SwitchUI } from '@interco/inter-ui/components/Switch'

import * as S from './style'
import { mountImages, pixInsuranceInfoList } from './utils'

const pixInsuranceExist = true

const ChavePix = () => {
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState<boolean>(false)
  const [isOpenFailBottomSheet, setIsOpenFailBottomSheet] = useState<boolean>(false)
  const [termsAccepted, setTermsAccepted] = useState<boolean>(true)
  const [sendingPixKeys, setSendingPixKeys] = useState<boolean>(false)
  const [pixSelectedValues, setPixSelectedValues] = useState<Record<string, boolean>>()
  const [checkedPixInsurance, setCheckedPixInsurance] = useState<boolean>(false)
  const [openBottomSheetInsurance, setOpenBottomSheetInsurance] = useState<boolean>(false)

  const { hsfc }: IFichaCadastralCtx = useContext<IFichaCadastralCtx>(FichaCadastralCtx)
  const { pixInfo }: IPixInformationCtx = useContext<IPixInformationCtx>(PixInformationCtx)
  const { registerPixView } = pixInfo

  const { bottomSheet, pixKeys } = registerPixView

  const hsfcValue = (): string => hsfc || (sessionStorage.getItem('hsfc') as string)
  const history = useHistory()

  useEffect(() => {
    const pixValuesObj = {}
    pixKeys.forEach((item) => {
      pixValuesObj[item.type] = true
    })
    setPixSelectedValues(pixValuesObj)
  }, [pixKeys])

  const handleClickPreview = () => {
    history.push(RoutesEnum.CHAVE_PIX_INFO)
  }

  const handleChangeBottomSheet = () => {
    setIsOpenBottomSheet(!isOpenBottomSheet)
  }

  const handleChangePixKey = (key) => {
    if (pixSelectedValues) {
      setPixSelectedValues({
        ...pixSelectedValues,
        [key]: !pixSelectedValues[key],
      })
    }
  }

  const confirmPixKeys = () => {
    setSendingPixKeys(true)

    const objectToSend = {
      ...pixSelectedValues,
      confirmPortability: termsAccepted,
      requestedInsurance: pixInsuranceExist ? checkedPixInsurance : false,
    }

    postDadosPix({
      header: { hsfc: hsfcValue() },
      data: objectToSend,
      success: () => {
        sendToNewRelic('PIX', 'Pix - Sucesso', {
          ...objectToSend,
          FICHA_CADASTRAL: hsfcValue(),
        })
        history.push(RoutesEnum.CODIGO_INDICACAO)
      },
      error: () => {
        setIsOpenFailBottomSheet(true)
      },
      finally: () => {
        setSendingPixKeys(false)
      },
    })
  }

  const defiineAlertType = (type: 'attention' | 'info' | 'error'): AlertType => {
    switch (type) {
      case 'attention':
        return 'warning'
      case 'error':
        return 'error'
      case 'info':
        return 'grayscale'
      default:
        return 'grayscale'
    }
  }

  useEffect(() => {
    sendToNewRelic('PIX', '[Screen] - CadastrarChaves', {
      FICHA_CADASTRAL: hsfcValue(),
    })
  }, [])

  const finishAbertura = () => {
    sendToNewRelic('PIX', '[Exit] - Cadastrar depois', {
      FICHA_CADASTRAL: hsfcValue(),
    })

    history.push(RoutesEnum.CODIGO_INDICACAO)
  }

  return (
    <>
      <PageContainer>
        <PageTitle onClickPreview={handleClickPreview} />

        <S.PageTitle>
          <PageText text="Cadastrar chave Pix" margin="0px" />
          <Icons variant={Help} onClick={handleChangeBottomSheet} />
        </S.PageTitle>

        <S.ContentWrapper gap="16px" mt="0px">
          {pixKeys.map((pixKey) => (
            <S.SwitchWrapper key={pixKey.title}>
              <S.ContentWrapper>
                <Text variant="body-3" bold colorWeight={500}>
                  {pixKey.title}
                </Text>
                {pixKey.value && <Text variant="caption-1">{pixKey.value}</Text>}
              </S.ContentWrapper>

              <SwitchUI
                id={pixKey.type}
                value={pixKey.value}
                checked={pixSelectedValues ? pixSelectedValues[pixKey.type] : false}
                onChange={() => handleChangePixKey(pixKey.type)}
              />
            </S.SwitchWrapper>
          ))}
        </S.ContentWrapper>

        <ButtonWrapper style={{ paddingTop: '20px', marginTop: '30px' }}>
          <Alert type={defiineAlertType('info')} icon="warning">
            <Text variant="caption-1" colorWeight={500}>
              Se sua chave Pix já for cadastrada em outra instituição, é preciso autorizar sua
              portabilidade e reivindicação para o Inter.
            </Text>
          </Alert>

          {pixInsuranceExist && (
            <S.InsuranceWrapper
              style={{
                border: checkedPixInsurance ? '1px solid var(--primary400)' : '1px solid #DEDFE4',
              }}
            >
              <S.InsuranceContent>
                <S.IconPixWrapper>
                  <Icons variant={mountImages('transfer-money')} />
                </S.IconPixWrapper>

                <S.ContentWrapper>
                  <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
                    <Text variant="body-3" bold colorWeight={500} style={{ marginRight: '8px' }}>
                      Seguro Pix
                    </Text>

                    <S.TagStyled color="var(--success)" colorText="white" size="small">
                      <div style={{ marginBottom: '2px' }}>1º mês grátis</div>
                    </S.TagStyled>
                  </div>

                  <Text variant="caption-1">R$ 3,50 a partir do 2º mês.</Text>
                </S.ContentWrapper>

                <S.InsuranceSwitch>
                  <SwitchUI
                    id="simple"
                    checked={checkedPixInsurance}
                    onChange={() => {
                      setCheckedPixInsurance(!checkedPixInsurance)
                      sendToNewRelic('PIX', checkedPixInsurance ? 'SPix - Off' : 'SPix - On', {
                        FICHA_CADASTRAL: hsfcValue(),
                      })
                    }}
                  />
                </S.InsuranceSwitch>
              </S.InsuranceContent>

              {checkedPixInsurance ? (
                <div
                  style={{
                    backgroundColor: 'var(--gray100)',
                    padding: '16px',
                    borderRadius: '8px',
                  }}
                >
                  <Icons
                    variant={CheckCircle}
                    onClick={handleChangeBottomSheet}
                    color="var(--success500)"
                    width={16}
                    height={16}
                  />
                  <Text variant="caption-1" style={{ padding: '0 16px 16px 16px' }}>
                    Seu Pix estará seguro em até R$ 2.000,00
                  </Text>
                </div>
              ) : (
                <div
                  style={{
                    display: 'inline-grid',
                    justifyItems: 'left',
                    padding: '0 16px 16px 16px',
                  }}
                >
                  <Text variant="caption-1">Seu pix seguro até se seu celular for roubado.</Text>

                  <ButtonLink
                    style={{ marginTop: '8px' }}
                    variant="link"
                    onClick={() => setOpenBottomSheetInsurance(true)}
                    fontWeight="700"
                  >
                    Saiba mais
                  </ButtonLink>
                </div>
              )}
            </S.InsuranceWrapper>
          )}

          <S.CheckboxWrapper>
            <Checkbox
              id="checkbox-terms"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              label={
                checkedPixInsurance ? (
                  <Text variant="caption-1" colorWeight={500}>
                    Autorizo trazer minha chave para o Inter e proteger minhas transações com o
                    Seguro Pix
                  </Text>
                ) : (
                  <Text variant="caption-1" colorWeight={500}>
                    Autorizo trazer minha chave para o Inter
                  </Text>
                )
              }
              reversed
            />
          </S.CheckboxWrapper>

          <Button
            id="submit"
            onClick={confirmPixKeys}
            disabled={pixSelectedValues && !Object.values(pixSelectedValues).includes(true)}
            isLoading={sendingPixKeys}
          >
            Cadastrar chaves
          </Button>

          <Button id="pixInfo" variant="secondary" onClick={finishAbertura}>
            Cadastrar depois
          </Button>
        </ButtonWrapper>

        {isOpenBottomSheet && (
          <BottomSheet onClose={handleChangeBottomSheet}>
            <S.ContentWrapper gap="8px" mb="24px">
              <Text variant="headline-h3" as="h3">
                Cadastrar chave Pix
              </Text>
              <Text variant="body-3" as="p">
                Cadastre para pagar e receber de forma simples e rápida. Quem te pagar através
                dessas chaves verá alguns dos seus dados:
              </Text>

              {bottomSheet.specifications.map((item) => (
                <S.ItemWrapper key={item.title}>
                  <S.IconWrapper>
                    <Icons variant={mountImages(item.icon)} color="var(--gray500)" />
                  </S.IconWrapper>

                  <S.ContentWrapper>
                    <Text variant="body-3" colorWeight={500}>
                      {item.title}
                    </Text>
                    <Text variant="body-3" bold colorWeight={500}>
                      {item.value}
                    </Text>
                  </S.ContentWrapper>
                </S.ItemWrapper>
              ))}
            </S.ContentWrapper>

            <ButtonWrapper>
              <Button data-anim="ripple" onClick={handleChangeBottomSheet}>
                Entendi
              </Button>
            </ButtonWrapper>
          </BottomSheet>
        )}

        {isOpenFailBottomSheet && (
          <BottomSheet onClose={() => setIsOpenFailBottomSheet(false)}>
            <S.BottomSheetContentWrapper>
              <Text variant="headline-h3">Ocorreu um erro</Text>
              <Text variant="body-3">
                Houve um erro ao cadastrar a chave PIX. Verifique os dados e tente novamente.
              </Text>

              <Button onClick={() => setIsOpenFailBottomSheet(false)} style={{ width: '100%' }}>
                Entendi
              </Button>
            </S.BottomSheetContentWrapper>
          </BottomSheet>
        )}

        {openBottomSheetInsurance && (
          <BottomSheet onClose={() => setOpenBottomSheetInsurance(false)}>
            <S.InsuranceBottomSheetContent>
              <Text variant="body-2" bold colorWeight={500}>
                Proteção adicional em casos de:
              </Text>

              <S.ContentWrapper gap="16px" mt="0px">
                {pixInsuranceInfoList.map((item) => (
                  <S.InsuranceBottomSheetList key={item.text}>
                    <S.ContentWrapper>
                      <Text variant="body-3">{item.text}</Text>
                    </S.ContentWrapper>

                    <S.ContentWrapper>
                      <Text variant="body-3" bold colorWeight={500} style={{ marginLeft: 'auto' }}>
                        {item.value}
                      </Text>
                    </S.ContentWrapper>
                  </S.InsuranceBottomSheetList>
                ))}
              </S.ContentWrapper>

              <Alert type={defiineAlertType('info')} icon="warning">
                <Text variant="caption-1">
                  Válido para transações ou compras feitas no Super App por Pix, TED e TEF.
                </Text>
              </Alert>

              <Button onClick={() => setOpenBottomSheetInsurance(false)}>Entendi</Button>
            </S.InsuranceBottomSheetContent>
          </BottomSheet>
        )}
      </PageContainer>
    </>
  )
}

export default ChavePix
