type TData = {
  [key: string]: string
}

export const AuthorizationHeader: TData = {
  'x-id-ficha-cadastral': 'baaf28503211a8d0ea223cf0e4751a5f',
  'x-inter-mac-address': '83-0E-5D-78-40-45',
  'mag-identifier': 'tt',
  'x-inter-app-registration-id': 'tt',
  'x-inter-app-origem': 'tt',
  'x-inter-app-version': 'tt',
  'x-inter-ip': 'tt',
  'geo-location': 'tt',
  'x-inter-so': 'tt',
  'x-inter-device': 'tt',
  'Content-Type': 'application/json',
}
