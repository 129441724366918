import { BaseServices, EHttpMethod } from 'services/base'
import { urls } from 'services/base/url'

import { TRequestSendGlobalPayload } from './types'

const request = (payload: TRequestSendGlobalPayload) =>
  BaseServices.request({
    pathname: urls.PUT_CONTA_GLOBAL,
    method: EHttpMethod.PUT,
    host: process.env.REACT_APP_API_HOST_CONTA_DIGITAL,
    data: { ...payload.data },
    headers: { 'x-id-ficha-cadastral': payload.header.hsfc },
  })

export const putContaGlobal = async (payload: TRequestSendGlobalPayload) => {
  try {
    const response = await request(payload)

    payload.success && payload.success(response.status)
  } catch (error) {
    payload.error && payload.error(error)
  } finally {
    payload.finally && payload.finally()
  }
}
