import * as yup from 'yup'
import { MIN_PASSWORD_LENGTH } from 'pages/criarSenha'
import { TVariations } from 'shared/functions/StrongerPassword'

export const yupValidacaoCriarSenha = (variations: TVariations) =>
  yup.object().shape({
    senha: yup
      .string()
      .required('Informe sua senha')
      .test('hasUpper', 'Informe pelo menos 1 letra maiúscula', () => variations.upper)
      .test('hasLower', 'Informe pelo menos 1 letra minúscula', () => variations.lower)
      .test('hasDigit', 'Informe pelo menos 1 número', () => variations.digits)
      .test(
        'hasNonWords',
        'Informe pelo menos 1 caractere especial (ex: *, #, $)',
        () => variations.nonWords,
      )
      .min(MIN_PASSWORD_LENGTH, `Informe uma senha com no mínimo ${MIN_PASSWORD_LENGTH} dígitos`)
      .test('sequence', 'Não use sequenciais (ex: abc ou 123)', () => !variations.sequence)
      .test(
        'identicalSequence',
        'Não use caracteres idênticos (ex: aaa ou 111)',
        () => !variations.identicalSequence,
      ),
    confSenha: yup
      .string()
      .required('Confirme sua senha')
      .oneOf([yup.ref('senha'), null], 'Repita a mesma senha'),
  })
