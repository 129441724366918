import { useEffect, useState } from 'react'

import { Button, Icons, ButtonLink, Text, BottomSheet } from 'components'
import { CheckCircle } from 'components/elements/Icons'

import { Switch as SwitchUI } from '@interco/inter-ui/components/Switch'
import { Alert } from '@interco/inter-ui/components/Alert'

import * as S from './style'
import { getIcons, protectedCardBottomSheetInfo, protectedCardInfo } from './utils'
import {
  InsuranceCrossSellingCardBottomSheetInfo,
  InsuranceCrossSellingCardProps,
  InsuranceCrossSellingCardSwitchInfo,
  InsuranceType,
} from './types'

const InsuranceCrossSellingCard = ({ insuranceType, callback }: InsuranceCrossSellingCardProps) => {
  const [checkedInsurance, setCheckedInsurance] = useState<boolean>(false)
  const [openBottomSheetInsurance, setOpenBottomSheetInsurance] = useState<boolean>(false)
  const [bottomSheetInfo, setBottomSheetInfo] = useState<InsuranceCrossSellingCardBottomSheetInfo>()
  const [insuranceCrossSellingCard, setInsuranceCrossSellingCard] =
    useState<InsuranceCrossSellingCardSwitchInfo>()

  const getInsuranceContent = () => {
    if (insuranceType === InsuranceType.ProtectedCard) {
      setBottomSheetInfo(protectedCardBottomSheetInfo)
      setInsuranceCrossSellingCard(protectedCardInfo)
    } else {
      console.warn('InsuranceType not supported')
    }
  }

  useEffect(() => {
    getInsuranceContent()
  })

  return (
    <>
      <S.InsuranceWrapper
        style={{
          border: checkedInsurance ? '1px solid var(--primary400)' : '1px solid #DEDFE4',
        }}
      >
        <S.InsuranceContent>
          <S.IconWrapper>
            {insuranceCrossSellingCard && (
              <Icons variant={getIcons(insuranceCrossSellingCard?.switchCardIcon)} />
            )}
          </S.IconWrapper>

          <S.ContentWrapper>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
              <S.TagStyled color="var(--success)" colorText="white" size="small">
                <div style={{ marginBottom: '2px' }}>
                  {insuranceCrossSellingCard?.switchTag.label}
                </div>
              </S.TagStyled>
              <Text variant="body-3" bold colorWeight={500} style={{ marginRight: '8px' }}>
                {insuranceCrossSellingCard?.switchCardTitle}
              </Text>
            </div>

            <Text variant="caption-1">{insuranceCrossSellingCard?.switchCardSubTitle}</Text>
          </S.ContentWrapper>

          <S.InsuranceSwitch>
            <SwitchUI
              id="simple"
              checked={checkedInsurance}
              onChange={() => {
                setCheckedInsurance(!checkedInsurance)
                callback(!checkedInsurance)
              }}
            />
          </S.InsuranceSwitch>
        </S.InsuranceContent>

        {checkedInsurance ? (
          <div
            style={{
              backgroundColor: 'var(--gray100)',
              padding: '15px 16px 16px 16px',
              borderRadius: '0 0 8px 8px',
            }}
          >
            <Icons
              variant={CheckCircle}
              onClick={() => {
                setOpenBottomSheetInsurance(!openBottomSheetInsurance)
              }}
              color="var(--success500)"
              width={16}
              height={16}
            />
            <Text variant="caption-1" style={{ padding: '0 16px 16px 16px' }}>
              {insuranceCrossSellingCard?.switchCardMsg}
            </Text>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyItems: 'left',
              flexFlow: 'column',
              alignItems: 'flex-start',
              padding: '0 16px 16px 16px',
            }}
          >
            <Text variant="caption-1">{insuranceCrossSellingCard?.switchCardMsgOff}</Text>

            <ButtonLink
              style={{ marginTop: '5.72px' }}
              variant="link"
              onClick={() => setOpenBottomSheetInsurance(true)}
              fontWeight="700"
            >
              {insuranceCrossSellingCard?.switchCardInfoButtonLabel}
            </ButtonLink>
          </div>
        )}
      </S.InsuranceWrapper>
      {openBottomSheetInsurance && (
        <BottomSheet onClose={() => setOpenBottomSheetInsurance(false)}>
          <S.InsuranceBottomSheetContent>
            <Text variant="body-2" bold colorWeight={500}>
              {bottomSheetInfo?.bottomSheetTitle}
            </Text>

            <S.ContentWrapper gap="16px" mt="0px">
              {bottomSheetInfo?.bottomSheetCoverageList.map((item) => (
                <S.InsuranceBottomSheetList key={item.label}>
                  <S.ContentWrapper>
                    <Text variant="body-3">{item.label}</Text>
                  </S.ContentWrapper>

                  <S.ContentWrapper>
                    <Text variant="body-3" bold colorWeight={500} style={{ marginLeft: 'auto' }}>
                      {item.value}
                    </Text>
                  </S.ContentWrapper>
                </S.InsuranceBottomSheetList>
              ))}
            </S.ContentWrapper>
            {bottomSheetInfo?.bottomSheetAlertMessage && (
              <Alert type="grayscale" icon="warning">
                <Text variant="caption-1">{bottomSheetInfo?.bottomSheetAlertMessage}</Text>
              </Alert>
            )}

            <Button onClick={() => setOpenBottomSheetInsurance(false)}>
              {bottomSheetInfo?.bottomSheetButtonLabel}
            </Button>
          </S.InsuranceBottomSheetContent>
        </BottomSheet>
      )}
    </>
  )
}

export default InsuranceCrossSellingCard
