type TData = {
  [key: string]: string | number | boolean
}

interface IRequest<T = TData> {
  url: string
  method?: string
  body?: T | string[]
  headers?: Record<string, string>
  timeout?: number
}

export const request = <T = TData>({
  url,
  method = 'GET',
  body,
  headers = {},
  timeout = 30000,
}: IRequest<T>) => {
  const xhr = new XMLHttpRequest()
  const promise = new Promise((resolve, reject) => {
    xhr.open(method, url)
    xhr.timeout = timeout

    Object.keys(headers).forEach((key) => {
      xhr.setRequestHeader(key, headers[key])
    })

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          let { response } = xhr
          if (response) response = JSON.parse(response)
          resolve(response)
        } catch (error) {
          reject(xhr)
        }
      } else reject(xhr)
    }

    xhr.onerror = xhr.onabort
    xhr.onabort = xhr.ontimeout
    xhr.ontimeout = () => reject(xhr)

    let data

    if (
      headers.hasOwnProperty.call(headers, 'Content-Type') &&
      headers['Content-Type'] === 'multipart/form-data'
    ) {
      const formData = new FormData()
      formData.append('', JSON.stringify(body))
      data = formData
    } else {
      data = body ? JSON.stringify(body) : null
      if (
        headers.hasOwnProperty.call(headers, 'Content-Type') &&
        headers['Content-Type'] === 'application/x-www-form-urlencoded' &&
        body
      ) {
        data = Object.keys(body).reduce((acc: string[], item: string) => {
          acc.push(`${item}=${encodeURIComponent(body[item])}`)
          return acc
        }, [])
        data = data.join('&')
      }
    }

    xhr.send(data)
  })

  return promise
}
