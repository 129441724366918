/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useContext, useEffect, useState } from 'react'

import { PageContainer, ButtonWrapper, Button } from 'components'
import { TrackEventEnum } from 'interfaces/trackEvent.interface'
import { useHistory } from 'react-router-dom'
import { RoutesEnum } from 'enums/routes.enum'
import { FichaCadastralCtx } from 'App'
import { IFichaCadastralCtx } from 'interfaces/fichaCadastral.interface'
import { sendToNewRelic } from 'services'
import { useTrackEvent } from 'hooks'
import url from 'shared/functions/url'

import { BottomSheet, Text, Carousel } from '@inter/inter-ui'

import { CarouselWrapper } from './style'
import { contaMeiLabels, slidersAccountInfo } from './constants'

const ContaMei = () => {
  const [isOpenFeedbackModal, setIsOpenFeedbackModal] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean | undefined>(undefined)
  const [bottomSheetContent, setBottomSheetContent] = useState<ReactElement>(<></>)

  const { hsfc }: IFichaCadastralCtx = useContext<IFichaCadastralCtx>(FichaCadastralCtx)

  const history = useHistory()
  const sendTrackEventData = useTrackEvent()

  const failBottomSheet = () => (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h3>{contaMeiLabels.SENT_FAILT}</h3>
        <p className="mb-4">Você pode tentar novamente agora ou em alguns instantes.</p>
      </div>

      <ButtonWrapper>
        <Button
          type="submit"
          onClick={() => {
            handleClickInserirCodigo()
            setIsOpenFeedbackModal(() => false)
            setHasError(undefined)
          }}
        >
          Tentar novamente
        </Button>

        <Button variant="secondary" type="submit" onClick={handleClickAgoraNao}>
          Agora não
        </Button>
      </ButtonWrapper>
    </>
  )

  useEffect(() => {
    if (isOpenFeedbackModal) {
      sendTrackEventData(TrackEventEnum.GERAL_BOTTOMSHEET, {
        content_id: contaMeiLabels.PAGE_NAME,
        content_name: hasError ? contaMeiLabels.SENT_FAILT : contaMeiLabels.SENT_SUCCESS,
      })
    }
  }, [isOpenFeedbackModal])

  const handleClickInserirCodigo = () => {
    sendTrackEventData(TrackEventEnum.GERAL_BOTAO, {
      content_id: contaMeiLabels.PAGE_NAME,
      content_name: contaMeiLabels.OPEN_MEI_ACCOUNT,
    })

    history.push(RoutesEnum.CONTA_MEI_INFOS)
  }

  const handleClickAgoraNao = () => {
    sendTrackEventData(TrackEventEnum.GERAL_BOTAO, {
      content_id: contaMeiLabels.PAGE_NAME,
      content_name: contaMeiLabels.NOT_OPEN_ACCOUNT,
    })
    history.push(RoutesEnum.HOME_POS_ABERTURA)
  }

  const handleChangeCarousel = (_: number, nextSlide: number) => {
    sendTrackEventData(TrackEventEnum.GERAL_SLIDER, {
      content_id: contaMeiLabels.PAGE_NAME,
      content_name: slidersAccountInfo[nextSlide].title,
    })
  }

  useEffect(() => {
    const error = url.queryParam().sendError as string
    if (error) {
      setBottomSheetContent(failBottomSheet)
      setIsOpenFeedbackModal(true)
    }
  }, [])

  const Slider = () => (
    <>
      <CarouselWrapper>
        <Carousel
          dots
          slidesToShow={1}
          dotsType="big"
          verticalMode={false}
          arrows={false}
          beforeChange={(currentSlide: number, nextSlide: number) =>
            handleChangeCarousel(currentSlide, nextSlide)
          }
        >
          {slidersAccountInfo.map((slide) => (
            <span key={slide.title}>
              <img src={slide.img} alt={slide.title} width="100%" />

              <div style={{ margin: '24px 24px 0' }}>
                <h1>{slide.title}</h1>
                <Text variant="body-3" as="p" style={{ minHeight: '30px' }}>
                  {slide.contents}
                </Text>
              </div>
            </span>
          ))}
        </Carousel>
      </CarouselWrapper>
    </>
  )

  useEffect(() => {
    sendToNewRelic('POS_ABERTURA', '[Screen] - ContaMei', {
      FICHA_CADASTRAL: hsfc,
    })
  }, [])

  return (
    <PageContainer>
      <Slider />

      <ButtonWrapper>
        <Button onClick={handleClickInserirCodigo}>{contaMeiLabels.OPEN_MEI_ACCOUNT}</Button>
        <Button variant="secondary" onClick={handleClickAgoraNao}>
          {contaMeiLabels.NOT_OPEN_ACCOUNT}
        </Button>
      </ButtonWrapper>

      {isOpenFeedbackModal && (
        <BottomSheet onClose={() => setIsOpenFeedbackModal(false)}>
          {bottomSheetContent}
        </BottomSheet>
      )}
    </PageContainer>
  )
}

export default ContaMei
