import React, { useState, useEffect } from 'react'

import { HashRouter as Router, Route } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import DadosPessoaisEstadoCivil from 'pages/dadosPessoais/estadoCivil'
import Pendenciamento from 'pages/pendenciamento'
import PendenciamentoAprovado from 'pages/pendenciamento/pendenciamentoAprovado'
import PendenciamentoReprovado from 'pages/pendenciamento/pendenciamentoReprovado'
import ContaBasicaAberta from 'pages/pendenciamento/contaBasicaAberta'
import InativarFicha from 'pages/pendenciamento/inativarFicha'
import PendenciasRefazer from 'pages/pendenciasRefazer'
import PinSMS from 'pages/pinSMS'
import PinEmail from 'pages/pinEmail'
import ReiniciarFicha from 'pages/reiniciarFicha'
import Endereco from 'pages/enderecoResidencial'
import DadosPessoaisContato from 'pages/dadosPessoais/contato'
import InformacoesPix from 'pages/chavePix/informacoesPix'
import ChavePix from 'pages/chavePix'
import IndicarAmigos from 'pages/indicarAmigos'
import CodigoIndicacao from 'pages/codigoIndicacao'
import HomePosAbertura from 'pages/homePosAbertura'
import ContaMei from 'pages/contaMei'
import ContaMeiInformacoesEmpresa from 'pages/contaMei/informacoesEmpresa'
import ContaGlobal from 'pages/contaGlobal'
import InterLoop from 'pages/interLoop'
import { IPendenciasRefazer } from 'interfaces/pendenciasRefazer.interface'
import { ApiResponse, IApiResponseCtx } from 'interfaces/response.interface'
import { IEnderecos } from 'interfaces/enderecos.interface'
import url from 'shared/functions/url'
import { IDadosPessoais, IDadosPessoaisCtx, ILegalAgeCtx } from 'interfaces/dadosPessoais.interface'
import { IFichaCadastralCtx } from 'interfaces/fichaCadastral.interface'
import { IPosAberturaCtx, TAberturaOptions } from 'interfaces/posAbertura.interface'
import { IPixInformationCtx, TPixInformation } from 'interfaces/pixData.interface'
import { IInterLoopCtx } from 'interfaces/loop.interface'
import { posAberturaItensDefault } from 'pages/homePosAbertura/constants'
import FimOnboarding from 'pages/fimOnboarding'

import { initTheme, Theme, ModalProvider } from '@inter/inter-ui'

import CriarSenha from './pages/criarSenha'
import DadosProfissionais from './pages/dadosProfissionais'
import EscolhaCartao from './pages/escolhaCartao'
import EnderecoEntrega from './pages/enderecoEntrega'
import FinalizarPF from './pages/finalizarPF'
import Naturalidade from './pages/naturalidade'
import { RoutesEnum } from './enums/routes.enum'

import '@inter/inter-ui/dist/inter-ui.css'

export const DadosPessoaisCtx = React.createContext<IDadosPessoaisCtx>({} as IDadosPessoaisCtx)
export const ApiResponseCtx = React.createContext<IApiResponseCtx<unknown>>(
  {} as IApiResponseCtx<IPendenciasRefazer>,
)
export const LegalAgeCtx = React.createContext<ILegalAgeCtx>({} as ILegalAgeCtx)
export const FichaCadastralCtx = React.createContext<IFichaCadastralCtx>({} as IFichaCadastralCtx)
export const PixInformationCtx = React.createContext<IPixInformationCtx>({} as IPixInformationCtx)
export const InterLoopCtx = React.createContext<IInterLoopCtx>({} as IInterLoopCtx)
export const PosAberturaCtx = React.createContext<IPosAberturaCtx>({} as IPosAberturaCtx)

const App: React.FC = () => {
  const emptyAddress = {} as IEnderecos

  const scrollTop = () => window.scrollTo(0, 0)
  const [apiResponse, setApiResponse] = useState<ApiResponse<unknown>>({
    dados: {
      fraseTelaRefazerItens:
        'Precisamos que você reenvie um ou mais documentos, combinado? No próximo passo, te mostraremos quais são.',
    },
    telas: {
      flow: 'FLOW',
      stepList: [],
    },
  })
  const [dadosPessoais, setDadosPessoais] = useState<IDadosPessoais>({})
  const [endereco, setEndereco] = useState<IEnderecos>(emptyAddress)
  const [isMinor, setIsMinor] = useState<boolean>(false)
  const [isUnderFourteen, setIsUnderFourteen] = useState<boolean>(false)
  const [hsfc, setHsfc] = useState<string>('')
  const [posAberturaOptions, setPosAberturaOptions] =
    useState<TAberturaOptions>(posAberturaItensDefault)
  const [basicAccountCreated, setBasicAccountCreated] = useState<boolean>(false)
  const [termLink, setTermLink] = useState<string>('')
  const [termId, setTermId] = useState<number>(0)
  const [termType, setTermType] = useState<string>('')

  const [pixInfo, setPixInfo] = useState<TPixInformation>({} as TPixInformation)

  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  const queries = url.queryParam()
  useEffect(() => {
    localStorage.removeItem('isMinor')
    setIsMinor(url.queryParam().isMinor === 'true')
    localStorage.setItem('isMinor', url.queryParam().isMinor)
  }, [queries])

  return (
    <ModalProvider>
      <LegalAgeCtx.Provider value={{ isMinor, isUnderFourteen, setIsUnderFourteen }}>
        <Router>
          <div className="container">
            <Route
              exact
              path={RoutesEnum.STEP_DADOS_PESSOAIS}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <DadosPessoaisCtx.Provider value={{ dadosPessoais, setDadosPessoais }}>
                    {isMinor ? (
                      <ApiResponseCtx.Provider value={{ apiResponse, setApiResponse }}>
                        <DadosPessoaisContato />
                      </ApiResponseCtx.Provider>
                    ) : (
                      <DadosPessoaisEstadoCivil />
                    )}
                  </DadosPessoaisCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              exact
              path={RoutesEnum.DADOS_PESSOAIS_ESTADOCIVIL}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <DadosPessoaisCtx.Provider value={{ dadosPessoais, setDadosPessoais }}>
                    <DadosPessoaisEstadoCivil />
                  </DadosPessoaisCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              exact
              path={RoutesEnum.DADOS_PESSOAIS_CONTATO}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <DadosPessoaisCtx.Provider value={{ dadosPessoais, setDadosPessoais }}>
                    <ApiResponseCtx.Provider value={{ apiResponse, setApiResponse }}>
                      <DadosPessoaisContato />
                    </ApiResponseCtx.Provider>
                  </DadosPessoaisCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              exact
              path={RoutesEnum.STEP_VALIDACAO_SMS}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <DadosPessoaisCtx.Provider value={{ dadosPessoais, setDadosPessoais }}>
                    <PinSMS />
                  </DadosPessoaisCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              exact
              path={RoutesEnum.STEP_VALIDACAO_EMAIL}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <DadosPessoaisCtx.Provider value={{ dadosPessoais, setDadosPessoais }}>
                    <PinEmail />
                  </DadosPessoaisCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.STEP_NATURALIDADE}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <Naturalidade />
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.STEP_ESCOLHA_CARTAO}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <EscolhaCartao />
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.STEP_ENDERECO_RESIDENCIAL}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <Endereco endereco={endereco} setEndereco={setEndereco} />
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.ENDERECO_ENTREGA}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <EnderecoEntrega endereco={endereco} setEndereco={setEndereco} />
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.STEP_DADOS_PROFISSIONAIS}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <ApiResponseCtx.Provider value={{ apiResponse, setApiResponse }}>
                    <DadosProfissionais />
                  </ApiResponseCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.STEP_SENHA}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <ApiResponseCtx.Provider value={{ apiResponse, setApiResponse }}>
                    <FichaCadastralCtx.Provider
                      value={{ hsfc, setHsfc, basicAccountCreated, setBasicAccountCreated }}
                    >
                      <CriarSenha />
                    </FichaCadastralCtx.Provider>
                  </ApiResponseCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.STEP_EM_ANALISE}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <DadosPessoaisCtx.Provider value={{ dadosPessoais, setDadosPessoais }}>
                    <FichaCadastralCtx.Provider
                      value={{ hsfc, setHsfc, basicAccountCreated, setBasicAccountCreated }}
                    >
                      <PixInformationCtx.Provider value={{ pixInfo, setPixInfo }}>
                        <FinalizarPF />
                      </PixInformationCtx.Provider>
                    </FichaCadastralCtx.Provider>
                  </DadosPessoaisCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.PENDENCIAMENTO}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <Pendenciamento />
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.STEP_CONTA_ABERTA}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <PendenciamentoAprovado />
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.STEP_CONTA_BASICA}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <ContaBasicaAberta />
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.STEP_CONTA_NEGADA}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <PendenciamentoReprovado />
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.AVISO_REFAZER_ITENS}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <ApiResponseCtx.Provider value={{ apiResponse, setApiResponse }}>
                    <PendenciasRefazer />
                  </ApiResponseCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.STEP_REINICIAR_FICHA}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <InativarFicha />
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.STEP_REVALIDAR_EMAIL}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <ApiResponseCtx.Provider value={{ apiResponse, setApiResponse }}>
                    <ReiniciarFicha />
                  </ApiResponseCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.CHAVE_PIX_INFO}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <FichaCadastralCtx.Provider
                    value={{ hsfc, setHsfc, basicAccountCreated, setBasicAccountCreated }}
                  >
                    <PixInformationCtx.Provider value={{ pixInfo, setPixInfo }}>
                      <InformacoesPix />
                    </PixInformationCtx.Provider>
                  </FichaCadastralCtx.Provider>
                </CSSTransition>
              )}
            </Route>

            <Route
              path={RoutesEnum.CHAVE_PIX}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <FichaCadastralCtx.Provider
                    value={{ hsfc, setHsfc, basicAccountCreated, setBasicAccountCreated }}
                  >
                    <PixInformationCtx.Provider value={{ pixInfo, setPixInfo }}>
                      <ChavePix />
                    </PixInformationCtx.Provider>
                  </FichaCadastralCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.INDICAR_AMIGOS}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <IndicarAmigos />
                </CSSTransition>
              )}
            </Route>

            <Route
              path={RoutesEnum.CODIGO_INDICACAO}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <FichaCadastralCtx.Provider
                    value={{ hsfc, setHsfc, basicAccountCreated, setBasicAccountCreated }}
                  >
                    <PosAberturaCtx.Provider
                      value={{
                        posAberturaOptions,
                        setPosAberturaOptions,
                      }}
                    >
                      <InterLoopCtx.Provider
                        value={{
                          termLink,
                          termId,
                          termType,
                          setTermLink,
                          setTermId,
                          setTermType,
                        }}
                      >
                        <CodigoIndicacao />
                      </InterLoopCtx.Provider>
                    </PosAberturaCtx.Provider>
                  </FichaCadastralCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.HOME_POS_ABERTURA}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <FichaCadastralCtx.Provider
                    value={{ hsfc, setHsfc, basicAccountCreated, setBasicAccountCreated }}
                  >
                    <PosAberturaCtx.Provider value={{ posAberturaOptions, setPosAberturaOptions }}>
                      <InterLoopCtx.Provider
                        value={{
                          termLink,
                          termId,
                          termType,
                          setTermLink,
                          setTermId,
                          setTermType,
                        }}
                      >
                        <HomePosAbertura />
                      </InterLoopCtx.Provider>
                    </PosAberturaCtx.Provider>
                  </FichaCadastralCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.FIM_ONBOARDING}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <FichaCadastralCtx.Provider
                    value={{ hsfc, setHsfc, basicAccountCreated, setBasicAccountCreated }}
                  >
                    <FimOnboarding />
                  </FichaCadastralCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.CONTA_MEI}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <FichaCadastralCtx.Provider
                    value={{ hsfc, setHsfc, basicAccountCreated, setBasicAccountCreated }}
                  >
                    <PosAberturaCtx.Provider
                      value={{
                        posAberturaOptions,
                        setPosAberturaOptions,
                      }}
                    >
                      <ContaMei />
                    </PosAberturaCtx.Provider>
                  </FichaCadastralCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.CONTA_MEI_INFOS}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <FichaCadastralCtx.Provider
                    value={{ hsfc, setHsfc, basicAccountCreated, setBasicAccountCreated }}
                  >
                    <PosAberturaCtx.Provider
                      value={{
                        posAberturaOptions,
                        setPosAberturaOptions,
                      }}
                    >
                      <ContaMeiInformacoesEmpresa />
                    </PosAberturaCtx.Provider>
                  </FichaCadastralCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.CONTA_GLOBAL}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <PosAberturaCtx.Provider
                    value={{
                      posAberturaOptions,
                      setPosAberturaOptions,
                    }}
                  >
                    <ContaGlobal />
                  </PosAberturaCtx.Provider>
                </CSSTransition>
              )}
            </Route>
            <Route
              path={RoutesEnum.INTER_LOOP}
              render={() => {
                scrollTop()
                return null
              }}
            >
              {({ match }: string) => (
                <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                  <PosAberturaCtx.Provider
                    value={{
                      posAberturaOptions,
                      setPosAberturaOptions,
                    }}
                  >
                    <InterLoopCtx.Provider
                      value={{
                        termLink,
                        termId,
                        termType,
                        setTermLink,
                        setTermId,
                        setTermType,
                      }}
                    >
                      <InterLoop />
                    </InterLoopCtx.Provider>
                  </PosAberturaCtx.Provider>
                </CSSTransition>
              )}
            </Route>
          </div>
        </Router>
      </LegalAgeCtx.Provider>
    </ModalProvider>
  )
}

export default App
