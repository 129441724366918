import React, { ReactNode } from 'react'

import { Yellow } from 'assets/styled-component/variables'

import { Icon } from '@inter/inter-ui'

import { AlertWrapper, AlertTitle, AlertContent } from './style'

type TAlert = {
  children: ReactNode
  title?: string
}

export const Alert: React.FC<TAlert> = ({ title, children }: TAlert) => (
  <AlertWrapper>
    <span>
      <Icon name="warning" size={15} color={Yellow[400]} />
    </span>
    <AlertContent>
      {title && <AlertTitle>{title}</AlertTitle>}

      {children}
    </AlertContent>
  </AlertWrapper>
)

Alert.defaultProps = {
  title: undefined,
}
