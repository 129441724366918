import React, { useContext, useState } from 'react'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { PageContainer } from 'components'
import { sendDataNative } from 'components/hooks/useFetchNative'
import { TrackEventEnum } from 'interfaces/trackEvent.interface'
import { useTrackEvent } from 'hooks'
import { sendToNewRelic } from 'services'
import Title from 'components/layout/Terms/title'
import Footer from 'components/layout/Terms/footer'
import { InterLoopCtx } from 'App'
import { IInterLoopCtx } from 'interfaces/loop.interface'

import * as S from './style'

type TTermosProps = {
  onConfirmClick: () => void
  onBackClick: () => void
  loading: boolean
  hsfcValue: string
}
type TS3Object = { linkS3: string }

export const Termos = ({ onConfirmClick, onBackClick, loading, hsfcValue }: TTermosProps) => {
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)

  const { termLink }: IInterLoopCtx = useContext<IInterLoopCtx>(InterLoopCtx)

  const sendTrackEventData = useTrackEvent()

  const INTER_LOOP_TERMS = termLink

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages)
  }

  const handleClickMore = () => {
    setPageNumber(pageNumber < numPages ? pageNumber + 1 : numPages)
  }
  const handleClickLess = () => {
    setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1)
  }

  const handlePageBack = () => {
    sendTrackEventData(TrackEventEnum.GERAL_HEADER, {
      content_id: 'Inter Lopp',
      content_name: 'Voltar',
    })
    sendToNewRelic('POS_ABERTURA', TrackEventEnum.GERAL_HEADER, {
      content_id: 'Inter Loop',
      content_name: 'Voltar',
      FICHA_CADASTRAL: hsfcValue,
    })
    onBackClick()
    setPageNumber(1)
  }

  const handleDownload = () => {
    sendDataNative<TS3Object>({
      body: {
        linkS3: INTER_LOOP_TERMS,
      },
      callback: 'callbackOpenPdf',
      method: 'OPENPDF',
    })

    sendTrackEventData(TrackEventEnum.GERAL_HEADER, {
      content_id: 'Inter Loop',
      content_name: 'Download',
    })
    sendToNewRelic('POS_ABERTURA', TrackEventEnum.GERAL_HEADER, {
      content_id: 'Inter Loop',
      content_name: 'Download',
      FICHA_CADASTRAL: hsfcValue,
    })
  }

  return (
    <PageContainer>
      <Title title="Termos de uso" onPageBack={handlePageBack} onDownload={handleDownload} />

      <S.PDFContainer>
        <Document
          file={INTER_LOOP_TERMS}
          onLoadSuccess={onDocumentLoadSuccess}
          error="Falha ao baixar documento!"
          loading="Carregando PDF"
        >
          <Page
            key={`page_${pageNumber + 1}`}
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            className="pdfPage"
          />
        </Document>
      </S.PDFContainer>

      <Footer
        currentPage={pageNumber}
        totalPage={numPages}
        onConfirmButton={onConfirmClick}
        onClickPreview={handleClickLess}
        onClickNext={handleClickMore}
        loading={loading}
        hsfcValue={hsfcValue}
        track_name="Inter Loop"
      />
    </PageContainer>
  )
}
