import React, { useState } from 'react'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Icons, PageContainer } from 'components'
import { sendDataNative } from 'components/hooks/useFetchNative'
import { GA_TERMS_CLOUDFRONT } from 'common/constants'
import { TrackEventEnum } from 'interfaces/trackEvent.interface'
import { useTrackEvent } from 'hooks'
import { sendToNewRelic } from 'services'
import Title from 'components/layout/Terms/title'
import Footer from 'components/layout/Terms/footer'
import { ChevronRight, PdfFile } from 'components/elements/Icons'

import { FlexColumn, FlexRow } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './style'
import { contaGlobalLabels } from './constants'
import terms from './termsData'

type TTermosProps = {
  onConfirmClick: () => void
  onBackClick: () => void
  loading: boolean
  hsfcValue: string
}
type TS3Object = { linkS3: string }

export const Termos = ({ onConfirmClick, onBackClick, loading, hsfcValue }: TTermosProps) => {
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [showTermsList, setShowTermsList] = useState(false)
  const [termsUrl, setTermsUrl] = useState('global-account-agreement-pt-BR.pdf')

  const sendTrackEventData = useTrackEvent()

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages)
  }

  const handleClickMore = () => {
    setPageNumber(pageNumber < numPages ? pageNumber + 1 : numPages)
  }
  const handleClickLess = () => {
    setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1)
  }

  const handlePageBack = () => {
    sendTrackEventData(TrackEventEnum.GERAL_HEADER, {
      content_id: contaGlobalLabels.PAGE_NAME,
      content_name: 'Voltar',
    })
    sendToNewRelic('POS_ABERTURA', TrackEventEnum.GERAL_HEADER, {
      content_id: contaGlobalLabels.PAGE_NAME,
      content_name: 'Voltar',
      FICHA_CADASTRAL: hsfcValue,
    })
    onBackClick()
    setPageNumber(1)
  }

  const handleDownload = () => {
    sendDataNative<TS3Object>({
      body: {
        linkS3: `${GA_TERMS_CLOUDFRONT}/agreement_terms/${termsUrl}`,
      },
      callback: 'callbackOpenPdf',
      method: 'OPENPDF',
    })

    sendTrackEventData(TrackEventEnum.GERAL_HEADER, {
      content_id: contaGlobalLabels.PAGE_NAME,
      content_name: 'Download',
    })
    sendToNewRelic('POS_ABERTURA', TrackEventEnum.GERAL_HEADER, {
      content_id: contaGlobalLabels.PAGE_NAME,
      content_name: 'Download',
      FICHA_CADASTRAL: hsfcValue,
    })
  }

  const randerTerm = (url: string) => {
    setTermsUrl(url)
    setShowTermsList(false)
  }

  return (
    <PageContainer>
      <Title
        title="Termos de uso"
        onPageBack={handlePageBack}
        onDownload={handleDownload}
        showTermsList={showTermsList}
      />

      {showTermsList ? (
        <FlexColumn className="py-4 gap-y-4">
          {terms.map((item) => (
            <S.SCard key={item.id} onClick={() => randerTerm(item.url)}>
              <FlexRow alignItems="center" gap="16px">
                <Icons variant={PdfFile} width={24} height={24} color="var(--gray500)" />
                <FlexColumn gap="4px">
                  <Text variant="body-3" bold>
                    {item.title}
                  </Text>

                  <Text variant="caption-1" as="p">
                    {item.description}
                  </Text>
                </FlexColumn>
                <Icons variant={ChevronRight} width={24} height={24} />
              </FlexRow>
            </S.SCard>
          ))}
        </FlexColumn>
      ) : (
        <>
          <S.PDFContainer>
            <Document
              file={`${GA_TERMS_CLOUDFRONT}/agreement_terms/${termsUrl}`}
              onLoadSuccess={onDocumentLoadSuccess}
              error="Falha ao baixar documento!"
              loading="Carregando PDF"
            >
              <Page
                key={`page_${pageNumber + 1}`}
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                className="pdfPage"
              />
            </Document>
          </S.PDFContainer>

          <Footer
            currentPage={pageNumber}
            totalPage={numPages}
            onConfirmButton={onConfirmClick}
            onClickPreview={handleClickLess}
            onClickNext={handleClickMore}
            loading={loading}
            hsfcValue={hsfcValue}
            track_name="Global Account"
            hasCheck={false}
            setShowTermsList={setShowTermsList}
          />
        </>
      )}
    </PageContainer>
  )
}
