import styled from 'styled-components'

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  li:not(:first-child) {
    border-top: 1px solid var(--gray200);
  }
`

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  column-gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--grayscale-200, #dedfe4);
  background: var(--white-100, #fff);
  margin-bottom: 24px;

  span {
    width: 100%;
  }
`

export const IconLabel = styled.div``
