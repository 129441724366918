import React from 'react'

import * as S from './style'

type TLoadingIndicator = {
  className?: string
}

export const LoadingIndicator: React.FC<TLoadingIndicator> = ({ className = '' }) => (
  <S.LoadingIndicatorStyle className={className} />
)
