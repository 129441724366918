import { AuthorizationHeader } from 'shared/functions/headers'
import { request } from 'shared/functions/request'

interface IWebAppInterface {
  apiGet(data: string): void
  apiPost(data: string): void
  apiPostWithoutResponse(data: string): void
  apiPut(data: string): void
  callScreen(data: string): void
  finishTempest(data: string): void
  callBottomSheet({ title, description }: IBottomSheet): void
  callMediaUpdate(data: string): void
  finishOnboarding(data: string): void
  completeOnboarding(data: string): void
  share(data: string): void
  trackEvent(data: string): void
  restartOnboarding(data: string): void
  openPdf(data: string): void
}

interface IBottomSheet {
  title: string
  description: string
}

declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        apiGet: {
          postMessage(data: string): void
        }
        apiPost: {
          postMessage(data: string): void
        }
        apiPostWithoutResponse: {
          postMessage(data: string): void
        }
        apiPut: {
          postMessage(data: string): void
        }
        callScreen: {
          postMessage(data: string): void
        }
        callBottomSheet: {
          postMessage({ title, description }: IBottomSheet): void
        }
        finishTempest: {
          postMessage(data: string): void
        }
        callMediaUpdate: {
          postMessage(data: string): void
        }
        finishOnboarding: {
          postMessage(data: string): void
        }
        completeOnboarding: {
          postMessage(data: string): void
        }
        share: {
          postMessage(data: string): void
        }
        trackEvent: {
          postMessage(data: string): void
        }
        restartOnboarding: {
          postMessage(data: string): void
        }
        openPdf: {
          postMessage(data: string): void
        }
      }
    }
  }
}

export declare const Android: IWebAppInterface

type TypeMethod =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'POSTWRESPONSE'
  | 'FINISHTEMPEST'
  | 'CALLMEDIAUPDATE'
  | 'FINISHONBOARDING'
  | 'SHARE'
  | 'TRACKEVENT'
  | 'COMPLETEONBOARDING'
  | 'RESTARTONBOARDING'
  | 'OPENPDF'

type TData = {
  [key: string]: string | number | boolean | undefined | null
}

export type TSendDataNative<T = TData> = {
  body?: T | unknown
  callback?: string
  endpoint?: string
  headers?: unknown
  method?: TypeMethod
}

export const sendDataNative = <T = TData>({
  method = 'GET',
  body,
  callback = 'callbackApi',
  endpoint = '',
  headers = {},
}: TSendDataNative<T>): void => {
  const dataToSent = JSON.stringify(body)
  const data = JSON.stringify({
    endpoint,
    body: dataToSent || '',
    callback,
    headers,
  })
  if (typeof Android !== 'undefined') {
    switch (method) {
      case 'GET':
        Android.apiGet(data)
        break
      case 'PUT':
        Android.apiPut(data)
        break
      case 'POST':
        Android.apiPost(data)
        break
      case 'POSTWRESPONSE':
        Android.apiPostWithoutResponse(data)
        break
      case 'FINISHTEMPEST':
        Android.finishTempest(data)
        break
      case 'CALLMEDIAUPDATE':
        Android.callMediaUpdate(dataToSent)
        break
      case 'FINISHONBOARDING':
        Android.finishOnboarding(data)
        break
      case 'COMPLETEONBOARDING': // GO TO LOGIN SCREEN
        Android.completeOnboarding(data)
        break
      case 'SHARE':
        Android.share(dataToSent)
        break
      case 'TRACKEVENT':
        Android.trackEvent(dataToSent)
        break
      case 'RESTARTONBOARDING':
        Android.restartOnboarding(dataToSent)
        break
      case 'OPENPDF':
        Android.openPdf(dataToSent)
        break
      default:
        break
    }
  } else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    typeof window.webkit.messageHandlers.apiGet !== 'undefined'
  ) {
    switch (method) {
      case 'GET':
        window.webkit.messageHandlers.apiGet.postMessage(data)
        break
      case 'PUT':
        window.webkit.messageHandlers.apiPut.postMessage(data)
        break
      case 'POST':
        window.webkit.messageHandlers.apiPost.postMessage(data)
        break
      case 'POSTWRESPONSE':
        window.webkit.messageHandlers.apiPostWithoutResponse.postMessage(data)
        break
      case 'FINISHTEMPEST':
        window.webkit.messageHandlers.finishTempest.postMessage(data)
        break
      case 'CALLMEDIAUPDATE':
        window.webkit.messageHandlers.callMediaUpdate.postMessage(dataToSent)
        break
      case 'FINISHONBOARDING':
        window.webkit.messageHandlers.finishOnboarding.postMessage(data)
        break
      case 'COMPLETEONBOARDING':
        window.webkit.messageHandlers.completeOnboarding.postMessage(data)
        break
      case 'SHARE':
        window.webkit.messageHandlers.share.postMessage(dataToSent)
        break
      case 'TRACKEVENT':
        window.webkit.messageHandlers.trackEvent.postMessage(dataToSent)
        break
      case 'RESTARTONBOARDING':
        window.webkit.messageHandlers.restartOnboarding.postMessage(dataToSent)
        break
      case 'OPENPDF':
        window.webkit.messageHandlers.openPdf.postMessage(dataToSent)
        break
      default:
        break
    }
  } else {
    request({
      method,
      url: endpoint,
      headers: AuthorizationHeader,
      body,
    })
      .then((res: unknown) => new Error(String(res)))
      .catch((error: string | undefined) => new Error(error))
  }
}

export const callBottomSheet = ({ title, description }: IBottomSheet): void => {
  const data = { title, description }
  if (typeof Android !== 'undefined') {
    Android.callBottomSheet(data)
  }
}

export const callScreen = (page: string): void => {
  if (typeof Android !== 'undefined') {
    Android.callScreen(page)
  }
}
