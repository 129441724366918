import React, { useState, useContext, useEffect } from 'react'

import { JSEncrypt } from 'jsencrypt'
import { useHistory } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Gray, Green, Orange, Red, Yellow } from 'assets/styled-component/variables'
import { TSendDataNative, sendDataNative } from 'components/hooks/useFetchNative'
import { useCallbackNative } from 'components/hooks/useCallbackNative'
import { CRIAR_SENHA } from 'components/urls'
import {
  PageText,
  PageContainer,
  ButtonWrapper,
  FlexForm,
  Input,
  Spacing,
  Text,
  Icons,
  Tooltip,
} from 'components'
import { getRouter, RoutesEnum } from 'enums/routes.enum'
import {
  scorePassword,
  TScorePassword,
  defaultScorePassword,
} from 'shared/functions/StrongerPassword'
import { ApiError, ApiResponse, GenericRes, IApiResponseCtx } from 'interfaces/response.interface'
import { ITrackEvent, TrackEventEnum } from 'interfaces/trackEvent.interface'
import { yupValidacaoCriarSenha } from 'validations'
import { ApiResponseCtx } from 'App'
import { sendToNewRelic } from 'services'
import { RSA_PUBLIC_KEY } from 'common/constants'
import { Check, ChevronRight, Help } from 'components/elements/Icons'

import { Icon } from '@inter/inter-ui'
import { Button } from '@interco/inter-ui/components/Button'
import { yupResolver } from '@hookform/resolvers/yup'

import * as S from './style'

type TFormInput = {
  senha: string
  confSenha?: string
}

export const MIN_PASSWORD_LENGTH = 8
const MAX_PASSWORD_LENGTH = 20

const CriarSenha = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [passwordStrength, setPasswordStrength] = useState<TScorePassword>(defaultScorePassword)
  const history = useHistory()

  const { setApiResponse }: IApiResponseCtx = useContext<IApiResponseCtx>(ApiResponseCtx)

  const { variations, status } = passwordStrength

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setError,
    formState: { errors },
  } = useForm<TFormInput>({
    resolver: yupResolver(yupValidacaoCriarSenha(variations)),
  })

  const onSubmit: SubmitHandler<TFormInput> = (form: TFormInput) => {
    if (loading) return
    setLoading(true)
    const jsEncrypt = new JSEncrypt()
    jsEncrypt.setPublicKey(RSA_PUBLIC_KEY as string)
    const callApi: TSendDataNative = {
      body: { senha: jsEncrypt.encrypt(form.senha) },
      callback: 'callbackApiSenha',
      endpoint: CRIAR_SENHA,
      method: 'POST',
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_CRIAR_SENHA_CONTINUAR,
        parameters: {},
      },
    })
  }

  useCallbackNative('callbackApiSenha', 'onCallbackApiSenha', {
    onCallbackApiSenha: (data: GenericRes) => {
      if (data instanceof ApiResponse) {
        const step = getRouter(data.telas.stepList[0].step)
        const urlResponse = data.telas.stepList[0].url
        const urlParams = urlResponse.split('?')[1]

        sendToNewRelic('CRIAR_SENHA', '[ApiResponse] - callbackApiSenha', {
          PROXIMA_ETAPA: step,
          URL: urlResponse,
        })

        if (step) {
          if (step === RoutesEnum.AVISO_REFAZER_ITENS) {
            setApiResponse({
              ...data,
              telas: {
                ...data.telas,
                stepList: data.telas?.stepList?.splice(1),
              },
            }) // should be remove first step
          }
          history.push({
            pathname: step,
            search: `?${urlParams}`,
          })
        } else {
          const callApi: TSendDataNative = {
            body: data,
            callback: 'callbackMediaUpdate',
            endpoint: '',
            method: 'CALLMEDIAUPDATE',
          }
          sendDataNative(callApi)
        }
      } else if (data instanceof ApiError) {
        if (data.campo && (data.campo === 'senha' || data.campo === 'confSenha')) {
          setError(data.campo, {
            message: data.mensagem || 'Campo obrigatório.',
          })
        } else {
          sendToNewRelic('CRIAR_SENHA', '[ApiError] - callbackApiSenha', {
            ...data,
          })
        }
      }
      setLoading(false)
    },
  })

  const handleShowIcon = () => {
    setShowPassword(!showPassword)
  }

  const selectedPasswordValue = watch('senha')
  useEffect(() => {
    setPasswordStrength(scorePassword(selectedPasswordValue))
  }, [selectedPasswordValue])

  const preventInputEvent = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    return false
  }

  const IconRules = (condicao: boolean) => (
    <>
      {condicao ? (
        <Spacing mr="xxs">
          <Icons variant={Check} color={Green[500]} width={12} height={12} />
        </Spacing>
      ) : (
        <li style={{ color: Gray[500] }} />
      )}
    </>
  )

  const verifiedRules =
    (getValues('senha') || '').length >= MIN_PASSWORD_LENGTH &&
    variations.upper &&
    variations.lower &&
    variations.nonWords &&
    variations.digits &&
    !(variations.sequence || variations.identicalSequence || !selectedPasswordValue)

  const StatusRules = () => {
    if (status.complexity === 'bad') {
      return { text: 'Senha fraca', color: Red[500] }
    }
    if (
      status.complexity === 'good' ||
      status.complexity === 'great' ||
      ((status.complexity === 'perfect' || status.complexity === 'fantastic') &&
        verifiedRules === false)
    ) {
      return { text: 'Senha média', color: Yellow[500] }
    }
    if (status.complexity === 'perfect' || status.complexity === 'fantastic' || verifiedRules) {
      return { text: 'Senha forte', color: Green[500] }
    }
    return { text: '', color: Gray[200] }
  }

  return (
    <>
      <PageContainer>
        <S.PageTitle>
          <PageText text="Crie sua senha de acesso à conta" margin="0" />

          <Tooltip
            icon={<Icons variant={Help} />}
            title="Informe uma nova senha"
            description="Chegou o momento de cadastrar sua nova senha. Cadastre uma senha que seja segura e
              você possa se lembrar."
            subDescription="Siga as instruções para o cadastrado."
          />
        </S.PageTitle>

        <FlexForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Input
            id="senha"
            type={`${showPassword ? 'text' : 'password'}`}
            maxLength={MAX_PASSWORD_LENGTH}
            error={Boolean(errors.senha)}
            success={
              (status.complexity === 'perfect' || status.complexity === 'fantastic') &&
              verifiedRules
            }
            infoText={errors.senha?.message}
            iconRight={<Icon color={Orange[600]} name={showPassword ? 'show' : 'hide'} size={20} />}
            onClickIconRight={handleShowIcon}
            {...register('senha')}
            onPaste={(e: React.ChangeEvent<HTMLInputElement>) => preventInputEvent(e)}
            onCopy={(e: React.ChangeEvent<HTMLInputElement>) => preventInputEvent(e)}
            onCut={(e: React.ChangeEvent<HTMLInputElement>) => preventInputEvent(e)}
            margin="0 0 0 50px"
          />

          <S.StatusContainer>
            <Spacing mr="xxs">
              <Text variant="caption-1">{StatusRules().text}</Text>
            </Spacing>

            <S.StatusBar color={StatusRules().color} itemProp="4px 0 0 4px" />
            <S.StatusBar color={status.complexity === 'bad' ? Gray[200] : StatusRules().color} />
            <S.StatusBar
              color={
                status.complexity === 'bad' ||
                status.complexity === 'good' ||
                status.complexity === 'great' ||
                ((status.complexity === 'perfect' || status.complexity === 'fantastic') &&
                  verifiedRules === false)
                  ? Gray[200]
                  : StatusRules().color
              }
            />
          </S.StatusContainer>

          {(status.complexity === 'perfect' || status.complexity === 'fantastic') &&
            verifiedRules && (
              <Input
                id="confSenha"
                type={`${showPassword ? 'text' : 'password'}`}
                placeholder="Confirmar senha"
                maxLength={MAX_PASSWORD_LENGTH}
                error={Boolean(errors.confSenha)}
                success={getValues('confSenha') === getValues('senha')}
                infoText={errors.confSenha?.message}
                iconRight={
                  <Icon color={Orange[600]} name={showPassword ? 'show' : 'hide'} size={20} />
                }
                onClickIconRight={handleShowIcon}
                {...register('confSenha')}
                onPaste={(e: React.ChangeEvent<HTMLInputElement>) => preventInputEvent(e)}
                onCopy={(e: React.ChangeEvent<HTMLInputElement>) => preventInputEvent(e)}
                onCut={(e: React.ChangeEvent<HTMLInputElement>) => preventInputEvent(e)}
              />
            )}

          {verifiedRules === false && (
            <>
              <Spacing mb="xxs" mt="sm">
                <Text variant="body-3" bold colorWeight={500}>
                  A senha deve ter:
                </Text>
              </Spacing>

              <S.TextRulesStyled
                defaultChecked={(getValues('senha') || '').length >= MIN_PASSWORD_LENGTH}
              >
                {IconRules((getValues('senha') || '').length >= MIN_PASSWORD_LENGTH)}
                De {MIN_PASSWORD_LENGTH} a {MAX_PASSWORD_LENGTH} dígitos
              </S.TextRulesStyled>

              <S.TextRulesStyled defaultChecked={variations.upper}>
                {IconRules(variations.upper)} Pelo menos 1 letra maiúscula
              </S.TextRulesStyled>

              <S.TextRulesStyled defaultChecked={variations.lower}>
                {IconRules(variations.lower)} Pelo menos 1 letra minúscula
              </S.TextRulesStyled>

              <S.TextRulesStyled defaultChecked={variations.nonWords}>
                {IconRules(variations.nonWords)} Pelo menos 1 caracter especial
              </S.TextRulesStyled>

              <S.TextRulesStyled defaultChecked={variations.digits}>
                {IconRules(variations.digits)} Pelo menos 1 número
              </S.TextRulesStyled>

              <S.TextRulesStyled
                defaultChecked={
                  !(variations.sequence || variations.identicalSequence || !selectedPasswordValue)
                }
              >
                {IconRules(
                  !(variations.sequence || variations.identicalSequence || !selectedPasswordValue),
                )}
                Não ter sequenciais ou idênticos como &quot;123 ou aaa&quot;
              </S.TextRulesStyled>
            </>
          )}

          <ButtonWrapper>
            <Button
              type="submit"
              isLoading={loading}
              disabled={!watch('confSenha') || !watch('senha')}
              icon={
                <Icons
                  variant={ChevronRight}
                  color={!watch('confSenha') || !watch('senha') ? Gray[300] : Gray[100]}
                />
              }
            >
              Continuar
            </Button>
          </ButtonWrapper>
        </FlexForm>
      </PageContainer>
    </>
  )
}

export default CriarSenha
