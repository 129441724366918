import React, { useState, useEffect, MouseEvent, useContext } from 'react'

import { useHistory } from 'react-router-dom'
import { PageContainer, Button, ButtonWrapper, Tag, PageText, Radio, Divider } from 'components'
import { TIPO_CARTAO } from 'components/urls'
import { useCallbackNative } from 'components/hooks/useCallbackNative'
import { TSendDataNative, sendDataNative } from 'components/hooks/useFetchNative'
import { getRouter } from 'enums/routes.enum'
import { CartoesEnum } from 'enums/cartoes.enum'
import url from 'shared/functions/url'
import { formatFullName } from 'shared/functions/utils'
import { GenericRes, ApiError, ApiResponse } from 'interfaces/response.interface'
import { ITrackEvent, TrackEventEnum } from 'interfaces/trackEvent.interface'
import { sendToNewRelic } from 'services'
import { cartoesModelos } from 'utils/Cartoes'
import { getUrlParams } from 'shared/helpers/getUrlPrams'
import { Nfc, Card } from 'components/elements/Icons'
import InsuranceCrossSellingCard from 'components/organisms/InsuranceCrossSellingCard'
import { InsuranceType } from 'components/organisms/InsuranceCrossSellingCard/types'
import { ILegalAgeCtx } from 'interfaces/dadosPessoais.interface'
import { LegalAgeCtx } from 'App'

import { Text, BottomSheet, Alert } from '@inter/inter-ui'

import { EscolhaCartaoWrapper, CardContainer, CardContentVirtual, TextSaibaMais } from './style'

export enum ECardType {
  VIRTUAL = 'VIRTUAL',
  FISICO_VIRTUAL = 'FISICO_VIRTUAL',
}

const protectedCardInsuranceExist = true

const EscolhaCartao = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [fullName, setFullname] = useState<string>('Seu Nome Completo')
  const [tipoCartao, setTipoCartao] = useState<string>(CartoesEnum.GOLDPADRAO)
  const [escolherCartaoVirtual, setEscolherCartaoVirtual] = useState<boolean>(false)
  const [openSaibaMaisVirtual, setOpenSaibaMaisVirtual] = useState<boolean>(false)
  const [openSaibaMaisFisicoVirtual, setOpenSaibaMaisFisicoVirtual] = useState<boolean>(false)
  const [isIdadeMaximaSomenteCartaoVirtual, setIsIdadeMaximaSomenteCartaoVirtual] = useState(false)
  const [hsfc, setHsfc] = useState<string | null>(null)
  const { isMinor }: ILegalAgeCtx = useContext<ILegalAgeCtx>(LegalAgeCtx)

  const [selected, setSelected] = useState<string>('')
  const [insuranceSelected, setInsuranceSelected] = useState<boolean>(false)

  const history = useHistory()

  const escolhaCartao = () => {
    if (escolherCartaoVirtual && tipoCartao === CartoesEnum.ATHLETICO) {
      return selected === '1' ? CartoesEnum.ATHLETICO_DIGITAL : CartoesEnum.ATHLETICO
    }
    if (escolherCartaoVirtual && tipoCartao === CartoesEnum.GOLDPADRAO) {
      return selected === '1' ? CartoesEnum.GOLD_DIGITAL : CartoesEnum.GOLDPADRAO
    }
    if (escolherCartaoVirtual && tipoCartao === CartoesEnum.LAION) {
      return selected === '1' ? CartoesEnum.LAION_DIGITAL : CartoesEnum.LAION
    }

    return tipoCartao
  }

  const handleSendData = () => {
    setLoading(true)
    const callApi: TSendDataNative = {
      body: {
        tipoCartao: escolhaCartao(),
        protectedCardTerm: protectedCardInsuranceExist ? insuranceSelected : false,
      },
      callback: 'callbackApiCartao',
      endpoint: TIPO_CARTAO,
      method: 'PUT',
    }

    sendToNewRelic('ESCOLHA_CARTAO', 'Cartao Solicitado', {
      TIPO_CARTAO: escolhaCartao(),
    })

    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_ESCOLHA_CARTAO_CONTINUAR,
        parameters: {
          cartaoEscolhido: escolhaCartao(),
        },
      },
    })
  }

  useCallbackNative('callbackApiCartao', 'onCallbackApiCartao', {
    onCallbackApiCartao: (data: GenericRes) => {
      if (data instanceof ApiResponse) {
        const step = getRouter(data.telas.stepList[0].step)

        sendToNewRelic('ESCOLHA_CARTAO', '[ApiResponse] - callbackApiCartao', {
          PROXIMA_ETAPA: step,
          URL: data.telas.stepList[0].url,
        })
        if (step) {
          history.push(`${step}${url.buildQuery(getUrlParams(data.telas.stepList[0].url))}`)
        } else {
          const callApi: TSendDataNative = {
            body: data,
            callback: 'callbackMediaUpdate',
            endpoint: '',
            method: 'CALLMEDIAUPDATE',
          }
          sendDataNative(callApi)
        }
      } else if (data instanceof ApiError) {
        sendToNewRelic('ESCOLHA_CARTAO', '[ApiError] - callbackApiCartao', {
          ...data,
        })
      }
      setLoading(false)
    },
  })

  const queries = url.queryParam()

  useEffect(() => {
    setFullname(url.queryParam().nome || 'Seu Nome Completo')
    setTipoCartao(url.queryParam().tipoCartao || CartoesEnum.GOLDPADRAO)

    setEscolherCartaoVirtual(
      url.queryParam().escolherCartaoVirtual === 'true' ? true : false || false,
    )

    setIsIdadeMaximaSomenteCartaoVirtual(
      url.queryParam().isIdadeMaximaSomenteCartaoVirtual === 'true' ? true : false || false,
    )
    setHsfc(url.queryParam().hsfc)
  }, [queries])

  const handleClickSaibaMais = (e: MouseEvent<HTMLButtonElement>, cardType: ECardType) => {
    cardType === ECardType.VIRTUAL
      ? setOpenSaibaMaisVirtual(true)
      : setOpenSaibaMaisFisicoVirtual(true)
    e.stopPropagation()
  }

  const handlerInsuranceCallback = (protectedCardselected) => {
    setInsuranceSelected(protectedCardselected)
    sendToNewRelic('ProtectedCard', protectedCardselected ? 'SCProt - On' : 'SCProt - Off', {
      FICHA_CADASTRAL: hsfc,
    })
  }

  return (
    <PageContainer className="h-100">
      {isIdadeMaximaSomenteCartaoVirtual ? (
        <>
          <PageText text="Cartão menor de idade" />
          <Text variant="headline-h1" as="h1" style={{ marginTop: '24px', fontSize: '22px' }}>
            O Cartão Inter pro seu pequeno é virtual e gratuito!
          </Text>
          <Text variant="body-2" as="p">
            Faça cadastro em carteiras digitais, compras online e muito mais.
          </Text>
          <EscolhaCartaoWrapper>
            <div className="card-container kids" data-card-name={formatFullName(fullName)}>
              <img src={cartoesModelos.VIRTUAL_KIDS} alt="Cartão" />
            </div>
          </EscolhaCartaoWrapper>

          <ButtonWrapper>
            <Alert type="grayscale" title="Solicite o cartão físico quando quiser" icon="info">
              Acessando as configurações de cartões
            </Alert>
            <Button type="submit" onClick={handleSendData} isLoading={loading}>
              Continuar
            </Button>
          </ButtonWrapper>
        </>
      ) : (
        <>
          {escolherCartaoVirtual ? (
            <>
              <PageText text="Qual tipo de cartão você prefere?" />

              <CardContainer
                className="card-cartao-virtual-container"
                style={{
                  gridRow: '2',
                  borderColor: selected === '1' ? 'var(--primary300)' : 'var(--gray200)',
                }}
                onClick={() => setSelected('1')}
              >
                <Tag color="var(--success500)" colorText="white" margin="16px 0 0 16px">
                  Recomendado
                </Tag>
                <div style={{ display: 'grid', gridTemplateColumns: '80% 20%' }}>
                  <CardContentVirtual>
                    <div style={{ alignSelf: 'center' }}>
                      <Nfc height={24} width={24} color="var(--gray500)" />
                    </div>
                    <div>
                      <Text
                        variant="body-3"
                        as="span"
                        bold
                        colorWeight={500}
                        style={{ display: 'block', padding: '0 16px 4px' }}
                      >
                        Cartão virtual
                      </Text>
                      <TextSaibaMais
                        variant="caption-1"
                        as="span"
                        bold
                        colorWeight={500}
                        onClick={(e: MouseEvent<HTMLButtonElement>) =>
                          handleClickSaibaMais(e, ECardType.VIRTUAL)
                        }
                      >
                        Saiba mais
                      </TextSaibaMais>
                    </div>
                  </CardContentVirtual>
                  <Radio
                    id="label-1"
                    value="1"
                    name="label"
                    checked={selected === '1'}
                    reversed
                    style={{ marginRight: '16px' }}
                  />
                </div>
              </CardContainer>
              <CardContainer
                className="card-cartao-fisico-virtual-container"
                style={{
                  borderColor: selected === '2' ? 'var(--primary300)' : 'var(--gray200)',
                  gridTemplateColumns: '80% 20%',
                }}
                onClick={() => setSelected('2')}
              >
                <CardContentVirtual style={{ paddingTop: '16px' }}>
                  <Card height={24} width={24} color="var(--gray500)" />
                  <div>
                    <Text
                      variant="body-3"
                      as="span"
                      bold
                      style={{ color: 'var(--gray500)', display: 'block', padding: '0 16px' }}
                    >
                      Cartão físico + virtual
                    </Text>
                    <Text
                      variant="body-3"
                      as="span"
                      bold
                      style={{ color: 'var(--primary500)', width: '80px', padding: '0 16px' }}
                      onClick={(e: MouseEvent<HTMLButtonElement>) =>
                        handleClickSaibaMais(e, ECardType.FISICO_VIRTUAL)
                      }
                    >
                      Saiba mais
                    </Text>
                  </div>
                </CardContentVirtual>
                <Radio
                  id="label-2"
                  value="2"
                  name="label"
                  checked={selected === '2'}
                  reversed
                  style={{ marginRight: '16px', paddingTop: '16px' }}
                />
              </CardContainer>
              <ButtonWrapper>
                {selected === '1' && url.queryParam().tipoCartao && (
                  <Alert
                    title="Solicite o cartão físico quando quiser"
                    icon="warning"
                    type="grayscale"
                  >
                    Acessando as configurações de cartões
                  </Alert>
                )}
                {selected === '2' && (
                  <Alert
                    title="Sobre prazo de entrega do seu cartão"
                    icon="warning"
                    type="grayscale"
                  >
                    Receba seu cartão em até 15 dias úteis na sua casa. Enquanto espera, você pode
                    utilizar o seu cartão virtual.
                  </Alert>
                )}
                {protectedCardInsuranceExist && !isMinor && (
                  <>
                    <Divider />
                    <InsuranceCrossSellingCard
                      insuranceType={InsuranceType.ProtectedCard}
                      callback={handlerInsuranceCallback}
                    />
                  </>
                )}
                <Button
                  type="submit"
                  disabled={!selected}
                  onClick={handleSendData}
                  isLoading={loading}
                >
                  Continuar
                </Button>
              </ButtonWrapper>
            </>
          ) : (
            <>
              <PageText text="O laranjinha a um passo!" margin="80px 0px 24px 0px" />

              <p className="mb-4">
                Após finalizar o processo de abertura, você poderá acompanhar a entrega do seu
                cartão pelo menu de Cartões.
              </p>

              <EscolhaCartaoWrapper>
                <div
                  className={`card-container ${tipoCartao !== 'ATHLETICO' ? 'kids' : ''}`}
                  data-card-name={formatFullName(fullName)}
                >
                  <img src={cartoesModelos[tipoCartao]} alt="Cartão" />
                </div>
              </EscolhaCartaoWrapper>

              <ButtonWrapper>
                {protectedCardInsuranceExist && !isMinor && (
                  <InsuranceCrossSellingCard
                    insuranceType={InsuranceType.ProtectedCard}
                    callback={handlerInsuranceCallback}
                  />
                )}
                <Button type="submit" onClick={handleSendData} isLoading={loading}>
                  Continuar
                </Button>
              </ButtonWrapper>
            </>
          )}
        </>
      )}

      {openSaibaMaisFisicoVirtual && (
        <BottomSheet onClose={() => setOpenSaibaMaisFisicoVirtual(false)}>
          <h3>Cartão físico</h3>
          <p className="mb-4">
            Você receberá o seu cartão Inter Mastercard no endereço cadastrado em até 15 dias úteis,
            habilitado para compras no débito e saques nas redes Banco24Horas e Saque e Pague.
            Enquanto espera, você pode utilizar o seu cartão virtual.
          </p>

          <ButtonWrapper>
            <Button variant="primary" onClick={() => setOpenSaibaMaisFisicoVirtual(false)}>
              Entendi
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}

      {openSaibaMaisVirtual && (
        <BottomSheet onClose={() => setOpenSaibaMaisVirtual(false)}>
          <h3>Vantagens do cartão virtual</h3>
          <p className="mb-4">
            Ao optar pelo cartão virtual, você terá uma atitude mais sustentável, contribuindo para
            um mundo com menos plástico. E já pode utilizar seu cartão para compras online e físicas
            por aproximação do celular.
          </p>

          <ButtonWrapper>
            <Button variant="primary" onClick={() => setOpenSaibaMaisVirtual(false)}>
              Entendi
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}
    </PageContainer>
  )
}

export default EscolhaCartao
