import styled from 'styled-components'
import { Gray, INPUT_HEIGHT, Orange } from 'assets/styled-component/variables'

import { Input as InterInput } from '@inter/inter-ui'

export const Input = styled(InterInput)`
  input {
    font-weight: bold;
    width: 100%;
    max-width: 100%;
    caret-color: ${Orange[500]};
  }

  > div:not(:first-child) {
    height: ${INPUT_HEIGHT};
  }

  &.customSelect {
    input {
      cursor: pointer;
    }
  }

  &.readonlyDisabled {
    label {
      color: ${Gray[300]};
    }

    input,
    input::placeholder,
    input:disabled {
      color: ${Gray[200]};
      -webkit-text-fill-color: ${Gray[200]};
      opacity: 1;
    }
  }

  input:disabled {
    color: ${Gray[200]};
    -webkit-text-fill-color: ${Gray[200]};
  }
`
