export interface ITrackEvent {
  name: TrackEventEnum
  parameters: { [key: string]: unknown }
}

export enum TrackEventEnum {
  TELA_DADOS_BASICOS_DATA_NASCIMENTO_CONTINUAR = 'V_CDPF_DataNascimento_T_Continuar',
  TELA_DADOS_BASICOS_DATA_NASCIMENTO_VALIDO = 'V_CDPF_DataNascimento_P_DataNascimento',
  TELA_DADOS_BASICOS_ESTADO_CIVIL_CONTINUAR = 'V_CDPF_EstadoCivil_P_EstadoCivil',
  TELA_DADOS_BASICOS_ESTADO_CIVIL_VALIDO = 'V_CDPF_EstadoCivil_T_Continuar',
  TELA_DADOS_BASICOS_CONTATO_CELULAR_VALIDO = 'V_CDPF_Contatos_P_Celular',
  TELA_DADOS_BASICOS_CONTATO_EMAIL_VALIDO = 'V_CDPF_Contatos_P_Email',
  TELA_DADOS_BASICOS_CONTATO_CONTINUAR = 'V_CDPF_Contatos_T_Continuar',
  TELA_DADOS_BASICOS_CONTATO_ALTERAR = 'V_CDPF_Contatos_T_Alterar',
  TELA_DADOS_BASICOS_CONTATO_CONFIRMAR = 'V_CDPF_Contatos_T_Confirmar',
  TELA_SMS_ENVIAR = 'V_CDPF_ConfirmarSms_P_SMS',
  TELA_SMS_REENVIAR = 'V_CDPF_ConfirmarSms_T_Reenviar',
  TELA_EMAIL_ENVIAR = 'V_CDPF_ConfirmarEmail_P_Email',
  TELA_EMAIL_REENVIAR = 'V_CDPF_ConfirmarEmail_T_Reenviar',
  TELA_REVALIDAR_EMAIL_REENVIAR = 'V_CDPF_ConfirmarEmail_T_ReenviarCódigo',
  TELA_REVALIDAR_EMAIL_SEM_ACESSO = 'V_CDPF_ConfirmarEmail_T_NaoTenhoAcesso',
  TELA_REVALIDAR_EMAIL_POPUP_VOLTAR = 'V_CDPF_ConfirmarEmail_T_Voltar',
  TELA_REVALIDAR_EMAIL_POPUP_CONTINUAR = 'V_CDPF_ConfirmarEmail_T_Reiniciar',
  TELA_NATURALIDADE_CONTINUAR = 'V_CDPF_Naturalidade_T_Confirmar',
  TELA_ESCOLHA_CARTAO_CONTINUAR = 'V_CDPF_EscolhaCart_T_Continuar',
  TELA_ENDERECO_RESIDENCIAL_CEP_VALIDO = 'V_CDPF_EndResidencialOnboard_P_CEP',
  TELA_ENDERECO_RESIDENCIAL_CEP_CONTINUAR = 'V_CDPF_EndResidencialOnboard_T_Continuar',
  TELA_ENDERECO_RESIDENCIAL_CONTINUAR = 'V_CDPF_EndResidencial_T_Continuar',
  TELA_CONFIRMARCAO_ENTREGA_EDITAR = 'V_CDPF_ConfEntrega_T_Editar',
  TELA_CONFIRMARCAO_ENTREGA_CONTINUAR = 'V_CDPF_ConfEntrega_T_Continuar',
  TELA_ATENCAO_PREENCHER_INFOR_CONTINUAR = 'V_CDPF_FotoDocResp_T_Continuar',
  TELA_DADOS_PROFISSIONAIS_CONTINUAR = 'V_CDPF_DadosProf_T_Continuar',
  TELA_CRIAR_SENHA_CONTINUAR = 'V_CDPF_CriarSenha_T_Finalizar',
  TELA_CONCLUSAO_PJ_ABRIR = 'V_CDPF_ContaPJ_T_AbirContaPJ',
  TELA_CONCLUSAO_PJ_AGORA_NAO = 'V_CDPF_ContaPJ_T_AgoraNao',
  TELA_CONCLUSAO_PJ_A_CAMINHO_INDICAR = 'V_CDPF_SolicEmAnalise_T_Indicar',
  TELA_CONCLUSAO_PJ_A_CAMINHO_CONHECA_BANCO = 'V_CDPF_SolicEmAnalise_T_ConhecaOBanco',
  TELA_CONCLUSAO_PF_A_CAMINHO_INDICAR = 'V_CDPF_SolicEmAnalise_T_Indicar',
  TELA_CONCLUSAO_PF_A_CAMINHO_CONHECA_BANCO = 'V_CDPF_SolicEmAnalise_T_ConhecaOBanco',
  TELA_PENDENCIAMENTO_CONTINUAR = 'V_CDPF_Pendenciado_T_Continuar',
  TELA_PENDENCIAMENTO_REPROVADO_CONTINUAR = 'V_CDPF_ContaReprovada_T_ContaReprovada',
  TELA_PENDENCIAMENTO_APROVADO_INDICAR = 'V_CDPF_ContaAprovada_T_IndicarAmigos',
  TELA_PENDENCIAMENTO_APROVADO_CONTINUAR = 'V_CDPF_ContaAprovada_T_AcessarConta',
  TELA_CONTA_BASICA_ABERTA_INDICAR = 'V_CDPF_ContaAprovada_T_IndicarAmigos',
  TELA_INDIQUE_AMIGOS_AJUDA = 'V_CDPF_IndiqueAmigos_T_Help',
  TELA_INDIQUE_AMIGOS_BOTAO = 'V_CDPF_IndiqueAmigos_T_Botao',
  TELA_INDIQUE_AMIGOS_VALIDACAO_CODIGO = 'V_CDPF_IndiqueAmigos_DC_Validacao',
  TELA_INDIQUE_AMIGOS_ENVIAR = 'V_CDPF_IndiqueAmigos_T_Enviar',
  GERAL_HEADER = 'V_CDPF_Header_T',
  GERAL_BOTAO = 'V_CDPF_Geral_T_Botao',
  GERAL_CHECKBOX = 'V_CDPF_Geral_T_Checkbox',
  GERAL_BOTTOMSHEET = 'V_CDPF_Geral_DC_Bottomsheet',
  GERAL_INPUT = 'V_CDPF_Geral_DC_Input',
  GERAL_SLIDER = 'V_CDPF_Geral_T_Slider',
}
