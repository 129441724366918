import React, { useState, useEffect, useContext } from 'react'

import { useHistory } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { sendDataNative, TSendDataNative } from 'components/hooks/useFetchNative'
import { STEP_NATURALIDADE, getPathCidades } from 'components/urls'
import { useCallbackNative } from 'components/hooks/useCallbackNative'
import { PageContainer, Select, Button, ButtonWrapper, FlexForm, Input, PageText } from 'components'
import { getRouter } from 'enums/routes.enum'
import estados from 'shared/json/estados.json'
import paises from 'shared/json/paises.json'
import url from 'shared/functions/url'
import { ITrackEvent, TrackEventEnum } from 'interfaces/trackEvent.interface'
import { ICities, ICity } from 'interfaces/cities.interface'
import { GenericRes, ApiResponse, ApiError } from 'interfaces/response.interface'
import { yupValidacaoNaturalidade } from 'validations'
import { toTitleCase, toUpperCase } from 'shared/functions/utils'
import { Gray, Orange } from 'assets/styled-component/variables'
import { sendToNewRelic } from 'services'
import { ILegalAgeCtx } from 'interfaces/dadosPessoais.interface'
import { LegalAgeCtx } from 'App'
import { getUrlParams } from 'shared/helpers/getUrlPrams'

import { Icon } from '@inter/inter-ui'
import { yupResolver } from '@hookform/resolvers/yup'

type TFormInput = {
  nacionalidade?: string
  ufNaturalidade?: string
  naturalidade?: string
  cidade?: string
}

type TJson = {
  key: string
  value: string
}

const Naturalidade = () => {
  const [brForm, setBrForm] = useState<boolean>(true)
  const [cities, setCities] = useState<TJson[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingCities, setLoadingCities] = useState<boolean>(false)
  const history = useHistory()

  const { isMinor }: ILegalAgeCtx = useContext<ILegalAgeCtx>(LegalAgeCtx)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    setError,
    formState: { errors },
  } = useForm<TFormInput>({
    resolver: yupResolver(yupValidacaoNaturalidade),
  })

  useEffect(() => {
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: 'V_CDPF_Pagina_Naturalidade',
        parameters: {},
      },
    })
  }, [])

  useCallbackNative('callbackApiNaturalidade', 'onCallbackApiNaturalidade', {
    onCallbackApiNaturalidade: (data: GenericRes) => {
      if (data instanceof ApiResponse) {
        const step = getRouter(data.telas.stepList[0].step)
        const urlStepList = data.telas.stepList[0].url

        sendToNewRelic('NATURALIDADE', '[ApiResponse] - callbackApiNaturalidade', {
          PROXIMA_ETAPA: step,
          URL: urlStepList,
        })

        const newStep = urlStepList.split('/#/')[1]
        const hasQuestionMark = newStep.includes('?')

        if (newStep) {
          history.push(
            `/${newStep}${url.buildQuery(
              { nome: data.nome, ...getUrlParams(data.telas.stepList[0].url) },
              hasQuestionMark,
            )}`,
          )
        } else if (step) {
          history.push(
            `${step}${url.buildQuery({
              nome: data.nome,
              ...getUrlParams(data.telas.stepList[0].url),
            })}`,
          )
        } else {
          const callApi: TSendDataNative = {
            body: data,
            callback: 'callbackMediaUpdate',
            endpoint: '',
            method: 'CALLMEDIAUPDATE',
          }
          sendDataNative(callApi)
        }
      } else if (data instanceof ApiError) {
        if (
          data.campo &&
          (data.campo === 'nacionalidade' ||
            data.campo === 'ufNaturalidade' ||
            data.campo === 'cidade' ||
            data.campo === 'naturalidade')
        ) {
          setError(data.campo, {
            message: data.mensagem || 'Campo obrigatório.',
          })
        } else {
          sendToNewRelic('NATURALIDADE', '[ApiError] - callbackApiNaturalidade', {
            ...data,
          })
        }
      }
      setLoading(false)
    },
  })

  const fetchCities = (ufState: string) => {
    setCities([])
    setLoadingCities(true)
    sendDataNative({
      callback: 'callbackApiNaturalidadeCidades',
      endpoint: getPathCidades(ufState),
    })
  }

  const selectedNationalityValue = watch('nacionalidade')
  useEffect(() => {
    if (selectedNationalityValue === undefined) {
      setValue('nacionalidade', 'Brasil')
      setBrForm(true)
    }
    if (selectedNationalityValue === 'Brasil') {
      setBrForm(true)
      setValue('ufNaturalidade', undefined)
      setValue('cidade', undefined)
      setValue('naturalidade', undefined)
      setLoadingCities(false)
    } else {
      setBrForm(false)
    }
  }, [selectedNationalityValue, setValue])

  const selectedStateValue = watch('ufNaturalidade')
  useEffect(() => {
    if (selectedStateValue) {
      const selectedUf = estados.find((v: TJson) => v.value === selectedStateValue)?.key
      fetchCities(selectedUf ?? '')
    }
  }, [selectedStateValue])

  useCallbackNative('callbackApiNaturalidadeCidades', 'onCallbackApiNaturalidadeCidades', {
    onCallbackApiNaturalidadeCidades: (data: GenericRes<ICities>) => {
      if (data instanceof ApiResponse) {
        if (Array.isArray(data.dados.cidades) && data.dados.cidades?.length > 0) {
          const listOfCities = data.dados.cidades
          sendToNewRelic(
            'NATURALIDADE_LISTA_CIDADES',
            '[ApiResponse] - Sucesso ao buscar cidades',
            {
              UF: selectedStateValue,
            },
          )
          setCities(
            listOfCities.map((city: ICity) => ({
              key: city.codigo,
              value: toTitleCase(city.nome),
            })),
          )
        }
      } else if (data instanceof ApiError) {
        sendToNewRelic(
          'NATURALIDADE_LISTA_CIDADES',
          '[ApiError] - callbackApiNaturalidadeCidades',
          {
            ...data,
          },
        )
      }
      setLoadingCities(false)
    },
  })

  const onSubmit: SubmitHandler<TFormInput> = (form: TFormInput) => {
    setLoading(true)
    if (form.nacionalidade === 'Brasil') {
      form.naturalidade = toUpperCase(form.cidade)
    }

    const sendForm = {
      nacionalidade: paises.find((v: TJson) => v.value === form.nacionalidade)?.description || '',
      ufNaturalidade:
        form.nacionalidade === 'Brasil'
          ? estados.find((v: TJson) => v.value === form.ufNaturalidade)?.key
          : undefined,
      naturalidade: form.naturalidade,
    }

    const callApi: TSendDataNative = {
      body: sendForm,
      callback: 'callbackApiNaturalidade',
      endpoint: STEP_NATURALIDADE,
      method: 'PUT',
    }

    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_NATURALIDADE_CONTINUAR,
        parameters: {
          pais: sendForm.nacionalidade,
          estado: sendForm.ufNaturalidade,
          cidade: sendForm.naturalidade,
        },
      },
    })
    sendToNewRelic('NATURALIDADE', 'Dados enviado pelo formulário', {
      PAIS: sendForm.nacionalidade,
      UF: sendForm.ufNaturalidade || '',
      CIDADE: sendForm.naturalidade,
    })
  }

  const handleChangeNacionalidade = (value: string) => {
    setValue('nacionalidade', value)
  }
  const handleChangeUfNaturalidade = (value: string) => {
    setValue('ufNaturalidade', value)
    setValue('cidade', undefined)
  }
  const handleChangeCidade = (value: string) => {
    setValue('cidade', value)
  }

  const verifyDisabledButton = () =>
    !(
      (Boolean(getValues('ufNaturalidade')) && Boolean(getValues('cidade'))) ||
      Boolean(getValues('naturalidade'))
    )

  return (
    <PageContainer>
      <PageText text={isMinor ? 'Onde a criança ou adolescente nasceu?' : 'Onde você nasceu?'} />

      <FlexForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Select
          id="nacionalidade"
          label="País"
          options={paises}
          placeholder="Selecione o seu país natal"
          defaultValue={selectedNationalityValue}
          value={selectedNationalityValue}
          setSelectValue={handleChangeNacionalidade}
          isUsingFilter
          placeholderFilter="Busque o seu país natal"
          error={Boolean(errors.nacionalidade)}
          infoText={errors.nacionalidade?.message}
          {...register('nacionalidade')}
        />
        {brForm ? (
          <>
            <Select
              id="ufNaturalidade"
              label="Estado"
              options={estados}
              placeholder="Selecione o seu estado natal"
              selectValue={selectedStateValue ?? undefined}
              value={selectedStateValue ?? undefined}
              setSelectValue={handleChangeUfNaturalidade}
              isUsingFilter
              placeholderFilter="Busque o seu estado natal"
              error={Boolean(errors.ufNaturalidade)}
              infoText={errors.ufNaturalidade?.message}
              {...register('ufNaturalidade')}
            />

            {cities.length > 0 ? (
              <Select
                id="cidade"
                label="Cidade"
                options={cities}
                placeholder="Selecione a sua cidade natal"
                selectValue={watch('cidade') ?? ''}
                value={watch('cidade') ?? ''}
                setSelectValue={handleChangeCidade}
                isUsingFilter
                placeholderFilter="Busque a sua cidade natal"
                error={Boolean(errors.cidade)}
                infoText={errors.cidade?.message}
                disabled={!cities.length || loadingCities}
                loading={loadingCities}
                {...register('cidade')}
              />
            ) : (
              <Input
                id="cidade"
                type="text"
                label="Cidade"
                placeholder="Selecione a sua cidade natal"
                disabled
                iconRight={
                  <Icon
                    name={loadingCities ? 'loading' : 'arrow-down'}
                    size="20px"
                    color={loadingCities ? Orange[500] : Gray[200]}
                  />
                }
              />
            )}
          </>
        ) : (
          <Input
            id="naturalidade"
            value={watch('naturalidade')}
            label="Cidade"
            maxLength={80}
            placeholder="Informe a sua cidade natal"
            error={Boolean(errors.naturalidade)}
            infoText={errors.naturalidade?.message}
            {...register('naturalidade')}
          />
        )}

        <ButtonWrapper>
          <Button type="submit" isLoading={loading} disabled={verifyDisabledButton()}>
            Continuar
          </Button>
        </ButtonWrapper>
      </FlexForm>
    </PageContainer>
  )
}

export default Naturalidade
