/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'

import imgIndicacao from 'assets/images/codigoIndicacao.png'
import sinalSuccess from 'assets/images/sinalSuccess.svg'
import sinalError from 'assets/images/sinalError.svg'
import { PageContainer, ButtonWrapper, Button, Input, FlexForm, Spacing } from 'components'
import { Orange, Green, Red } from 'assets/styled-component/variables'
import { getLoyaltyTerms, getValidaCodigo, putCodigoIndicar } from 'services/axios'
import { TResponseInfo } from 'services/axios/types'
import { RESPONSE_VALIDA_CODIGO } from 'services/axios/constants'
import { TWO_SECONDS } from 'shared/functions/constants'
import { IFichaCadastralCtx } from 'interfaces/fichaCadastral.interface'
import { FichaCadastralCtx, InterLoopCtx, PosAberturaCtx } from 'App'
import { sendToNewRelic } from 'services'
import { toUpperCase } from 'shared/functions/utils'
import { TrackEventEnum } from 'interfaces/trackEvent.interface'
import { useTrackEvent } from 'hooks'
import { useHistory } from 'react-router-dom'
import { RoutesEnum } from 'enums/routes.enum'
import url from 'shared/functions/url'
import { TLoyaltyTermResponse } from 'services/axios/getLoyaltyTerms/types'
import { IInterLoopCtx } from 'interfaces/loop.interface'
import { IPosAberturaCtx } from 'interfaces/posAbertura.interface'

import { BottomSheet, Icon, Text } from '@inter/inter-ui'

const CodigoIndicacao = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [loadingLoop, setLoadingloop] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const [validation, setValidation] = useState<TResponseInfo>(RESPONSE_VALIDA_CODIGO.DEFAULT)

  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState<boolean>(false)
  const [bottomSheetContent, setBottomSheetContent] = useState<ReactElement>(<></>)

  const [isOpenCodeModal, setIsOpenCodeModal] = useState<boolean>(false)

  const { hsfc }: IFichaCadastralCtx = useContext<IFichaCadastralCtx>(FichaCadastralCtx)
  const { setTermId, setTermType, setTermLink }: IInterLoopCtx =
    useContext<IInterLoopCtx>(InterLoopCtx)
  const { posAberturaOptions, setPosAberturaOptions }: IPosAberturaCtx =
    useContext<IPosAberturaCtx>(PosAberturaCtx)

  const refCodeInput = useRef<HTMLInputElement>(null)

  const history = useHistory()
  const sendTrackEventData = useTrackEvent()

  const hsfcValue = (): string => hsfc || (sessionStorage.getItem('hsfc') as string)

  const codeInfoBottomSheet = () => (
    <>
      <Text variant="headline-h3" as="h3">
        Código de indicação
      </Text>

      <Text variant="body-3" as="p">
        No Inter, você indica a abertura de conta aos seus amigos e ganha R$ 10,00 de cashback extra
        para usar Inter Shop por cada amigo convidado.
      </Text>

      <ButtonWrapper>
        <Button type="submit" onClick={() => setIsOpenBottomSheet(false)}>
          Entendi
        </Button>
      </ButtonWrapper>
    </>
  )

  const successBottomSheet = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={sinalSuccess} alt="Sucesso!" />
      <Text variant="headline-h3" as="h3" style={{ marginTop: '40px' }}>
        Código enviado com sucesso
      </Text>
    </div>
  )

  const failBottomSheet = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={sinalError} alt="Falha!" />
      <Text variant="headline-h3" as="h3" style={{ marginTop: '40px' }}>
        Não foi possível enviar seu código
      </Text>
    </div>
  )

  useEffect(() => {
    sendToNewRelic('INDICACAO_PF', '[Screen] - CodigoIndicacao', {
      FICHA_CADASTRAL: hsfcValue(),
    })
  }, [])

  useEffect(() => {
    if (code.length === 8) {
      setLoading(true)

      getValidaCodigo({
        params: { codigo: code },
        header: { hsfc: hsfcValue() },
        success: (response) => {
          setValidation(response)
          refCodeInput.current?.blur()
          sendToNewRelic('INDICACAO_PF', '[ApiResponse] - CodigoConsultado', {
            FICHA_CADASTRAL: hsfcValue(),
            CODIGO_INDICACAO: code,
            VALIDO: 'Sim',
          })
          sendTrackEventData(TrackEventEnum.TELA_INDIQUE_AMIGOS_VALIDACAO_CODIGO, {
            content_id: validation.info,
            content_status: 'Aprovado',
          })
        },
        error: (error) => {
          setValidation(error)
          sendToNewRelic('INDICACAO_PF', '[ApiResponse] - CodigoConsultado', {
            FICHA_CADASTRAL: hsfcValue(),
            CODIGO_INDICACAO: code,
            VALIDO: 'Não',
          })
          sendTrackEventData(TrackEventEnum.TELA_INDIQUE_AMIGOS_VALIDACAO_CODIGO, {
            content_id: validation.info,
            content_status: 'Negado',
          })
        },
        finally: () => {
          setLoading(false)
        },
      })
    } else setValidation(RESPONSE_VALIDA_CODIGO.DEFAULT)
  }, [code])

  const inputIcon = () => {
    if (loading) return <Icon name="loading" color={Orange[500]} />

    if (validation.status !== 'UNDEFINED') {
      return (
        <Icon
          name={validation.icon}
          color={validation.status === 'SUCCESS' ? Green[500] : Red[500]}
        />
      )
    }

    return undefined
  }

  const errorStatus = (): boolean => ['ERROR', 'FAIL'].includes(validation.status)

  const handleCodeModal = () => {
    if (errorStatus()) setCode('')
    setIsOpenCodeModal(!isOpenCodeModal)
    setLoadingSubmit(false)
  }

  const handleClickInserirCodigo = () => {
    handleCodeModal()

    sendTrackEventData(TrackEventEnum.TELA_INDIQUE_AMIGOS_BOTAO, {
      content_id: 'Inserir código de indicação',
    })
  }
  const finishAbertura = (TrackName: string, TrackContentId: string | undefined) => {
    setLoadingloop(true)

    getLoyaltyTerms({
      success: (response: TLoyaltyTermResponse) => {
        setTermId(Object(response).termId)
        setTermType(Object(response).termType)
        setTermLink(Object(response).termLink)
        Object(response).enabled === true &&
          setPosAberturaOptions({
            ...posAberturaOptions,
            loop: { ...posAberturaOptions.loop, display: true },
            indique: { ...posAberturaOptions.indique, display: false },
          })
      },
      finally: () => setLoadingloop(false),
    })

    JSON.stringify(url.queryParam().isMinor) === 'true'
      ? history.push(RoutesEnum.FIM_ONBOARDING)
      : history.push(RoutesEnum.HOME_POS_ABERTURA)
    sendTrackEventData(TrackName, {
      content_id: TrackContentId,
    })
  }

  const submitCode = () => {
    putCodigoIndicar({
      data: { codigoIndicacao: code },
      header: { hsfc: hsfcValue() },
      success: () => {
        handleCodeModal()
        setIsOpenBottomSheet(true)
        setBottomSheetContent(successBottomSheet)

        sendToNewRelic('INDICACAO_PF', '[ApiResponse] - SucessoEnviarIndicacao', {
          FICHA_CADASTRAL: hsfcValue(),
          CODIGO_INDICACAO: code,
        })

        setTimeout(() => {
          setCode('')
          setIsOpenBottomSheet(false)
          finishAbertura(TrackEventEnum.TELA_INDIQUE_AMIGOS_ENVIAR, undefined)
        }, TWO_SECONDS)
      },
      error: () => {
        handleCodeModal()
        setIsOpenBottomSheet(true)
        setBottomSheetContent(failBottomSheet)

        sendToNewRelic('INDICACAO_PF', '[ApiError] - FalhaEnviarIndicacao', {
          FICHA_CADASTRAL: hsfcValue(),
          CODIGO_INDICACAO: code,
        })

        setTimeout(() => {
          setIsOpenBottomSheet(false)
          setValidation(RESPONSE_VALIDA_CODIGO.FAIL)
        }, TWO_SECONDS)
      },
    })
  }

  const handleSubmitCode = () => {
    setLoadingSubmit(true)
    getValidaCodigo({
      params: { codigo: code },
      header: { hsfc: hsfcValue() },
      success: () => {
        submitCode()
      },
      error: () => {
        setValidation(RESPONSE_VALIDA_CODIGO.FAIL)
      },
      finally: () => setLoadingSubmit(false),
    })
  }

  return (
    <PageContainer>
      <img
        src={imgIndicacao}
        alt="Indique um amigo"
        style={{ margin: '24px -24px', width: 'calc(100% + 48px)' }}
      />

      <Text variant="headline-h1" as="h1">
        Recebeu uma indicação? Resgate sua bonificação
      </Text>

      <Text variant="body-3" as="p">
        Insira o{' '}
        <Text
          onClick={() => {
            setIsOpenBottomSheet(true)
            setBottomSheetContent(codeInfoBottomSheet)
          }}
          variant="body-3"
          color="primary"
          as="span"
          bold
        >
          código
        </Text>{' '}
        que recebeu para resgatar 200 pontos e trocar por até R$5,00
      </Text>

      <ButtonWrapper>
        <Button onClick={handleClickInserirCodigo}>Inserir código de indicação</Button>
        <Button
          variant="secondary"
          isLoading={loadingLoop}
          onClick={() => {
            finishAbertura(
              TrackEventEnum.TELA_INDIQUE_AMIGOS_BOTAO,
              'Não tenho código de indicação',
            )
            sendToNewRelic('INDICACAO_PF', '[Exit] - Sem indicação', {
              FICHA_CADASTRAL: hsfcValue(),
            })
          }}
        >
          Não tenho código de indicação
        </Button>
      </ButtonWrapper>

      {isOpenCodeModal && (
        <BottomSheet onClose={handleCodeModal}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text variant="headline-h3" as="h3">
              Código de indicação
            </Text>
            <Text onClick={handleCodeModal} variant="body-3" color="primary" as="span" bold>
              Fechar
            </Text>
          </div>

          <Text variant="body-3" as="p">
            Insira o código que você recebeu do seu amigo.
          </Text>

          <Spacing mb="xxs">
            <FlexForm>
              <Input
                id="codigo"
                type="text"
                placeholder="Informe o código de indicação"
                ref={refCodeInput}
                value={code}
                maxLength={8}
                error={errorStatus()}
                success={validation.status === 'SUCCESS'}
                infoText={validation.info}
                iconRight={inputIcon()}
                onClickIconRight={
                  errorStatus()
                    ? () => {
                        setCode('')
                        refCodeInput.current?.focus()
                      }
                    : undefined
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value.length <= 8 && setCode(toUpperCase(e.target.value))
                }}
              />
            </FlexForm>
          </Spacing>

          <ButtonWrapper>
            <Button
              onClick={handleSubmitCode}
              disabled={!(validation.status === 'SUCCESS')}
              isLoading={loadingSubmit}
            >
              Enviar código de indicação
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}

      {isOpenBottomSheet && (
        <BottomSheet onClose={() => setIsOpenBottomSheet(false)}>{bottomSheetContent}</BottomSheet>
      )}
    </PageContainer>
  )
}

export default CodigoIndicacao
