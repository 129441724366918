import { ServiceResponseError } from 'exceptions'
import { BaseServices, EHttpMethod } from 'services/base'
import { urls } from 'services/base/url'

import { getOperatingSystem } from '@interco/lib-util'

import { TRequestDadosPixPayload, TResponseGetPixData } from './types'

const request = (payload: TRequestDadosPixPayload) =>
  BaseServices.request<TResponseGetPixData>({
    pathname: urls.PIX_KEYS,
    method: EHttpMethod.GET,
    host: process.env.REACT_APP_API_HOST_CONTA_DIGITAL,
    headers: {
      'x-inter-hash-id': payload.header.hsfc,
      'x-inter-so': getOperatingSystem().toLocaleUpperCase(),
    },
  })

export const getDadosPix = async (payload: TRequestDadosPixPayload) => {
  try {
    const response = await request(payload)
    payload.success && payload.success(response.data)
  } catch (error: unknown) {
    if (error instanceof ServiceResponseError) {
      payload.error && payload.error(error)
    }
  } finally {
    payload.finally && payload.finally()
  }
}
