import { ApiResponse, GenericRes, ApiError } from 'interfaces/response.interface'
import { ResponseEmpty } from 'interfaces/responseEmpty.interface'
import { decodeBase64 } from 'shared/functions/utils'

type ConverterResponse = {
  data: ApiResponse & ApiError
  flavor?: string
  nome?: string
}

export default (event: Event): GenericRes => {
  try {
    let obj: ConverterResponse & ApiError & ResponseEmpty
    try {
      // console.log((event as CustomEvent).detail)
      obj = JSON.parse((event as CustomEvent).detail || null)

      // console.log('json', obj)
    } catch (error) {
      return new ApiError()
    }
    if (!obj || !obj?.data) {
      return new ResponseEmpty()
    }
    if (obj?.mensagem) {
      return Object.assign(new ApiError(), obj)
    }
    if (typeof obj.data === 'string') {
      obj.data = JSON.parse(decodeBase64(obj.data))
    } else {
      obj.data = obj.data as ApiResponse & ApiError
    }

    if (obj.data?.mensagem) {
      return Object.assign(new ApiError(), obj.data)
    }

    // console.log('data decoded', obj.data)
    if (obj.data && obj.data?.telas) {
      return Object.assign(new ApiResponse(), {
        ...obj.data,
        flavor: obj.flavor,
        nome: obj.nome,
      })
    }
    return Object.assign(new ApiError(), obj.data)
  } catch (error) {
    // console.log('err', error)
    const err = new ApiError()
    err.mensagem = error.message
    return err
  }
}
