export type TTerms = {
  id: string
  title: string
  description: string
  url: string
}

const terms: TTerms[] = [
  {
    id: 'cayman-account-agreement-term',
    title: 'Contrato de Conta Cayman e Cartão Internacional',
    description: 'Acesse informações sobre conta e cartão',
    url: 'contrato-conta-cayman-cartao-internacional-v1-setembro-24.pdf',
  },
  {
    id: 'electronic-signature-agreement-term',
    title: 'Acordo de consentimento para assinatura eletrônica',
    description:
      'O usuário concorda em receber comunicações eletrônicas da Inter&Co e utilizar assinaturas eletrônicas.',
    url: 'e-sign-consent-pt-BR.pdf',
  },
  {
    id: 'short-form-agreement-term',
    title: 'Inter&co Account | Short Form Disclosure',
    description: 'Acesse informações sobre taxas e serviços',
    url: 'short-form-pt-BR.pdf',
  },
  {
    id: 'long-form-agreement-term',
    title: 'Inter&co Account Fees Schedule | Long Form',
    description: 'Acesse informações sobre taxas e serviços',
    url: 'long-form-pt-BR.pdf',
  },
  {
    id: 'inter-pay-agreement-term',
    title: 'InterPay Disclosures',
    description: 'Acesse informações sobre o InterPay',
    url: 'interpay-agreement-pt-BR.pdf',
  },
  {
    id: 'term-user-agreement-term',
    title: 'Termo de Acordo do Usuário',
    description: 'Acesse o Contrato de Usuário para o uso do aplicativo e da conta',
    url: 'user-agreement-pt-BR.pdf',
  },
  {
    id: 'contract-account-agreement-term',
    title: 'Contrato da Conta inter&co',
    description: 'Acesse os termos de uso da conta',
    url: 'global-account-agreement-pt-BR.pdf',
  },
  {
    id: 'private-policy-agreement-term',
    title: 'Política de Privacidade',
    description: 'Conheça nossas políticas de privacidade',
    url: 'privacy-policy-pt-BR.pdf',
  },
]

export default terms
