export enum InsuranceType {
  ProtectedCard = 'ProtectedCard',
}

export interface InsuranceCrossSellingCardProps {
  insuranceType: InsuranceType
  callback: (selected: boolean) => void
}

export interface InsuranceCrossSellingCardBottomSheetInfo {
  bottomSheetTitle: string
  bottomSheetCoverageList: { label: string; value: string }[]
  bottomSheetButtonLabel: string
  bottomSheetAlertMessage?: string
}

export interface InsuranceCrossSellingCardSwitchInfo {
  switchTag: { label: string }
  switchCardIcon: string
  switchCardTitle: string
  switchCardSubTitle: string
  switchCardMsg: string
  switchCardMsgOff: string
  switchCardInfoButtonLabel: string
}
