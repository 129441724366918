import styled from 'styled-components'

export const CarouselWrapper = styled.div`
  margin: 24px -24px;
  width: calc(100% + 48px);

  .slick-slider {
    min-height: 450px;
  }
`
