import React, { useContext, useEffect } from 'react'

import casalChavePix from 'assets/images/chavePix/casalChavePix.png'
import { PageContainer, ButtonWrapper, Button, Icons } from 'components'
import { RoutesEnum } from 'enums/routes.enum'
import { useHistory } from 'react-router-dom'
import { FichaCadastralCtx } from 'App'
import { sendToNewRelic } from 'services'
import { IFichaCadastralCtx } from 'interfaces/fichaCadastral.interface'

import { Text } from '@inter/inter-ui'

import { mountImages, homePixInfoList } from './utils'
import * as S from './style'

const InformacoesPix = () => {
  const history = useHistory()

  const { hsfc }: IFichaCadastralCtx = useContext<IFichaCadastralCtx>(FichaCadastralCtx)

  const hsfcValue = (): string => hsfc || (sessionStorage.getItem('hsfc') as string)

  useEffect(() => {
    sendToNewRelic('PIX', '[Screen] - Informacoes', {
      FICHA_CADASTRAL: hsfcValue(),
    })
  }, [])

  return (
    <PageContainer>
      <img
        src={casalChavePix}
        alt="Cadastre sua chave Pix"
        style={{ margin: '24px -24px', width: 'calc(100% + 48px)' }}
      />

      <Text variant="headline-h1" as="h1">
        Cadastre sua chave e ganhe um Seguro Pix
      </Text>

      <Text variant="body-3" as="p">
        Com a chave Pix você paga e recebe de forma rápida, e ganha uma proteção turbinada
      </Text>

      {homePixInfoList.map((item) => (
        <S.ItemWrapper key={item.title}>
          <S.ImageWrapper>
            <Icons variant={mountImages(item.icon)} />
          </S.ImageWrapper>

          <S.ContentWrapper>
            <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
              <Text variant="caption-1" bold colorWeight={500} style={{ marginRight: '6px' }}>
                {item.title}
              </Text>

              {item.tagText && (
                <S.TagStyled color={item.tagColor || ''} colorText="white" size="small">
                  <div style={{ marginBottom: '2px' }}>{item.tagText}</div>
                </S.TagStyled>
              )}
            </div>

            <Text variant="caption-1" colorWeight={500}>
              {item.value}
            </Text>
          </S.ContentWrapper>
        </S.ItemWrapper>
      ))}

      <ButtonWrapper>
        <Button id="pixInfo" variant="primary" onClick={() => history.push(RoutesEnum.CHAVE_PIX)}>
          Continuar
        </Button>
      </ButtonWrapper>
    </PageContainer>
  )
}

export default InformacoesPix
