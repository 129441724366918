import React from 'react'

import { TAberturaOptions, TPosAberturaItem } from 'interfaces/posAbertura.interface'
import { Icons } from 'components'
import { ChevronRight, CheckFill } from 'components/elements/Icons'

import { Text } from '@inter/inter-ui'

import * as S from './styles'

type TListItensProps = {
  items: TAberturaOptions
  onClick: (link: string) => void
}

type ElementProps = TPosAberturaItem & {
  onClick: (link: string) => void
}

const Element = ({ icon, done, label, link, onClick }: ElementProps) => (
  <S.Item onClick={done ? undefined : () => onClick(link)}>
    <S.IconLabel>
      <Icons variant={icon} color={done ? 'var(--gray300)' : 'var(--gray500)'} />
    </S.IconLabel>
    <Text variant="body-3" bold colorWeight={done ? 300 : 500}>
      {label}
    </Text>
    <S.IconLabel>
      <Icons
        variant={done ? CheckFill : ChevronRight}
        color={done ? 'var(--success500)' : undefined}
      />
    </S.IconLabel>
  </S.Item>
)

export const ListItens = ({ items, onClick }: TListItensProps) => {
  const itemValues: TPosAberturaItem[] = []
  Object.keys(items).forEach((item) => itemValues.push(items[item]))

  return (
    <S.List>
      {itemValues.map((item) => (
        <>{item.display && <Element key={item.label} {...item} onClick={onClick} />}</>
      ))}
    </S.List>
  )
}
