import { RoutesEnum } from 'enums/routes.enum'
import { UserMoney, Globe, Share, Loop } from 'components/elements/Icons'

export const posAberturaItensDefault = {
  mei: {
    icon: UserMoney,
    label: 'Quero uma conta Empresa ou MEI',
    link: RoutesEnum.CONTA_MEI,
    done: false,
    display: true,
  },
  global: {
    icon: Globe,
    label: 'Quero uma Conta Global',
    link: RoutesEnum.CONTA_GLOBAL,
    done: false,
    display: true,
  },
  indique: {
    icon: Share,
    label: 'Quero indicar para amigos',
    link: RoutesEnum.INDICAR_AMIGOS,
    done: false,
    display: true,
  },
  loop: {
    icon: Loop,
    label: 'Programa de pontos Loop',
    link: RoutesEnum.INTER_LOOP,
    done: false,
    display: false,
  },
}
