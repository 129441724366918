import styled from 'styled-components'
import { Gray, White } from 'assets/styled-component/variables'

export const ListInfoWrapper = styled.div`
  display: flex;

  p {
    margin-left: 2.5rem;
  }
`

export const StepNumber = styled.span`
  font-size: 12px;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 50%;
  background: ${Gray[500]};
  color: ${White[500]};
  line-height: 24px;
  display: inline-block;
  position: absolute;
`
