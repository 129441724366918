import { BaseServices, EHttpMethod } from 'services/base'
import { urls } from 'services/base/url'

import { TRequestSendCnpjPayload } from './types'

const request = (payload: TRequestSendCnpjPayload) =>
  BaseServices.request({
    pathname: urls.POST_CONTA_MEI,
    method: EHttpMethod.POST,
    host: process.env.REACT_APP_API_HOST_CONTA_DIGITAL,
    data: { ...payload.data },
    headers: { 'x-id-ficha-cadastral': payload.header.hsfc },
  })

export const postContaMei = async (payload: TRequestSendCnpjPayload) => {
  try {
    const response = await request(payload)

    payload.success && payload.success(response.status)
  } catch (error) {
    payload.error && payload.error(error)
  } finally {
    payload.finally && payload.finally()
  }
}
