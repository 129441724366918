import styled from 'styled-components'

import { Button as InterButton } from '@inter/inter-ui'

export const Button = styled(InterButton)`
  font-weight: bold;
  max-height: 48px;
  height: 48px;
`

export const ButtonLink = styled(InterButton)<{ fontWeight?: string }>`
  font-weight: ${(props) => props.fontWeight || 400};
  padding: 0;
  font-size: 12px;
  font-style: normal;
  line-height: 15px;
  letter-spacing: 0px;
`
