import React from 'react'

import { Text } from 'components'

type TPageText = {
  text: string
  margin?: string
}

export const PageText: React.FC<TPageText> = ({ text, margin }: TPageText) => (
  <Text variant="headline-h1" style={{ margin: margin || '80px 0px 40px 0px' }}>
    {text}
  </Text>
)
