import React, { ChangeEvent, useContext, useRef, useState } from 'react'

import { FlexForm, Input, UIFlex, Text, Checkbox, ButtonWrapper, Button } from 'components'
import { PageContainer } from 'components/layout/PageContainer'
import { useForm } from 'react-hook-form'
import { yupValidacaoContaMei } from 'validations'
import { useTrackEvent } from 'hooks'
import { TrackEventEnum } from 'interfaces/trackEvent.interface'
import { postContaMei } from 'services/axios'
import { justDigits } from 'shared/functions/utils'
import { IFichaCadastralCtx } from 'interfaces/fichaCadastral.interface'
import { FichaCadastralCtx, PosAberturaCtx } from 'App'
import { IPosAberturaCtx } from 'interfaces/posAbertura.interface'
import { useHistory } from 'react-router-dom'
import { sendToNewRelic } from 'services'
import { sendDataNative } from 'components/hooks/useFetchNative'
import { MEI_ACCOUNT_TERMS } from 'shared/functions/constants'
import { CheckCircle, Help, Icons } from 'components/elements/Icons'
import { RoutesEnum } from 'enums/routes.enum'
import url from 'shared/functions/url'
import { formatarNumero } from 'utils/convertCurrencyToString'

import { BottomSheet } from '@inter/inter-ui'
import { Icon } from '@inter/inter-ui'
import { yupResolver } from '@hookform/resolvers/yup'

import { contaMeiLabels } from '../constants'
import * as S from './style'

type TFormInput = {
  cnpj: string
  faturamento: string
}

type TS3Object = { linkS3: string }
const InformacoesEmpresa = () => {
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState(false)
  const [success, setSuccess] = useState(false)

  const { hsfc }: IFichaCadastralCtx = useContext<IFichaCadastralCtx>(FichaCadastralCtx)
  const { posAberturaOptions, setPosAberturaOptions }: IPosAberturaCtx =
    useContext<IPosAberturaCtx>(PosAberturaCtx)

  const history = useHistory()
  const sendTrackEventData = useTrackEvent()

  const hsfcValue = (): string => hsfc || (sessionStorage.getItem('hsfc') as string)

  const refCNPJInput = useRef<HTMLInputElement>(null)
  const refFaturamentoInput = useRef<HTMLInputElement>(null)

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<TFormInput>({
    resolver: yupResolver(yupValidacaoContaMei),
  })

  const handleCNPJModal = () => {
    if (errors) {
      setValue('cnpj', '')
      setValue('faturamento', '')
      clearErrors()
      setTermsAccepted(false)
    }
  }

  const handleInputCnpj = (value: string) => {
    if (value.length === 18) {
      clearErrors()
      sendTrackEventData(TrackEventEnum.GERAL_INPUT, {
        content_id: contaMeiLabels.PAGE_NAME,
        content_name: contaMeiLabels.CNPJ,
      })
    }
  }

  const onSubmit = (data: TFormInput) => {
    refCNPJInput.current?.blur()
    refFaturamentoInput.current?.blur()

    setLoadingSubmit(true)
    postContaMei({
      data: {
        cnpj: justDigits(data.cnpj),
        faturamentoMensal: formatarNumero(data.faturamento).toString(),
        aceiteTermos: true,
      },
      header: {
        hsfc: hsfcValue(),
      },
      success: () => {
        setPosAberturaOptions({
          ...posAberturaOptions,
          mei: { ...posAberturaOptions.mei, done: true },
        })
        setSuccess(true)
        sendToNewRelic('POS_ABERTURA', 'ContaMei - Sucesso', {
          FICHA_CADASTRAL: hsfc,
        })
      },
      error: () => {
        history.push(`${RoutesEnum.CONTA_MEI}${url.buildQuery({ sendError: true })}`)
      },
      finally: () => {
        handleCNPJModal()
        setLoadingSubmit(false)
      },
    })
  }

  const openTerms = () => {
    sendDataNative<TS3Object>({
      body: {
        linkS3: MEI_ACCOUNT_TERMS,
      },
      callback: 'callbackOpenPdf',
      method: 'OPENPDF',
    })
  }

  const handleClickEntendi = () => {
    sendTrackEventData(TrackEventEnum.GERAL_BOTAO, {
      content_id: contaMeiLabels.PAGE_NAME,
      content_name: contaMeiLabels.UNDERSTOOD,
    })
    history.push(RoutesEnum.HOME_POS_ABERTURA)
  }

  return (
    <PageContainer>
      {success ? (
        <FlexForm>
          <UIFlex style={{ flex: '1' }} justifyContent="center">
            <S.Wrapper>
              <UIFlex alignItems="center" style={{ marginBottom: '64px' }}>
                <S.CircleWrapper>
                  <Icons variant={CheckCircle} width={32} height={32} color="var(--gray100)" />
                </S.CircleWrapper>
              </UIFlex>
              <h3>Solicitação enviada com sucesso</h3>
              <p className="mb-4">
                Em breve você receberá a resposta da análise da sua solicitação por e-mail.
              </p>
            </S.Wrapper>
          </UIFlex>

          <ButtonWrapper>
            <Button type="button" variant="primary" onClick={handleClickEntendi}>
              {contaMeiLabels.CONTINUE}
            </Button>
          </ButtonWrapper>
        </FlexForm>
      ) : (
        <>
          <UIFlex
            style={{ padding: '16px 0', marginBottom: '24px' }}
            justifyContent="end"
            direction="row"
          >
            <S.Link onClick={() => history.goBack()}>
              <Icon name="close" color="var(--primary500)" />
            </S.Link>
          </UIFlex>
          <FlexForm onSubmit={handleSubmit(onSubmit)}>
            <S.Wrapper>
              <Input
                id="cnpj"
                label={
                  <Text variant="body-3" bold>
                    {contaMeiLabels.CNPJ}
                  </Text>
                }
                type="tel"
                placeholder="Insira seu CNPJ"
                maxLength={18}
                mask="99.999.999/9999-99"
                error={Boolean(errors.cnpj)}
                infoText={errors.cnpj?.message}
                {...register('cnpj')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputCnpj(e.target.value)}
              />

              <Input
                id="faturamento"
                label={
                  <Text variant="body-3" bold>
                    {contaMeiLabels.FATURAMENTO}
                  </Text>
                }
                placeholder="R$ 0,00"
                type="currency"
                currency="BRL"
                error={Boolean(errors.faturamento)}
                infoText={errors.faturamento?.message}
                {...register('faturamento')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputCnpj(e.target.value)}
                helper={<Icons variant={Help} onClick={() => setOpenModal(true)} />}
              />
            </S.Wrapper>

            <ButtonWrapper>
              <Checkbox
                id="checkbox-terms"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
                disabled={loadingSubmit}
                label={
                  <div style={{ display: 'block' }}>
                    Li e concordo com as informações descritas do documento{' '}
                    <Text variant="body-3" color="primary" as="span" bold onClick={openTerms}>
                      Termos e Condições
                    </Text>
                  </div>
                }
                reversed
              />

              <Button type="submit" disabled={!termsAccepted} isLoading={loadingSubmit}>
                {contaMeiLabels.CONTINUE}
              </Button>
            </ButtonWrapper>
          </FlexForm>
        </>
      )}

      {openModal && (
        <BottomSheet onClose={() => setOpenModal(false)}>
          <h3>Preencha com a média mensal de faturamento da sua empresa nos últimos 12 meses.</h3>
          <p className="mb-4">Caso não tenha, preencha com uma previsão de faturamento mensal</p>
          <ButtonWrapper>
            <Button data-anim="ripple" onClick={() => setOpenModal(false)}>
              Entendi
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}
    </PageContainer>
  )
}

export default InformacoesEmpresa
