import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'
import { MaskPHONE } from 'inter-frontend-lib-util-form/lib/masks/phone'
import { useForm, SubmitHandler } from 'react-hook-form'
import {
  PageContainer,
  Button,
  Input,
  FlexForm,
  ButtonWrapper,
  Text,
  PageText,
  PageTitle,
} from 'components'
import { sendDataNative, TSendDataNative } from 'components/hooks/useFetchNative/index'
import { useCallbackNative } from 'components/hooks/useCallbackNative'
import { DADOS_COMPLEMENTARES } from 'components/urls'
import { getRouter, RoutesEnum } from 'enums/routes.enum'
import { justDigits, ellipsis } from 'shared/functions/utils'
import { ApiError, ApiResponse, GenericRes, IApiResponseCtx } from 'interfaces/response.interface'
import { ITrackEvent, TrackEventEnum } from 'interfaces/trackEvent.interface'
import { IDadosPessoaisCtx, ILegalAgeCtx } from 'interfaces/dadosPessoais.interface'
import { yupValidacaoContato } from 'validations'
import { DadosPessoaisCtx, ApiResponseCtx, LegalAgeCtx } from 'App'
import estadosCivis from 'shared/json/estadoCivil.json'
import { sendToNewRelic } from 'services'
import url from 'shared/functions/url'
import { getUrlParams } from 'shared/helpers/getUrlPrams'
import emailProviders from 'shared/json/emailProviders.json'

import { yupResolver } from '@hookform/resolvers/yup'
import { BottomSheet } from '@inter/inter-ui'

import * as S from './styles'

type TFormInput = {
  email: string
  celular: string
}
type TJson = {
  key: string
  value: string
}

const DadosPessoaisContato = () => {
  const { dadosPessoais, setDadosPessoais }: IDadosPessoaisCtx =
    useContext<IDadosPessoaisCtx>(DadosPessoaisCtx)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [emailProvider, setEmailProvider] = useState<string>('')
  const history = useHistory()
  const { setApiResponse }: IApiResponseCtx = useContext<IApiResponseCtx>(ApiResponseCtx)

  const { isMinor }: ILegalAgeCtx = useContext<ILegalAgeCtx>(LegalAgeCtx)

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<TFormInput>({
    resolver: yupResolver(yupValidacaoContato),
  })

  const onSubmit: SubmitHandler<TFormInput> = (form: TFormInput) => {
    if (loading) return
    setDadosPessoais({
      ...dadosPessoais,
      estadoCivil: isMinor ? 'Solteiro' : dadosPessoais.estadoCivil,
      email: form.email,
      celular: form.celular,
    })

    const dadosPessoaisStorage = localStorage.getItem('dadosPessoais')

    if (dadosPessoaisStorage) {
      localStorage.removeItem('dadosPessoais')
    }

    localStorage.setItem('dadosPessoais', JSON.stringify(form))

    setIsOpenModal(true)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_DADOS_BASICOS_CONTATO_CONTINUAR,
        parameters: {},
      },
    })
    // eslint-disable-next-line no-unused-expressions
    document.getElementById('email')?.blur() // resolve bug when press en ter device focusing input and can edit
  }

  const onEdit = () => {
    setIsOpenModal(false)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_DADOS_BASICOS_CONTATO_ALTERAR,
        parameters: {},
      },
    })
  }

  const sendData = () => {
    setIsOpenModal(false)
    setLoading(true)
    const callApi: TSendDataNative = {
      body: {
        ...dadosPessoais,
        estadoCivil:
          estadosCivis.find((v: TJson) => v.value === dadosPessoais.estadoCivil)?.key || '',
        celular: justDigits(dadosPessoais.celular || ''),
        email: dadosPessoais.email,
      },
      callback: 'callbackApi',
      endpoint: DADOS_COMPLEMENTARES,
      method: 'PUT',
      headers: {},
    }
    sendDataNative(callApi)
    sendDataNative<ITrackEvent>({
      method: 'TRACKEVENT',
      body: {
        name: TrackEventEnum.TELA_DADOS_BASICOS_CONTATO_CONFIRMAR,
        parameters: {},
      },
    })
  }

  useCallbackNative('callbackApi', 'onCallbackApi', {
    onCallbackApi: (data: GenericRes) => {
      if (data instanceof ApiResponse) {
        const step = getRouter(data.telas.stepList[0].step)

        sendToNewRelic('CONTATO', '[ApiResponse] - callbackApi', {
          PROXIMA_ETAPA: step,
          URL: data.telas.stepList[0].url,
        })
        if (step) {
          if (step === RoutesEnum.AVISO_REFAZER_ITENS) {
            setApiResponse({
              ...data,
              telas: {
                ...data.telas,
                stepList: data.telas?.stepList?.splice(1),
              },
            }) // should be remove first step
          }

          history.push(`${step}${url.buildQuery(getUrlParams(data.telas.stepList[0].url))}`)
        } else {
          const callApi: TSendDataNative = {
            body: data,
            callback: 'callbackMediaUpdate',
            endpoint: '',
            method: 'CALLMEDIAUPDATE',
          }
          sendDataNative(callApi)
        }
      } else if (data instanceof ApiError) {
        if (data.campo && (data.campo === 'celular' || data.campo === 'email')) {
          setError(data.campo, {
            message: data.mensagem || 'Campo obrigatório.',
          })
        } else {
          sendToNewRelic('CONTATO', '[ApiError] - callbackApi', {
            ...data,
          })
        }
      }
      setLoading(false)
    },
  })

  const closeModal = () => {
    setIsOpenModal((openModal: boolean) => !openModal)
  }

  const handleClickPreview = () => {
    history.push(RoutesEnum.STEP_DADOS_PESSOAIS)
  }

  const handleChangeProviderEmail = (value: string) => {
    setEmailProvider(value)
    setValue(
      'email',
      `${
        getValues('email')?.includes('@') ? getValues('email').split('@', 1) : getValues('email')
      }${value}`,
    )
  }

  return (
    <PageContainer>
      <PageTitle onClickPreview={isMinor ? undefined : handleClickPreview} />

      <PageText
        text={isMinor ? 'Quais os dados de contato do responsável' : 'Quais seus dados de contato?'}
        margin="24px 0px 16px 0px"
      />

      <Text variant="body-3" style={{ marginBottom: '40px' }}>
        Validaremos estes dados para sua segurança
      </Text>

      <FlexForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          id="celular"
          type="tel"
          label="Celular"
          placeholder="(11) 99999-9999"
          maxLength={15}
          mask="(99) 99999-9999"
          defaultValue={watch('celular')}
          error={Boolean(errors.celular)}
          infoText={errors.celular?.message}
          {...register('celular')}
        />

        <Input
          id="email"
          label="E-mail"
          placeholder="exemplo@exemplo.com"
          maxLength={60}
          value={watch('email')}
          error={Boolean(errors.email)}
          infoText={errors.email?.message}
          {...register('email')}
        />
        <div style={{ display: 'flex', overflow: 'auto' }}>
          {getValues('email') &&
            getValues('email').length > 0 &&
            emailProviders.map((item) => (
              <S.EChips
                style={{ marginRight: '16px' }}
                key={item}
                mode="normal"
                label={item}
                isActive={
                  item === emailProvider &&
                  Boolean(getValues('email')?.split('@')[1] === emailProvider.split('@')[1])
                }
                onClick={() => handleChangeProviderEmail(item)}
              />
            ))}
        </div>
        <ButtonWrapper>
          <Button type="submit" isLoading={loading} disabled={!watch('celular') || !watch('email')}>
            Continuar
          </Button>
        </ButtonWrapper>
      </FlexForm>
      {isOpenModal && (
        <BottomSheet onClose={closeModal}>
          <Text variant="body-2" bold colorWeight={500}>
            Está tudo certo?
          </Text>

          <div style={{ margin: '16px 0px 18px' }}>
            <Text variant="body-3">Seu celular é:</Text>
            <Text variant="body-3" as="p">
              <b>{dadosPessoais?.celular && MaskPHONE(dadosPessoais?.celular)}</b>
            </Text>
            <Text variant="body-3">Seu e-mail é:</Text>
            <Text variant="body-3" as="p">
              <b>{dadosPessoais?.email && ellipsis(dadosPessoais.email, 30)}</b>
            </Text>
          </div>

          <ButtonWrapper>
            <Button type="submit" onClick={sendData}>
              Confirmar
            </Button>
            <Button type="submit" onClick={onEdit} variant="secondary">
              Alterar
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}
    </PageContainer>
  )
}

export default DadosPessoaisContato
