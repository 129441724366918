import React, { ReactNode, useRef } from 'react'

import { FullPageWrapper } from './style'

type TFullPage = {
  children?: ReactNode
  activeCloseFullPage?: boolean
}

export const FullPage = ({ children, activeCloseFullPage }: TFullPage) => {
  const modalRef = useRef<HTMLDivElement>(null)

  return (
    <FullPageWrapper className={`${activeCloseFullPage ? 'active' : ''}`}>
      <div className="backdrop" />
      <div className="modal">
        <div ref={modalRef} className="modal-content modal-fullscreen">
          {children}
        </div>
      </div>
    </FullPageWrapper>
  )
}

FullPage.defaultProps = {
  children: undefined,
  activeCloseFullPage: false,
}
