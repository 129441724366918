import React from 'react'

import { Icons } from 'components'
import { ArrowLeft, Download } from 'components/elements/Icons'

import * as S from './style'

type TTitleProps = {
  title: string
  onPageBack: () => void
  onDownload: () => void
  showTermsList?: boolean
}

const Title = ({ title, onPageBack, onDownload, showTermsList }: TTitleProps) => (
  <S.TitleContainer showTermsList={showTermsList}>
    <Icons variant={ArrowLeft} onClick={onPageBack} />
    <h3>{title}</h3>
    {!showTermsList && <Icons variant={Download} onClick={onDownload} />}
  </S.TitleContainer>
)

export default Title
