export const Gray = {
  100: '#EBEDF0',
  200: '#DEDFE4',
  300: '#B6B7BB',
  400: '#6A6C72',
  500: '#161616',
  600: '#8C91A9',
  700: '#6A6E81',
  800: '#4B4E5C',
}

export const White = {
  500: '#FFFFFF',
}

export const Orange = {
  500: '#FF7A00',
  600: '#EB7000',
  700: '#FFC2E7',
}

export const Green = {
  400: '#20C05D',
  500: '#00AA4F',
}

export const Yellow = {
  300: '#FFF7D9',
  400: '#FFC700',
  500: '#FFBA00',
}

export const Red = {
  500: '#FD4F47',
}

export const Status = {
  Error: {
    100: '#FEF0ED',
    500: '#F56A50',
  },
}

export const INPUT_HEIGHT = '48px'
