import React, { useContext } from 'react'

import imageScreenFimOnboarding from 'assets/images/imageScreenFimOnboarding.png'
import { PageContainer, ButtonWrapper, Button } from 'components'
import { TSendDataNative, sendDataNative } from 'components/hooks/useFetchNative'
import { IFichaCadastralCtx } from 'interfaces/fichaCadastral.interface'
import { FichaCadastralCtx } from 'App'

import { Text } from '@inter/inter-ui'

import * as S from './style'

const FimOnboarding = () => {
  const { basicAccountCreated }: IFichaCadastralCtx =
    useContext<IFichaCadastralCtx>(FichaCadastralCtx)

  const valueGetBySessionStorage =
    /^true$/i.test(sessionStorage.getItem('basicAccountCreated') as string) || false
  const basicAccountCreatedValue = (): boolean => basicAccountCreated || valueGetBySessionStorage

  const handleFinalizarAbertura = () => {
    const callApi: TSendDataNative = {
      method: 'FINISHONBOARDING',
    }
    sendDataNative(callApi)
  }

  return (
    <PageContainer>
      <S.ImageWrapper>
        <img src={imageScreenFimOnboarding} alt="img-cartoes" />
      </S.ImageWrapper>
      <div>
        <Text variant="headline-h1" as="h1">
          {basicAccountCreatedValue() ? 'Quase lá!' : 'Agora é só aguardar!'}
        </Text>

        <Text variant="body-3" as="p">
          {basicAccountCreatedValue()
            ? 'Enquanto seus documentos estão em análise, navegue pelo Super App para conhecer alguns produtos que o Inter tem para você!'
            : 'Já recebemos seus documentos e estamos analisando sua solicitação. Você receberá o nosso retorno em até 3 dias úteis.'}
        </Text>

        <ButtonWrapper style={{ marginTop: '24px' }}>
          <Button onClick={handleFinalizarAbertura}>
            {basicAccountCreatedValue() ? 'Entrar no Super App' : 'Finalizar'}
          </Button>
        </ButtonWrapper>
      </div>
    </PageContainer>
  )
}

export default FimOnboarding
