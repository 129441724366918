import styled, { css } from 'styled-components'

import { TextProps } from '@inter/inter-ui'

import { ModifiersProps, StyledTagProps } from './types'

export const TagText = styled.span<
  TextProps & Pick<StyledTagProps, 'theme' | 'colorText' | 'intensity'>
>`
  ${({ colorText, theme, intensity }) => css`
    color: ${colorText || `var(--tag-${theme}-text-${intensity}-color)`};
    font-size: 10px;
    font-weight: 700;
    vertical-align: text-bottom;
  `}
`

const modifiers = ({ theme, intensity }: ModifiersProps) =>
  css`
    background-color: var(--tag-${theme}-background-${intensity}-color);
  `

export const Container = styled.div<{ hasOnClick: boolean }>`
  display: flex;

  ${({ hasOnClick }) => hasOnClick && 'cursor: pointer;'}
`

export const Tag = styled.span<StyledTagProps>`
  ${({ size, theme, intensity, color, margin }: StyledTagProps) => css`
    padding: ${size === 'small' ? '0px 4px' : '8px 16px'};
    margin: ${margin || '0 4px'};
    border-radius: 4px;
    white-space: nowrap;

    ${color &&
    css`
      background-color: ${color};
    `}

    ${theme && intensity && !color && modifiers({ theme, intensity })}
  `}
`
